import {Container} from "react-bootstrap";
import {Outlet} from "react-router-dom";
import React from "react";

export default function PaymentUpdateScreen() {

  return (
    <Container>
      <Outlet/>
    </Container>
  );
}
