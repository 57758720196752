import React, {useEffect, useRef, useState} from "react";
import {Container, Row, Spinner} from "react-bootstrap";
import {LibraryResultItem} from "../models/APITypes";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import {Link} from "react-router-dom";
import ScrollDetector from "../components/ScrollDetector";
import MediaTile from "../components/MediaTile";
import {useUploader} from "../hooks/useUploader";
import Logger from "../models/Logger";


type LibraryScreenProps = {
  navBar?: React.RefObject<HTMLDivElement>;
};

type FetchingStateType = {
  token: string | null;
  fetching: boolean;
};

export default function LibraryScreen(_: LibraryScreenProps) {
  const auth = useAuth();
  const {showUploadModal} = useUploader();


  const fetchStateRef = useRef<FetchingStateType>({token: null, fetching: true});
  const [libraryResults, setLibraryResults] = useState<LibraryResultItem[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const bottomHitRef = useRef<{bottomHit: boolean}>({bottomHit: false});

  const fetchData = (initial?: boolean) => {
    fetchStateRef.current.fetching = true;

    if (fetchStateRef.current.token || initial) {
      const api = new API(auth.getAuthToken() || "");
      api.library(fetchStateRef.current.token || undefined)
        .then(result => {
          Logger.log(result);
          setLibraryResults(prev => prev.concat(result.data.data));
          fetchStateRef.current.token = result.data.next;
        })
        .catch(Logger.log)
        .finally(() => {
          fetchStateRef.current.fetching = false;
          if (initial) {
            setInitialLoading(false);
          }
          if (fetchStateRef.current.token && bottomHitRef.current.bottomHit) {
            fetchData();
          }
        });
    }
  };

  const onBottomHit = () => {
    bottomHitRef.current.bottomHit = true;
    if (!fetchStateRef.current.fetching) {
      fetchData();
    }
  };

  const onLeave = () => {
    bottomHitRef.current.bottomHit = false;
  };

  useEffect(() => {
    fetchData(true);
  }, []);


  if (initialLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <Spinner animation="border" variant="primary"/>
      </div>
    );
  }

  if (libraryResults.length === 0) {
    return (
      <div className="w-100 py-5">
        <Row className="w-100">
          <h3 className="text-center">
            Looks like there isn't anything here yet
          </h3>
        </Row>
        <Row className="w-100">
          <h4 className="text-center text-muted">
            <span role="button" className="text-primary" onClick={() => showUploadModal()}>Upload</span> to begin
          </h4>
        </Row>
      </div>
    );
  }

  if (libraryResults.length === 0) {
    return (
      <div className="w-100 py-5">
        <Row className="w-100">
          <h3 className="text-center">
            No results
          </h3>
        </Row>
        <Row className="w-100">
          <h4 className="text-center text-black-50">
            Try a different name or <Link to="/uploader">upload</Link>
          </h4>
        </Row>
      </div>
    );
  }


  return (
    <div>
      <Container fluid>
        <Row className="d-grid px-3"
             style={{gap: 15, gridAutoFlow: "dense", gridTemplateColumns: `repeat(auto-fill, minmax(140px, 1fr))`}}
        >
          {
            libraryResults.map(item =>
              <div style={{gridColumnEnd: `span 1`}} key={`${item.name}${item.id}`}><MediaTile info={item}/></div>)
          }
        </Row>
        <ScrollDetector className="mb-5" onBecomeVisible={onBottomHit} onLeave={onLeave}/>
      </Container>
    </div>
  );
}
