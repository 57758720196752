import React, {useEffect, useRef} from "react";

export type ScrollDetectorProps = {
  onBecomeVisible?: () => void;
  onLeave?: () => void;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function ScrollDetector({onBecomeVisible, onLeave, ...props}: ScrollDetectorProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && onBecomeVisible) {
        onBecomeVisible();
      }

      if (!entry.isIntersecting && onLeave) {
        onLeave();
      }
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);


  return (
    <div ref={ref} {...props}/>
  );
}
