import React, {useEffect, useState} from "react";
import {Button, Col, Container, Dropdown, Row, Spinner} from "react-bootstrap";
import MediaIcon from "../components/MediaIcon";
import {useNavigate, useParams} from "react-router-dom";
import {EpisodeProgress, ShowProgress, UserSeason} from "../models/APITypes";
import useAuth from "../hooks/useAuth";
import SeasonList from "../components/SeasonList";
import API from "../models/API";
import {useProfile} from "../hooks/useProfile";
import ToggleSwitch from "../components/ToggleSwitch";
import {MovieDBShowItem} from "../models/UploaderTypes";
import MovieDBRequest from "../models/MovieDBRequest";
import {useUserContent} from "../hooks/useUserContent";
import {BsPlayFill} from "react-icons/bs";
import Logger from "../models/Logger";

function fetchHiddenShow(id: number) {
  return MovieDBRequest.tvShow(id).details()
    .catch(error => {
      Logger.log(error);
      return undefined;
    });
}

export default function ShowScreen() {
  const navigate = useNavigate();
  const {showId} = useParams();
  const {getShow} = useUserContent();

  const [selectedSeason, setSelectedSeason] = useState<UserSeason>();
  const [latestProgress, setLatestProgress] = useState<EpisodeProgress>();
  const [latestDuration, setLatestDuration] = useState<number>();

  const [showProgress, setShowProgress] = useState<ShowProgress>();

  const [showHidden, setShowHidden] = useState<boolean>(false);
  const [hiddenShowData, setHiddenShowData] = useState<MovieDBShowItem>();


  const showData = getShow(Number(showId));

  const auth = useAuth();
  const profile = useProfile();





  useEffect(() => {
    if (!showData) {
      return
    }


    if (showHidden && !hiddenShowData) {
      fetchHiddenShow(Number(showId)).then(data => setHiddenShowData(data));
    }
  }, [showHidden, showData]);

  useEffect(() => {
    const api = new API(auth.getAuthToken() || "");

    const currentProfile = profile.getCurrentProfile();

    if (!currentProfile || showId === undefined) {
      return;
    }

    if (!showData) {
      return;
    }

    api.showProgress(currentProfile.id, Number(showId)).view()
      .then(result => {
        setShowProgress(result.data);
        Logger.log(result.data);
      })
      .catch(Logger.log);


    // Fetch latest progress, try to assign it to the show image preview
    api.latestShowProgress(currentProfile.id, Number(showId)).view()
      .then(result => {

        const progressShowId = result.data.show_id;
        const progressSeasonId = result.data.season_id;
        const progressEpisodeId = result.data.episode_id;

        let episodeExists = false;

        showData.seasons.forEach((season) => {
          for (const episode of season.episodes) {
            if (episode.show_id === progressShowId &&
              episode.season_id === progressSeasonId &&
              episode.media_id === progressEpisodeId) {
              setLatestProgress(result.data);
              setLatestDuration(episode.duration);
              setSelectedSeason(season);
              episodeExists = true;
              return;
            }
          }
        });

        if (episodeExists) {
          return;
        }

        throw new Error("Episode doesn't exist");

      })
      .catch(error => {
        const firstEpisode = showData.seasons[0].episodes[0];

        setLatestProgress({
          id: 0,
          show_id: firstEpisode.show_id,
          season_id: firstEpisode.season_id,
          episode_id: firstEpisode.media_id,
          progress: 0,
          updated_at: ""
        });
        setSelectedSeason(showData.seasons[0]);

        Logger.log(error);
      });


  }, [showData, profile]);

  const backdropPath = () => {
    if (showData && showData.backdrop_path) {
      return showData.backdrop_path;
    }

    return undefined;
  };

  const seasonList = (): UserSeason[] => {

    if (!showData) {
      return [];
    }

    let sortedSeasons: UserSeason[] = [];


    const mappedUserSeasons: {[id: number]: UserSeason} = {};
    for (const season of showData.seasons) {
      mappedUserSeasons[season.season_id] = season;
    }


    if (!showHidden) {
      sortedSeasons = showData ? showData.seasons : [];
    }
    else if (hiddenShowData && hiddenShowData.seasons) {
      for (const season of hiddenShowData.seasons) {
        if (!season.season_number) {
          continue;
        }

        if (season.season_number in mappedUserSeasons) {
          sortedSeasons.push(mappedUserSeasons[season.season_number]);
        }
        else {
          sortedSeasons.push({
            show_id: showData.show_id,
            season_id: season.season_number,
            name: season.name,
            episodes: []
          })
        }
      }
    }


    return sortedSeasons;
  }

  if (!showData) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Spinner variant="primary"/>
        </Row>
      </Container>
    )
  }

  return (
    <Container
    >
      <Row>
        <Col xs={12} lg={6}>
          <Button variant="link"
                  onClick={() => navigate(`/watch/show/${latestProgress?.show_id}/${latestProgress?.season_id}/${latestProgress?.episode_id}`)}
                  className="w-100 h-100 p-0">
            <MediaIcon
              iconPath={backdropPath()}
              progress={latestProgress?.progress} label={latestProgress && `S${latestProgress.season_id}:E${latestProgress.episode_id}`}
              duration={latestDuration}
            />
          </Button>
        </Col>
        <Col xs={12} lg={6} className="text-start">
          <Row>
            <div className="w-100 d-flex">
              <h2 className="text-truncate my-auto" >
                {showData?.name}
              </h2>
            </div>
          </Row>
          <Button
            className="w-auto d-flex mb-2 mt-2"
            variant="light"
            onClick={() => navigate(`/watch/show/${latestProgress?.show_id}/${latestProgress?.season_id}/${latestProgress?.episode_id}`)}
          >
            <BsPlayFill className="my-auto" size="1.25em"/> <small className="my-auto ms-1">{latestProgress?.progress ? "Resume" : "Play"}</small>
          </Button>
          <Row>
            <Col>
              <p>
                {showData && showData.overview}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className=" d-flex">
          <small className="my-auto me-2">Show hidden</small>
          <ToggleSwitch onValueChange={setShowHidden} className="my-auto"/>
        </Col>
      </Row>

      <Row className="mt-4 mb-2">
        <Col className="text-start">
          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-decoration-none p-0">
              {selectedSeason ? (selectedSeason.name || `Season ${selectedSeason.season_id}`) : ""}
              {/*{showData && showData.seasons.map((season, index) => selectedSeason === index ? (season.name || `Season ${season.season_id}`) : "")}*/}
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              {
                seasonList().map((season) =>
                  <Dropdown.Item
                    onClick={() => setSelectedSeason(season)}
                  >
                    {season.name || `Season ${season.season_id}`}
                  </Dropdown.Item>)
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>

      </Row>

      <Row>
        {
          selectedSeason !== undefined && showData &&
          <SeasonList
            progress={showProgress && showProgress[selectedSeason.season_id]}
            seasonData={selectedSeason}
            showHidden={showHidden}
          />
        }
      </Row>
    </Container>
  );
}
