import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link, Outlet} from "react-router-dom";
import {ResponsiveNavProvider} from "../hooks/useResponsiveNav";

const footer = [
  {
    link: "/",
    title: "About"
  },
  {
    link: "/plans",
    title: "Plans"
  },
  {
    link: "/help",
    title: "Help"
  },
  {
    link: "/login",
    title: "Log In"
  },
  {
    link: "/signup",
    title: "Sign Up"
  },
  {
    link: "/legal/privacy",
    title: "Privacy Policy"
  },
  {
    link: "/legal/tos",
    title: "Terms of Service"
  },

];

export default function PublicScreen() {


  return (
    <>
      {/*<meta name="theme-color" content="#fff" />*/}
      {/*style={{backgroundColor: "rgb(177,105,255)"}}*/}
      <div className="vw-100 min-vh-100 bg-dark text-white" >
        <ResponsiveNavProvider>
          <Outlet/>

          <div className="vw-100">
          <Container className="py-5">
            <Row className="col-10 offset-1 text-white">
              {
                footer.map(item => (
                  <Col xs={12} sm={6} lg={3}>
                    <Link to={item.link} className="link-light text-decoration-none text-center">
                      <h5>{item.title}</h5>
                    </Link>
                  </Col>
                ))
              }
            </Row>

          </Container>
          </div>
        </ResponsiveNavProvider>
      </div>


    </>
  );
}
