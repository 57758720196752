import React from "react";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import {Accordion, Anchor, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const faq = [

  {
    group: "Pocketter",
    questions: [
      {
        title: "What is Pocketter?",
        body: "Pocketter is a personal streaming platform where you can store and watch your movies and TV shows anywhere, anytime."
      }
    ]
  },
  {
    group: "Plans",
    questions: [
      {
        title: "What is included in the plans?",
        body: "Each plan includes storage, up to 10TB, and SD streaming. HD streaming is also included for all plans except 10GB."
      },
      {
        title: "Can I change my plan?",
        body: "Plans can be upgraded or downgraded at any time. Upgrades are immediately applied, with the difference of the remaining current plan put towards the new plan. Downgrades are reflected from the next billing date and you will continue to have access to the current plan until then."
      },
      {
        title: "What happens if I change my plan during my free trial?",
        body: "You can change your plan at any point during the free trial without charge. When your trial period ends, the plan selected at that time will begin to be charged regularly."
      }
    ]
  },
  {
    group: "Uploading",
    questions: [
      {
        title: "How do I upload my file?",
        body: "Files can be uploaded in your browser through the Pocketter website by dragging and dropping a file or clicking the upload button in the top right corner of your library."
      },
      {
        title: "Can I upload multiple files for the same movie or episode?",
        body: "Currently it is only possible to upload a single file. If you upload a file to a movie or episode that already has a video uploaded, it will replace that video."
      },
      {
        title: "Why is my upload failing?",
        body: "There are various reasons an upload may fail. If your uploads are repeatedly failing, you may not have enough available storage and will need to upgrade before you can proceed. It is also possible the file being uploaded is protected by proprietary encryption for DRM (Digital rights management). If the file is protected by such a system, we are legally unable to access the video in a way that would allow us to upload and encode the video to Pocketter."
      },
      {
        title: "Why is my upload failing when I have enough storage?",
        body: <>
          It is possible the file being uploaded is protected by proprietary encryption for DRM (Digital rights management). If the file is protected by such a system, we are legally unable to access the video in a way that would allow us to upload and encode the video to Pocketter.
          <br/><br/>
          You may also want to try playing the file on your device to make sure the file isn’t corrupted.
          </>
      },

    ]
  },
  {
    group: "Storage",
    questions: [
      {
        title: "How does storage space work on Pocketter?",
        body: <>
          The storage limit is a limit on new uploads. This means that content already on Pocketter will not be affected if you exceed your plan’s storage.
          <br/><br/>
          When you upload a file, it is encoded to a stream-ready format and Pocketter will check if you have enough space to store this new file. Please note, the resulting file size will likely vary from the file you originally uploaded.

        </>
      },
      {
        title: "What happens to my files when my plan changes?",
        body: "No matter what plan you change to, even the free tier, the movies and shows you have already uploaded are always there for you to access."
      },
      {
        title: "What happens if I change my plan?",
        body: "The uploader requires you to have Windows 10 or later for Windows users and MacOS Mojave (10.14) or later for Mac users, make sure you've got the right version. If you have the right operating system and still can't run it, get in contact with us and we'll help you out."
      },
    ]
  },
  {
    group: "Streaming",
    questions: [
      {
        title: "What’s the difference between SD and HD streaming?",
        body: "SD (standard definition) and HD (high definition) refer to the resolution of the video you watch on your device - the greater the resolution, the better the quality of video. SD streaming means that you can watch videos up to 480p. HD streaming extends up to 1080p, also known as full HD. The actual resolution streamed may be impacted by network performance."
      },
      {
        title: "How many people can watch at once?",
        body: <>
          The number of simultaneous screens that are allowed depends on your plan. See <Link to="/plans">plans</Link> for more details.
        </>
      }
    ]
  }
];


export default function HelpScreen() {



  return (
    <>
      <Container style={{marginTop: 150, marginBottom: 150}}>
        <Row>
          <Col xs={12} className="flex-column my-auto d-flex">
            <h1 className="text-center text-white mt-auto">
              Need help with something?
            </h1>
            <h5 className="text-center text-muted mb-auto">
              Checkout the FAQ or get in contact.
            </h5>
          </Col>
        </Row>
      </Container>
      <div className="vw-100">
        <Container>
          <Row>
            <h2 className="py-3 px-2">FAQ</h2>
            {
              faq.map((item) => {
                return (
                  <Container className="py-3">
                    <h4 className="text-start">{item.group}</h4>
                    <Accordion flush className="rounded overflow-hidden">
                      {
                        item.questions.map((item, index) => {
                          return (
                            <AccordionItem eventKey={`${index}`}>
                              <AccordionHeader>
                                {item.title}
                              </AccordionHeader>
                              <AccordionBody className="text-start">
                                {item.body}
                              </AccordionBody>
                            </AccordionItem>
                          );
                        })
                      }
                    </Accordion>
                  </Container>
                );
              })
            }
          </Row>
          <Row style={{marginTop: 150, marginBottom: 150}}>
            <Col lg={6} className="d-flex">
              <h2 className="text-center m-auto">Get in contact</h2>
            </Col>
            <Col lg={6} className="d-flex">
              <Anchor href="mailto:support@pocketter.com" className="w-100 d-flex m-auto">
                <h4 className="text-center mx-auto">
                  support@pocketter.com
                </h4>
              </Anchor>

            </Col>
          </Row>

        </Container>
      </div>

    </>
  );
}
