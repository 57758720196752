import React from "react";
import ControlButton, {ControlButtonProps} from "./ControlButton";
import {Spinner} from "react-bootstrap";
import {IoPause} from "react-icons/io5";
import {BsPlayFill} from "react-icons/bs";

export type PlayButtonProps = {
  isPlaying?: boolean;
  isLoading?: boolean;
} & ControlButtonProps;

export default function PlayButton({isLoading, isPlaying, ...props}: PlayButtonProps) {

  if (isLoading) {
   return (
     <Spinner animation="border" variant="light" style={{width: "4em", height: "4em"}}/>
   );
  }

  return (
    <ControlButton
      {...props}
    >
      {
        isPlaying ? <IoPause color="white" size={"6em"}/> : <BsPlayFill color="white" size={"8em"}/>
      }
    </ControlButton>
  );
}
