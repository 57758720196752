import React from "react";
import {IoArrowBackOutline} from "react-icons/io5";
import {Button, ButtonProps} from "react-bootstrap";

export default function BackButton(props: ButtonProps) {
  return (
    <Button variant="link" className="d-flex justify-content-start align-items-start" {...props}>
      <IoArrowBackOutline size="2em"/>
    </Button>
  );
}
