import React, {ReactNode, useContext, useMemo, useState} from "react";
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import logo from "../logo.svg";
import useAuth from "./useAuth";

interface ResponsiveNavContextType {
  setLogoTitleClasses: (classes?: string) => void;
  setBackground: (background?: string) => void;
  setDark: (dark: boolean) => void;
}

const ResponsiveNavContext = React.createContext<ResponsiveNavContextType>({} as ResponsiveNavContextType);

export type ResponsiveNavProviderPropsType = {
  children: ReactNode;
};

const defaultBackground = "rgba(var(--bs-dark-rgb), 0.95)";

function ResponsiveNavProvider(props: ResponsiveNavProviderPropsType) {
  const location = useLocation();
  const auth = useAuth();

  const [logoTitleClasses, setLogoTitleClassesState] = useState<string>("");
  const [background, setBackgroundState] = useState<string>(defaultBackground);
  const [dark, setDarkState] = useState<boolean>(true);

  function setLogoTitleClasses(classes?: string) {
    setLogoTitleClassesState(classes || "");
  }

  function setBackground(background?: string) {
    setBackgroundState(background || defaultBackground);
  }

  function setDark(dark: boolean) {
    setDarkState(dark);
  }

  const value = useMemo(() => ({
    setLogoTitleClasses,
    setBackground,
    setDark
  }), [logoTitleClasses, background, dark]);

  return (
    <ResponsiveNavContext.Provider value={value}>
      <meta name="theme-color" content={background} />

      <Navbar expand="md" fixed="top" sticky="top"  style={{background: background}} variant={"dark"}>
        <Container>
          <Navbar.Brand as={Link} to="/" href="/" className="d-flex my-auto">
            <img
              alt="Pocketter"
              src={dark ? logo : logo}
              height={45}
              className="d-inline-block align-top"
            />
            <h4 className={ "mx-3 text-center my-auto text-secondary" + logoTitleClasses } style={{lineHeight: "35px"}}>Pocketter</h4>


          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="flex-fill" defaultActiveKey="/" activeKey={location.pathname}>
              <Nav.Link as={Link} to="/" href="/">About</Nav.Link>
              {/*<Nav.Link as={Link} to="/uploader" href="/uploader">Uploader</Nav.Link>*/}
              <Nav.Link as={Link} to="/plans" href="/plans">Plans</Nav.Link>
              <Nav.Link as={Link} to="/help" href="/help">Help</Nav.Link>
              <form className="form-inline ms-md-auto">
                <Button variant={"outline-light"} className="me-2 text-decoration-none" style={{width: 90}} onClick={auth.redirectLogin}>
                  <>Log In</>
                </Button>
                <Button variant="light" className={ ""} style={{width: 90}} onClick={auth.redirectSignUp}>
                  Sign Up
                </Button>
              </form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {props.children}
    </ResponsiveNavContext.Provider>
  );

}

function useResponsiveNav() {
  return useContext(ResponsiveNavContext);
}

export {useResponsiveNav, ResponsiveNavProvider};
