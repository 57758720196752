import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Axios from "axios";
import API from "../models/API";
import * as CryptoJS from "crypto-js";
import Logger from "../models/Logger";

interface AuthContextType {
  loading: boolean;
  error?: any;
  isAuthenticated: boolean;
  redirectLogin: () => void;
  redirectSignUp: () => void;
  getAuthToken: () => string|null;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export type AuthProviderPropsType = {
  children: ReactNode;
  authorizeEndpoint: string;
  tokenEndpoint: string;
  redirectUri: string;
  clientId: string;
  scope: string;
};


function generateRandomString(length: number) {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

async function generateCodeChallenge(codeVerifier: string) {
  const digest = CryptoJS.SHA256(codeVerifier);
  // @ts-ignore
  return digest.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

}

// Export the provider as we need to wrap the entire app with it
export function AuthProvider(props: AuthProviderPropsType): JSX.Element {
  const [error, setError] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [accessToken, setAccessTokenState ] = useState<string>();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname, error]);

  useEffect(() => {

    const args = new URLSearchParams(window.location.search);
    const code = args.get("code");

    if (args.has("code")) {
      const data = {
        client_id: props.clientId,
        code_verifier: window.sessionStorage.getItem("code_verifier"),
        grant_type: "authorization_code",
        redirect_uri: props.redirectUri,
        code: code
      };

      setLoading(true);

      Axios.post(props.tokenEndpoint, data)
        .then(result => {

          window.localStorage.setItem("rt", result.data["refresh_token"]);
          window.localStorage.setItem("at", result.data["access_token"]);
          window.localStorage.setItem("last_refresh", Date.now().toString());

          setAccessTokenExpiry(result.data["expires_in"]);

          // Check if needs refreshing every 10 seconds
          setInterval(() => refreshAccessTokenCheck().catch(Logger.error), 10000);
          setIsAuthenticated(true);
          setError(null);

          Logger.log(result);
          return (new API(result.data["access_token"])).endSessions();
        })
        .catch(error => {
          setIsAuthenticated(false);
          setError(error);
          Logger.log(error);
        })
        .finally(() => {
          setLoading(false);
          window.location.search = "";
        })
    }
    else {
      // Refresh access token
      const refreshToken = window.localStorage.getItem("rt");

      if (!refreshToken) {
        setIsAuthenticated(false);
      }
      else {
        setLoading(true);
        refreshAccessToken()
          .then(() => {
            // Check if needs refreshing every 10 seconds
            setInterval(() => refreshAccessTokenCheck().catch(Logger.error), 10000);
          })
          .finally(() => setLoading(false));
      }
    }
    setLoadingInitial(false);
  }, []);

  function setAccessToken(token: string) {
    setAccessTokenState(token);
    window.localStorage.setItem("at", token);
  }

  function clearAccessToken() {
    setAccessTokenState(undefined);
    window.localStorage.removeItem("at");
  }

  function setAccessTokenExpiry(expiresIn: number) {
    window.localStorage.setItem("at_exp", (Date.now() + expiresIn * 1000).toString());
  }

  // Check for refreshing token using local storage
  async function refreshAccessTokenCheck() {
    const accessToken = window.localStorage.getItem("at");
    const expiresOn = window.localStorage.getItem("at_exp");
    const last_refresh = window.localStorage.getItem("last_refresh");

    // If last refresh attempt within 5 seconds then return
    if (last_refresh && (expiresOn && Date.now() < Number(last_refresh) + (5 * 1000))) {
      return;
    }

    // Is the current time more than 30 seconds before token expires
    if ((!expiresOn && !accessToken) || (expiresOn && Date.now() > Number(expiresOn) - (30 * 1000))) {
      return refreshAccessToken().catch(Logger.log);
    }
  }

  async function refreshAccessToken() {
    const token = window.localStorage.getItem("rt");

    if (!token) {
      throw Error("Refresh token not found");
    }
    // We don't need an actual auth token at this point
    const api = new API("");

    try {
      window.localStorage.setItem("last_refresh", Date.now().toString());
      const result = await api.refreshAccessToken(props.clientId, token);
      window.localStorage.setItem("rt", result.data.refresh_token);
      setAccessTokenExpiry(result.data.expires_in);
      setAccessToken(result.data.access_token);
      setIsAuthenticated(true);
    }
    catch (e) {
      Logger.error(e);
      setIsAuthenticated(false);
      clearAccessToken();
    }
  }

  function redirectLogin() {
    const codeVerifier = generateRandomString(64);

    generateCodeChallenge(codeVerifier).then(function(codeChallenge) {
      window.sessionStorage.setItem("code_verifier", codeVerifier);

      const args = new URLSearchParams({
        response_type: "code",
        client_id: props.clientId,
        scope: props.scope,
        code_challenge_method: "S256",
        code_challenge: codeChallenge,
        redirect_uri: props.redirectUri
      });
      window.location.assign(props.authorizeEndpoint + "?" + args);
    });
  }

  function redirectSignUp() {
    const codeVerifier = generateRandomString(64);

    generateCodeChallenge(codeVerifier).then(function(codeChallenge) {
      window.sessionStorage.setItem("code_verifier", codeVerifier);

      const args = new URLSearchParams({
        response_type: "code",
        client_id: props.clientId,
        scope: props.scope,
        code_challenge_method: "S256",
        code_challenge: codeChallenge,
        redirect_uri: props.redirectUri,
        signup: "true"
      });
      window.location.assign(props.authorizeEndpoint + "?" + args);
    });
  }

  function logout() {
    const api = new API(getAuthToken() || "");
    api.logout()
      .finally(() => {
        navigate("/");

        window.localStorage.removeItem("rt");
        clearAccessToken();
        setIsAuthenticated(false);
      });
  }

  function getAuthToken() {
    return window.localStorage.getItem("at");
  }


  const memoedValue = useMemo(
    () => ({
      loading,
      error,
      isAuthenticated,
      redirectLogin,
      redirectSignUp,
      logout,
      getAuthToken
    }),
    [isAuthenticated, loading, error, accessToken]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && !loading && props.children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
