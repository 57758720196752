import React from "react";
import {BsPlayFill} from "react-icons/bs";
import {Image} from "react-bootstrap";

type MediaIconProps = {
  iconPath?: string;
  progress?: number;
  duration?: number;
  label?: string;
  hidden?: boolean;
};

export default function MediaIcon({iconPath, progress, duration, label, hidden, ...props}: React.ComponentPropsWithoutRef<"div"> & MediaIconProps) {
  return (
      <div {...props}>
        <div className="ratio ratio-16x9 rounded overflow-hidden bg-black">
          {
            iconPath &&
            <Image
              className="w-100 h-100 overflow-hidden"
              style={{objectFit: "contain"}}
              src={`https://www.themoviedb.org/t/p/w780/${iconPath}`}
            />
          }

          <div className="d-flex justify-content-center align-items-center">
            {
              !hidden &&
              <BsPlayFill
                color="white"
                size="6em"
              />
            }

            <h4 className="position-absolute overflow-visible text-white p-3" style={{left: 0, bottom: 0, textShadow: "0 1px 5px #333"}}>
              {label}
            </h4>

            <div className="w-100 position-absolute rounded-3" style={{height: 4, bottom: 0}}>
              <div className="" style={{height: 4, width: `${((progress && duration && Math.max(0.03, progress/duration) )||0)*100}%`, backgroundColor: "mediumpurple"}}/>
            </div>
          </div>
        </div>

      </div>
  );
}
