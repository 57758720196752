import React, {useRef, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {BsCloudArrowUp} from "react-icons/bs";

type FileUploadScreenProps = {
  onFileChanged?: (file: File) => void;
  selectedFile?: File;
};

export default function FileUploadScreen(props: FileUploadScreenProps) {
  const [dragState, setDragState] = useState<"none" | "enter" | "over" | "leave">("none");
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [previewSrc, setPreviewSrc] = useState<string>();

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragState("none");

    props.onFileChanged && props.onFileChanged(event.dataTransfer.files[0]);
    setPreviewSrc(URL.createObjectURL(event.dataTransfer.files[0]));
  };

  const handleDropDownUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      props.onFileChanged && props.onFileChanged(event.target.files[0]);
      setPreviewSrc(URL.createObjectURL(event.target.files[0]));

    }
  };


  return (
    <Container className=" m-0 ">
      <Row
        onDrop={handleDrop}
        onDragEnter={event => {
          event.preventDefault();
          setDragState("enter");
        }}
        onDragLeave={event => {
          event.preventDefault();
          setDragState("leave");
        }}
        onDragOver={event => {
          event.preventDefault();
          setDragState("over");
        }}
        className={`d-flex justify-content-center py-5 ${dragState === "over" || dragState === "enter" ? "bg-primary bg-opacity-10" : ""}`}
      >
        <Col xs={12} className=" position-relative justify-content-center text-center p-5">

          {
            props.selectedFile ?
              <>
                <div className="position-relative d-flex flex-column mh-100" >
                  <Card className="p-0 rounded-0 border-0">
                    <Card.Body className="rounded-0 p-0 bg-transparent">
                      <Container fluid className="mt-auto p-0 m-0 ">
                        <video playsInline src={previewSrc} className="w-100 shadow"/>

                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-white mt-2 mb-auto">{props.selectedFile.name}</small>

                    </Card.Footer>
                  </Card>

                </div>


              </>
              :
              <>

                <BsCloudArrowUp className="text-primary" size="3em"/>
                <h2 className="text-center text-white">Drag and drop</h2>
                <h5 className="text-center text-muted">or click <span
                  onClick={() => hiddenFileInput.current && hiddenFileInput.current.click()} role="button"
                  className="text-primary">here</span></h5>
              </>
          }


        </Col>

      </Row>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleDropDownUploadChange}
        className="d-none"
      />
    </Container>
  );
}
