import React from "react";
import ControlButton, {ControlButtonProps} from "./ControlButton";
import {MdReplay10} from "react-icons/md";

export type SkipButtonProps = {
  direction: "forward" | "backward";
} & ControlButtonProps;

export default function SkipButton({direction, ...props}: SkipButtonProps) {
  return (
    <ControlButton {...props}>
      {
        direction === "backward" ?
          < MdReplay10 color="white" size={"4em"}/>
          :
          <MdReplay10 color="white" size={"4em"}/>

      }
    </ControlButton>
  )
    ;
}
