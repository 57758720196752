import React, {useEffect} from "react";
import PublicPrivateRoute from "./PublicPrivateRoute";
import useAuth from "../hooks/useAuth";

const LoginRedirect = () => {

  const auth = useAuth();
  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.redirectLogin();
    }
  }, [auth.isAuthenticated]);

  return <></>;
}

export default function ProtectedRouteElement(element: React.ReactElement): React.ReactElement | null {

  return PublicPrivateRoute(<LoginRedirect/>, element);
}
