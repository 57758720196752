import React from "react";
import {Image, Ratio} from "react-bootstrap";
import {Link} from "react-router-dom";
import {LibraryResultItem} from "../models/APITypes";

type MediaTypeProps = {
  info: LibraryResultItem;
};

export default function MediaTile({info, ...props}: MediaTypeProps & React.HTMLAttributes<HTMLDivElement>) {

  return (
    <div {...props}>
      <div className="w-100 h-100"  >
        <Link to={`/${info.type}/${info.id}`} className="rounded h-auto" >
          <Ratio aspectRatio={150} className="" style={{objectFit: "fill"}}>
            {
              info.poster_path ?
                <Image
                  className="h-100 rounded"
                  style={{objectFit: "fill", boxShadow: "0 0.5rem 20px rgba(0,0,0,0.175)", aspectRatio: "2/3"}}
                  src={"https://www.themoviedb.org/t/p/w500/" + info.poster_path}
                />
                :
                <div className="bg-primary bg-gradient rounded d-flex overflow-hidden p-1">
                  <h5 className="my-auto mw-100 text-center my-auto text-white w-100">{info.name}</h5>
                </div>
            }

          </Ratio>

        </Link>
      </div>
    </div>

  );
}
