import React, {useState} from "react";
import {Button, Modal, ModalProps} from "react-bootstrap";
import ProfileList from "../ProfileList";
import ProfileEditScreen from "./ProfileEditScreen";
import ProfileAddScreen from "./ProfileAddScreen";

export type ProfileModalProps = {
  hideCloseButton?: boolean;
  showPromptTitle?: boolean;
} & React.HTMLAttributes<HTMLDivElement> & ModalProps;

export default function ProfileModal({hideCloseButton, showPromptTitle, onHide, ...props}: ProfileModalProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editSelection, setEditSelection] = useState<number>();
  const [showAddScreen, setShowAddScreen] = useState<boolean>(false);
  const backButtonPressed = () => {
    if (showAddScreen || editSelection !== undefined) {
      setEditSelection(undefined);
      setShowAddScreen(false);
      return;
    }

    setIsEditing(!isEditing)
  }

  const backButtonTitle = () => {
    if (showAddScreen || editSelection !== undefined) {
      return "Cancel";
    }

    return isEditing ? "Done" : "Edit";
  }

  const onHideWrapper = () => {
    setShowAddScreen(false);
    setEditSelection(undefined);
    onHide && onHide();
  };

  let inside;

  if (showAddScreen) {
    inside = <ProfileAddScreen onSuccess={() => setShowAddScreen(false)}/>;
  }
  else if (editSelection === undefined) {
    inside = <ProfileList isEditing={isEditing} onAddPressed={() => setShowAddScreen(true)} onSelection={profileIndex => isEditing && setEditSelection(profileIndex)}/>
  }
  else {
    inside = <ProfileEditScreen editingProfileId={editSelection} onSuccess={() => setEditSelection(undefined)}/>;
  }

  return (
    <Modal contentClassName="shadow bg-dark  border-0" centered onHide={onHideWrapper} {...props}>
      <Modal.Header className="shadow-sm d-flex" closeButton={!hideCloseButton} closeVariant="white">

        <div className="w-100 d-flex flex-column">
          {
            showPromptTitle &&
            <div className="w-100 d-flex text-white">
              <h3 className="mx-auto">Select a profile</h3>
            </div>
          }
          <Button
            className="text-decoration-none me-auto"
            variant="link"
            onClick={backButtonPressed}
          >
            {backButtonTitle()}
          </Button>

        </div>
      </Modal.Header>
      <Modal.Body className="text-white">
        {
          inside
        }
      </Modal.Body>
    </Modal>
  );
}
