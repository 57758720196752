import React, {useEffect, useState} from "react";
import {Image, Nav} from "react-bootstrap";
import Inverse from "../Inverse.svg";

import {
  BsCloudArrowUp,
  BsFillCloudArrowUpFill,
  BsGrid1X2,
  BsGrid1X2Fill,
} from "react-icons/bs";
import {
  IoEllipsisHorizontal,
  IoSearch,
  IoSearchOutline,
} from "react-icons/io5";
import {useProfile} from "../hooks/useProfile";

import {Link, useLocation} from "react-router-dom";
import ProfileButton from "./ProfileButton";


const buttons = [
  {
    icon: BsGrid1X2,
    selectedIcon: BsGrid1X2Fill,
    title: "Library",
    link: "/"
  },
  {
    icon: IoSearchOutline,
    selectedIcon: IoSearch,
    title: "Search",
    link: "/search"
  },
  {
    icon: BsCloudArrowUp,
    selectedIcon: BsFillCloudArrowUpFill,
    title: "Uploads",
    link: "/uploads"
  },
];


export default function SideBar() {

  const profile = useProfile();
  const location = useLocation();
  const [pathname, setPathname] = useState("/");

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);


  return (

    <Nav
      variant="pills"
      className="flex-fill  d-flex flex-column position-relative bg-dark user-select-none mw-100"
      style={{minHeight: 400}}
    >
      <div className="d-flex px-3" style={{marginTop: 32, marginBottom: 32}}>
        <Image className=" my-auto " width={55} height={55} src={Inverse}/>
        <h4 className="text-white ms-3 my-auto">Pocketter</h4>
      </div>
      <div>
        {
          buttons.map(button => (
            <Nav.Item className="mx-2">
              <Nav.Link
                as={Link}
                href={button.link}
                eventKey={button.link}
                to={button.link}
                className={`w-100  text-start rounded px-3 py-3 text-decoration-none d-flex ${pathname === button.link ? "active" : ""}`}
              >
                {
                  pathname === button.link ?
                    <button.selectedIcon size="25px" className="my-auto"/>
                    :
                    <button.icon size="25px" className="my-auto"/>

                }

                <p className="ms-4 text-center my-auto text-truncate">
                  {button.title}
                </p>
              </Nav.Link>
            </Nav.Item>
          ))
        }
      </div>

      <div role="button" onClick={profile.showProfileModal} className="mt-auto d-flex px-3 mw-100"
           style={{paddingTop: 16, paddingBottom: 16}}>
        <div className="d-flex flex-shrink-0 my-auto" style={{height: 50, width: 50}}>
          <ProfileButton
            className="h-100 w-100"
            profileColour={profile.getCurrentProfile()?.colour || ""}
            initial={profile.getCurrentProfile()?.name[0].toUpperCase()}
          />
        </div>
        <strong className="my-auto ms-3 text-white text-truncate">
          {profile.getCurrentProfile()?.name}
        </strong>
        <IoEllipsisHorizontal size="1.5em" className="text-white ms-auto my-auto flex-shrink-0"/>
      </div>

    </Nav>

  );
}
