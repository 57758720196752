export type MovieDBShowItem = {
  backdrop_path?: string | null;
  created_by?: object[];
  episode_run_time?: number[];
  first_air_date?: string;
  genres?: object[];
  homepage?: string;
  id?: number;
  in_production?: boolean;
  languages?: string[];
  last_air_date?: string;
  last_episode_to_air?: object;
  name?: string;
  next_episode_to_air?: null;
  networks?: object[];
  number_of_episodes?: number;
  number_of_seasons?: number;
  origin_country?: string[];
  original_language?: string;
  original_name?: string;
  overview?: string;
  popularity?: number;
  poster_path?: string | null;
  production_companies?: object[];
  production_countries?: object[];
  seasons?: MovieDBShowItemSeason[];
  spoken_languages?: object[];
  status?: string;
  tagline?: string;
  type?: string;
  vote_average?: number;
  vote_count?: number;
}

export type MovieDBShowItemSeason = {
  air_date?: string;
  episode_count?: number;
  id?: number;
  name?: string;
  overview?: string;
  poster_path?: string;
  season_number: number;
};

export type MovieDBSeasonItem = {
  _id?: string;
  air_date?: string;
  episodes?: MovieDBEpisodeItem[];
  name?: string;
  overview?: string;
  id?: number;
  poster_path?: string;
  season_number?: number;
}

export type MovieDBEpisodeItem = {
  air_date?: string;
  episode_number?: number;
  crew?: object[];
  guest_stars?: object[];
  id?: number;
  name?: string;
  overview?: string;
  production_code?: string | null;
  season_number?: number;
  still_path?: string | null;
  vote_average?: number;
  vote_count?: number;
}

export type MovieDBMovieItem = {
  adult?: boolean;
  backdrop_path?: string | null;
  belongs_to_collection?: null | object;
  budget?: number;
  genres?: object[];
  homepage?: string | null;
  id?: number;
  imdb_id?: string | null;
  original_language?: string;
  original_title?: string;
  overview?: string | null;
  popularity?: number;
  poster_path?: string | null;
  production_companies?: object[];
  production_countries?: object[];
  release_date?: string;
  revenue?: number;
  runtime?: number | null;
  spoken_languages?: object[];
  status?: "Rumored" | "Planned" | "In Production" | "Post Production" | "Released" | "Canceled";
  tagline?: string | null;
  title?: string;
  video?: boolean;
  vote_average?: number;
  vote_count?: number;
}

export type MovieDBSearchMultiTVItem = MovieDBSearchShowItem & {media_type: "tv"};
export type MovieDBSearchMultiMovieItem = MovieDBSearchMovieItem & {media_type: "movie"};

export type MovieDBSearchMultiItem = MovieDBSearchMultiTVItem | MovieDBSearchMultiMovieItem | {media_type: "person"};

export type MovieDBSearchMultiResult = {
  page?: number;
  results?: MovieDBSearchMultiItem[];
  total_results?: number;
  total_pages?: number;
};

export type MovieDBSearchShowItem = {
  poster_path?: string | null;
  popularity?: number;
  id?: number;
  backdrop_path?: string | null;
  vote_average?: number;
  overview?: string;
  first_air_date?: string;
  origin_country?: string[];
  genre_ids?: number[];
  original_language?: string;
  vote_count?: number;
  name?: string;
  original_name?: string;
}

export type MovieDBSearchMovieItem = {
  poster_path?: string | null;
  adult?: boolean;
  overview?: string | null;
  release_date?: string
  genre_ids?: number[];
  id?: number;
  original_title?: string;
  original_language?: string;
  title?: string;
  backdrop_path?: string | null;
  popularity?: number;
  vote_count?: number;
  video?: boolean;
}



export type ShowsUploadStateType = {
  [key: number]: {
    [key: number]: {
      [key: number]: {
        state: UploadStateType,
        added_on: Date
      }
    }
  }
};

export type MoviesUploadStateType = {
  [key: number]: {
    state: UploadStateType,
    added_on: Date
  }
};


export enum MediaType {
  Movie = "movie",
  Episode = "episode",
}

export enum UploadProgressType {
  None,
  Compression,
  Upload,
  Encode
}

export type UploadStateType = {
  type: UploadProgressType,
  progress: number
};
export type UploadQueueType = {
  mediaType: MediaType.Episode;
  mediaId: number;
  seasonId: number;
  showId: number;
} | {
  mediaType: MediaType.Movie;
  mediaId: number;
};


export type UploadProgressCallbackType = (uploadData: UploadQueueType, uploadState: UploadStateType) => void;

export enum EncodeProgressStatusType {
  Pending = "pending",
  Running = "running",
  Failed = "failed",
  Succeeded = "succeeded",
  Cancelled = "cancelled"
}

export type UploadEncodeProgressType =
  {
    type: MediaType.Movie;
    upload_id: number;
    media_id: number;
    status: EncodeProgressStatusType;
    progress: number;
  }
  |
  {
    type: MediaType.Episode;
    upload_id: number;
    media_id: number;
    status: EncodeProgressStatusType;
    progress: number;
    season_id: number;
    show_id: number;
  };


export type UserEpisode = {
  media_id: number;
  season_id: number;
  show_id: number;
  video_id: number;
  name?: string;
  overview?: string;
  still_path?: string;
  next?: {
    show_id: number;
    season_id: number;
    media_id: number;
  }
}

export type UserSeason = {
  show_id: number;
  season_id: number;
  name?: string;
  overview?: string;
  poster_path?: string;
  episodes: UserEpisode[];
};

