import React, {useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";

type SliderProps = {
  onChange?: (value: number) => void;
  slidingBegan?: () => void;
  slidingEnded?: () => void;
  position?: number;
  containerStyle?: React.CSSProperties;
};

export default function Slider(props: SliderProps) {
  const [, setCount] = useState<{ x: number, y: number } | undefined>({x: 150, y: 0});
  const [isDragging, setIsDragging] = useState(false);
  const [trackWidth, setTrackWidth] = useState(1);

  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTrackWidth(trackRef.current ? trackRef.current.offsetWidth : 1);
  }, []);

  const jumpTo = (position: number) => {
    setCount({x: position - 10, y: 0});
  };

  const onStart = () => {
    setCount(undefined);
    setIsDragging(true);
    props.slidingBegan && props.slidingBegan();
  };

  const onStop = () => {
    setIsDragging(false);
    props.slidingEnded && props.slidingEnded();
  };

  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{...{height: 30}, ...props.containerStyle}}
      onClick={(event) => {
        !isDragging && jumpTo(event.clientX - event.currentTarget.getBoundingClientRect().left);
        props.onChange && props.onChange((event.clientX - event.currentTarget.getBoundingClientRect().left - 10) / trackWidth)
        event.stopPropagation();
      }}
    >
      <div
        className="w-100 bg-light position-relative"
        style={styles.track}
        ref={trackRef}
      >
        <div
          style={{width: props.position ? props.position * trackWidth + 5 : 0, borderRadius: 2}}
          className="h-100 bg-primary"
        />
        <Draggable
          axis="x"
          bounds="parent"
          onStart={onStart}
          onStop={onStop}
          onDrag={(event, data) => {
            // Logger.log(data.node.parentElement?.getBoundingClientRect().width);
            // Logger.log(trackWidth);
            props.onChange && props.onChange(data.x / (trackWidth));
            event.stopPropagation();
            event.preventDefault();
          }}
          onMouseDown={event => {
            event.stopPropagation();
            event.preventDefault();
          }}
          position={!isDragging && props.position ? {x: props.position * trackWidth, y: 0} : undefined}
        >
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: 25,
              marginTop: "-12px",
              backgroundColor: "#fff",
              transition: `transform 0.1s`
            }}
          />
        </Draggable>

      </div>
    </div>

  );
}

const styles = {
  track: {
    height: 3,
    borderRadius: 2,
  }
}
