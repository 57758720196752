import React from "react";
import {Col, Row, Spinner} from "react-bootstrap";
import ProfileButton from "./ProfileButton";
import {useProfile} from "../hooks/useProfile";


export type ProfileListProps = {
  isEditing?: boolean;
  onSelection?: (profileIndex: number) => void;
  onAddPressed?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export default function ProfileList({isEditing, onSelection, onAddPressed, ...props}: ProfileListProps) {

  const profile = useProfile();
  const profiles = profile.getProfiles();

  const onProfileClicked = (index: number) => {
    if (!isEditing) {
      profile.setCurrentProfile(index);
    }

    onSelection && onSelection(index);
  }

  if (!profiles) {
    return (
      <Row className="py-5 justify-content-center">
        <Spinner  animation="border" variant="primary"/>
      </Row>
    );
  }



  return (
    <div {...props}>
      <Row>

        {
          profiles.map((item, index) => {


            return (
              <Col xs={4} className="pb-2">
                <ProfileButton
                  initial={item.name[0].toUpperCase()}
                  name={profiles[index].name}
                  profileColour={item.colour}
                  selected={profile.getCurrentProfile()?.id === item.id}
                  onClick={() => onProfileClicked(item.id)}
                  isEditing={isEditing}
                />
              </Col>
            );

          })
        }

        {
          profiles.length < 6 &&
          <Col xs={4} className="pb-2">
            <ProfileButton
              initial={"+"}
              profileColour={"#727272"}
              onClick={onAddPressed}
            />
          </Col>
        }

      </Row>
    </div>
  );
}
