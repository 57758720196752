import React from "react";
import {Col, ColProps, Row} from "react-bootstrap";

type StripeFormElementProps = {
  errorMessage?: string;
}

export default function StripeFormElement({children, errorMessage, ...props}: StripeFormElementProps & ColProps) {
  return (
    <Col xs={12} className="py-1" {...props}>
      {children}

      <Row className="px-3 pt-1 text-danger" style={{fontSize: "12px"}}>
        {errorMessage}
      </Row>

    </Col>
  );
}
