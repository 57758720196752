import React, {ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import API from "../models/API";
import useAuth from "./useAuth";
import {UserMovie, UserShow} from "../models/APITypes";
import WebSocket from "../models/WebSocket";
import Logger from "../models/Logger";

type UserContextType = {
  getMovie: (id: number) => UserMovie|undefined;
  getShow: (id: number) => UserShow|undefined;
  deleteMovie: (id: number, callback?: () => void) => Promise<void>;
  deleteEpisode: (showId: number, seasonId: number, episodeId: number) => Promise<void>;
};

export type UserMovieCache = {
  [id: number]: {
    data: UserMovie;
    updatedAt: number;
  }
};

export type UserShowCache = {
  [id: number]: {
    data: UserShow;
    updatedAt: number;
  }
};

type MovieEventData = {
  id: number;
  video_id: number;
  media_id: number;
};

type EpisodeEventData = {
  id: number;
  video_id: number;
  media_id: number;
  season_id: number;
  show_id: number;
};


const UserContentContext = React.createContext<UserContextType>({} as UserContextType);


export type UserContentProviderPropsType = {
  children: ReactNode;
};

function UserContentProvider(props: UserContentProviderPropsType) {
  const {getAuthToken, isAuthenticated} = useAuth();

  const [userMovies, setUserMovies] = useState<UserMovieCache>({});
  const [userShows, setUserShows] = useState<UserShowCache>({});

  const userMoviesFetching = useRef<Set<number>>(new Set());
  const userShowsFetching = useRef<Set<number>>(new Set());

  const videoListenerRef = useRef<{ userId: number, socket: WebSocket }>();

  useEffect(() => {
    setUpListener().catch(Logger.log);
  }, [isAuthenticated]);

  async function setUpListener() {

    if (isAuthenticated) {
      const authToken = getAuthToken() || "";
      const api = new API(authToken);
      const user = await api.user();
      const userId = user.data.user_id;

      WebSocket.setAuthToken(() => getAuthToken() || "");
      const websocket = WebSocket.getInstance();


      if (videoListenerRef.current?.userId !== userId) {
        // videoListenerRef.current?.socket.disconnect();
        videoListenerRef.current = {
          userId: user.data.user_id,
          socket: websocket
        };
      }

      WebSocket.getInstance().private(`video.${userId}`)
        .stopListening("UserMovieAdded")
        .stopListening("UserMovieDeleted")
        .stopListening("UserEpisodeAdded")
        .stopListening("UserEpisodeDeleted")
        .listen("UserMovieAdded", (data: MovieEventData) => fetchMovieData(data.media_id))
        .listen("UserMovieDeleted", (data: MovieEventData) => delete userMovies[data.media_id])
        .listen("UserEpisodeAdded", (data: EpisodeEventData) => fetchShowData(data.show_id))
        .listen("UserEpisodeDeleted", (data: EpisodeEventData) => delete userShows[data.show_id])
    } else {
      if (videoListenerRef.current?.socket) {
        // videoListenerRef.current?.socket.disconnect();
        videoListenerRef.current = undefined;
      }

    }
  }

  function needsRefreshing(date: number) {
    // 30 Minute TTL
    return (Date.now() - date) >= 30*60*1000;
  }

  function fetchMovieData(id: number) {
    const api = new API(getAuthToken() || "");

    return api.userMovie(id).view()
      .then(result => {
        setUserMovies(prev => {
          const updated = {...prev};
          updated[id] = {
            data: result.data,
            updatedAt: Date.now()
          };
          return updated;
        });
      })
  }

  function fetchShowData(id: number) {
    const api = new API(getAuthToken() || "");
    return api.userShow(id).view()
      .then(result => {
        setUserShows(prev => {
          const updated = {...prev};
          updated[id] = {
            data: result.data,
            updatedAt: Date.now()
          };
          return updated;
        });
      })
  }

  function getMovie(id: number) {
    if (id in userMovies && !needsRefreshing(userMovies[id].updatedAt)) {
      return userMovies[id].data;
    }


    if (!userMoviesFetching.current.has(id)) {
      userMoviesFetching.current.add(id);
      fetchMovieData(id)
        .catch(Logger.log)
        .finally(() => userMoviesFetching.current.delete(id));
    }

    return id in userMovies ? userMovies[id].data : undefined;
  }

  function getShow(id: number) {
    if (id in userShows && !needsRefreshing(userShows[id].updatedAt)) {
      return userShows[id].data;
    }

    if (!userShowsFetching.current.has(id)) {
      userShowsFetching.current.add(id);
      fetchShowData(id)
        .catch(Logger.log)
        .finally(() => userShowsFetching.current.delete(id));
    }


    return id in userShows ? userShows[id].data : undefined;
  }

  /**
   * @param id Movie ID
   * @param callback Callback for before state is updated
   */
  function deleteMovie(id: number, callback?: () => void) {
    const api = new API(getAuthToken() || "");
    return api.userMovie(id).delete()
      .then(() => {
        setUserMovies(prev => {
          callback && callback();
          const updated = {...prev};
          id in updated && delete updated[id];
          return updated;
        });
        return fetchMovieData(id);
      });
  }

  function deleteEpisode(showId: number, seasonId: number, episodeId: number) {
    const api = new API(getAuthToken() || "");
    return api.userEpisode(showId, seasonId, episodeId).delete()
      .then(() => {
        setUserShows(prev => {

          const show = prev[showId];
          if (!show) {
            return prev;
          }

          const season = show.data.seasons.find(season => season.season_id === seasonId);

          if (!season) {
            return prev;
          }

          season.episodes = season.episodes.filter(episode => episode.media_id !== episodeId);

          return {...prev};
        });
        return fetchShowData(showId);
      });
  }


  const memoedValue = useMemo(
    () => ({
      getMovie,
      getShow,
      deleteMovie,
      deleteEpisode
    }),
    [userShows, userMovies]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <UserContentContext.Provider value={memoedValue}>
      {props.children}
    </UserContentContext.Provider>
  );
}


function useUserContent() {
  return useContext(UserContentContext);
}

export {useUserContent, UserContentProvider};
