import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import Slider from "../components/Slider";
import Hls, {HlsConfig} from "hls.js";

import {BiExitFullscreen, BiFullscreen} from "react-icons/bi";
import {BsPlayFill} from "react-icons/bs";
import {
  IoArrowBackOutline,
  IoPause,
  IoVolumeMedium,
  IoVolumeMute,
} from "react-icons/io5";
import {MdForward10, MdReplay10} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import {useProfile} from "../hooks/useProfile";
import {useThrottledCallback} from "use-debounce";
import Logger from "../models/Logger";
import SessionToken from "../models/SessionToken";

type PlayerScreenProps = {
  mediaType: "episode" | "movie";
};

export default function PlayerScreen(props: PlayerScreenProps) {

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Video controls
  const [showControls, setShowControls] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(1);
  const [position, setPosition] = useState<number>(1);
  const [volume, setVolume] = useState<number>(1);
  const [muted, setMuted] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const [showSessionError, setShowSessionError] = useState<boolean>(false);

  // Video data
  const [videoId, setVideoId] = useState<number>();
  const [sessionToken, setSessionToken] = useState<string>();
  const [initialProgress, setInitialProgress] = useState<number>();
  const [next, setNext] = useState<{ show_id: number, season_id: number, media_id: number }>();

  // Make sure not to reload video on token refresh
  const [lastLoadedVideo, setLastLoadedVideo] = useState<number>();

  const [playerLoaded, setPlayerLoaded] = useState<boolean>(false);
  const hlsRef = useRef<Hls>();

  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const handle = useFullScreenHandle();

  const navigate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const {showId, seasonId, episodeId, movieId} = useParams();


  const updateProfileProgress = (profileId: number, progress: number) => {
    const api = new API(auth.getAuthToken() || "");

    if (!profile || initialProgress === undefined || !progress) {
      return;
    }

    progress = Math.floor(progress);

    if (props.mediaType === "movie") {
      api.movieProgress(
        profileId,
        Number(movieId)
      )
        .update({progress: progress})
        .catch(Logger.log);
    } else if (props.mediaType === "episode") {
      api.episodeProgress(
        profileId,
        Number(showId),
        Number(seasonId),
        Number(episodeId)
      )
        .update({progress: progress})
        .catch(Logger.log);
    }
  };

  const debouncedUpdateProfileProgress = useThrottledCallback(updateProfileProgress, 5000, {leading: true});

  const fadeControls = () => {
    setShowControls(true);
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowControls(false)
    }, 3000);
  }


  const togglePlay = () => {
    timer.current && clearTimeout(timer.current);

    if (isPlaying) {
      videoRef.current?.pause();
      setShowControls(true);
    } else {
      fadeControls();
      videoRef.current?.play();
    }
  }

  // Load media data
  useEffect(() => {
    const api = new API(auth.getAuthToken() || "");

    if (props.mediaType === "episode") {

      api.userEpisode(Number(showId), Number(seasonId), Number(episodeId)).view()
        .then(result => {
          setTitle(result.data.name);
          setVideoId(result.data.video_id);
          setNext(result.data.next);
        })
        .catch(Logger.log);
    } else {
      api.userMovie(Number(movieId)).view()
        .then(result => {
          setTitle(result.data.title);
          setVideoId(result.data.video_id);
        })
        .catch(Logger.log);
    }


  }, [showId, seasonId, episodeId, movieId]);

  // Initialise session
  useEffect(() => {
    if (!videoId) {
      return;
    }

    const authToken = auth.getAuthToken() || "";
    SessionToken.requestSessionToken(authToken, videoId)
      .then(result => {
        setSessionToken(result.session_token)
      })
      .catch(error => {
        setShowSessionError(true);
        Logger.error(error);
      });

    const refreshInterval = setInterval(() => {
      const expiresOn = SessionToken.getExpiry();

      // Refresh 3 seconds before expiry
      if (expiresOn && Date.now() > expiresOn - (3000)) {
        SessionToken.clearExpiresIn();

        SessionToken.requestSessionToken(authToken, videoId)
          .then(result => setSessionToken(result.session_token))
          .catch(error => {
            setShowSessionError(true);
            Logger.error(error);
          });
      }
    }, 2000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [videoId]);

  // Set next action
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onended = () => {
        setSessionToken(undefined);
        setPlayerLoaded(false);

        const currentProfile = profile.getCurrentProfile();
        hlsRef.current?.detachMedia();
        videoRef.current && hlsRef.current?.attachMedia(videoRef.current);

        if (currentProfile && videoRef.current) {
          updateProfileProgress(currentProfile.id, videoRef.current.duration);
        }

        if (next) {
          navigate(`/watch/show/${next.show_id}/${next.season_id}/${next.media_id}`, {replace: true});
          setNext(undefined);
        } else {
          if (props.mediaType === "movie") {
            navigate(`/movie/${movieId}`);
          } else {
            navigate(`/show/${showId}`);
          }
        }
      }
    }
  }, [playerLoaded, videoId, next]);

  // Fetch watch progress
  useEffect(() => {
    const currentProfile = profile.getCurrentProfile();

    if (!currentProfile || !duration) {
      return;
    }

    const api = new API(auth.getAuthToken() || "");

    if (props.mediaType === "episode") {
      api.episodeProgress(currentProfile.id, Number(showId), Number(seasonId), Number(episodeId)).view()
        .then(result => {
          let progress = result.data.progress;

          // If less than 30 seconds from the end, restart progress
          if (duration - 30 < progress) {
            progress = 0;
          }

          setInitialProgress(progress);
        })
        .catch(() => setInitialProgress(0));
    } else {
      api.movieProgress(currentProfile.id, Number(movieId)).view()
        .then(result => {
          let progress = result.data.progress;

          // If less than 30 seconds from the end, restart progress
          if (duration - 30 < progress) {
            progress = 0;
          }

          setInitialProgress(progress);
        })
        .catch(() => setInitialProgress(0));
    }
  }, [profile, duration]);

  // Initialise player
  useEffect(() => {
    if (!profile) {
      return;
    }

    if (!sessionToken) {
      return;
    }

    if (playerLoaded) {
      return;
    }

    timer.current = setTimeout(() => {
      setShowControls(true)
    }, 500);


    if (!videoRef.current) {
      return;
    }

    if (Hls.isSupported()) {
      const config: Partial<HlsConfig> = {
        xhrSetup: xhr => {
          xhr.setRequestHeader("Authorization", `Bearer ${auth.getAuthToken()}`);
          xhr.setRequestHeader("Pocketter-Video-Session", SessionToken.getToken() || "");
        },
        debug: process.env.NODE_ENV !== "production"
      };

      hlsRef.current = new Hls(config);

      videoRef.current.ontimeupdate = () => {
        if (videoRef.current) {
          setPosition(videoRef.current.currentTime);

          const currentProfile = profile.getCurrentProfile();


          if (currentProfile) {
            debouncedUpdateProfileProgress(currentProfile.id, videoRef.current.currentTime);
          }
        }
      }

      hlsRef.current.attachMedia(videoRef.current);
      videoRef.current.autoplay = true;

      videoRef.current.onplay = () => {
        setIsPlaying(true);
      }
      videoRef.current.onpause = () => {
        setIsPlaying(false);
      }
      videoRef.current.onloadeddata = () => {
        if (videoRef.current)
          setDuration(videoRef.current.duration);
      }
      videoRef.current.onwaiting = () => {
        setIsLoading(true);
      }
      videoRef.current.oncanplay = () => {
        setIsLoading(false);
      }

      setPlayerLoaded(true);
    }

  }, [profile, sessionToken, showId, seasonId, episodeId, movieId]);

  // Load video
  useEffect(() => {
    if (!videoId || !playerLoaded || !sessionToken || initialProgress === undefined) {
      return;
    }

    if (lastLoadedVideo !== videoId) {
      setLastLoadedVideo(videoId);
      hlsRef.current?.loadSource(`${process.env.REACT_APP_API_URL}/v1/video/videos/${videoId}`);
      videoRef.current?.play();
    }

  }, [videoId, initialProgress, playerLoaded, sessionToken, lastLoadedVideo]);

  // Set initial progress
  useEffect(() => {
    if (initialProgress === undefined || !videoRef.current) {
      return;
    }

    videoRef.current.currentTime = initialProgress;
  }, [duration, initialProgress, playerLoaded]);

  const videoCleanUpCallback = useCallback(() => {
    videoRef.current && videoRef.current.remove();
    if (hlsRef.current) {
      hlsRef.current.removeAllListeners();
      hlsRef.current.destroy();
    }
  }, [playerLoaded]);

  useEffect(() => {
    return () => {
      videoCleanUpCallback();
    };
  }, []);


  return (
    <FullScreen handle={handle}>
      <meta name="theme-color" content="#000"/>

      <Container fluid={true} style={styles.container} className="vh-100" ref={containerRef}>

        <video ref={videoRef} style={{position: "fixed", top: 0, left: 0, right: 0, width: "100vw", height: "100vh"}}/>

        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.2)",
            opacity: showControls ? 1 : 0,
            justifyContent: "center",
            alignContent: "center",
            cursor: showControls ? "default" : "none"
          }}
          onClick={() => {
            togglePlay();
          }}
          onMouseMove={() => {
            isPlaying ? fadeControls() : setShowControls(true);
          }}
        >
          <Container fluid={true}>
            <Row className="py-5 fixed-top">
              <Col xs={3} sm={2}>
                {
                  !handle.active &&
                  <Button
                    variant="link"
                    onClick={event => {
                      event.stopPropagation();
                      navigate(-1);
                    }}
                    className="row"
                    style={{cursor: showControls ? "pointer" : "none"}}
                  >
                    <IoArrowBackOutline size="2em" color="white"/>
                  </Button>

                }
              </Col>
              <Col xs={6} sm={8} className="d-flex justify-content-center">
                <div>
                  <h4 className="text-center text-white">{title}</h4>
                </div>

              </Col>
              <Col xs={3} sm={2} className="d-flex flex-column" style={{color: "red"}}>
                <Button
                  variant="link"
                  onClick={event => {
                    event.stopPropagation();
                    handle.active ? handle.exit() : handle.enter();
                  }}
                  className="mx-auto pb-3"
                  style={{cursor: showControls ? "pointer" : "none"}}
                >
                  {
                    handle.active ?
                      <BiExitFullscreen size="2em" color="white"/>
                      :
                      <BiFullscreen size="2em" color="white"/>
                  }
                </Button>
                <div className="mx-auto position-relative d-none d-md-block">
                  <div className=""
                       style={{width: "120px", top: "8px", left: "-130px", position: "absolute", zIndex: 300}}>
                    <Slider
                      containerStyle={{
                        cursor: showControls ? "pointer" : "pointer"
                      }}
                      position={volume}
                      onChange={value => {
                        if (videoRef.current) {
                          const volume = Math.min(Math.max(value, 0), 1);
                          videoRef.current.volume = volume;
                          setVolume(volume);
                        }
                      }}
                    />


                  </div>
                  <Button
                    variant="link"
                    onClick={event => {
                      if (videoRef.current) {
                        videoRef.current.muted = !muted;
                        setMuted(!muted);
                      }
                      event.stopPropagation();
                    }}
                    style={{cursor: showControls ? "pointer" : "none"}}
                  >

                    {
                      muted ? <IoVolumeMute size="2em" color="white"/> : <IoVolumeMedium size="2em" color="white"/>
                    }

                  </Button>
                </div>

              </Col>
            </Row>

            <Row className="my-auto justify-content-center">
              <Col xs={3} className="d-flex justify-content-center">
                <Button
                  variant="link"
                  onClick={(event) => {
                    if (videoRef.current) {
                      const newTime = Math.max(videoRef.current.currentTime - 10, 0.0001);
                      videoRef.current.currentTime = newTime;
                      setPosition(newTime);
                    }
                    fadeControls();
                    event.stopPropagation();
                  }}
                  style={{cursor: showControls ? "pointer" : "none"}}
                >
                  <MdReplay10 color="white" size={"4em"}/>
                </Button>
              </Col>
              <Col xs={4} className="d-flex align-items-center justify-content-center">
                {
                  isLoading ?
                    <Spinner animation="border" variant="light" style={{width: "4em", height: "4em"}}/>
                    :
                    (
                      <Button
                        variant="link"
                        onClick={(event) => {
                          togglePlay();
                          event.stopPropagation();
                        }}
                        style={{cursor: showControls ? "pointer" : "none"}}
                      >
                        {
                          isPlaying ? <IoPause color="white" size={"6em"}/> : <BsPlayFill color="white" size={"8em"}/>
                        }
                      </Button>
                    )
                }
              </Col>
              <Col xs={3} className="d-flex justify-content-center">
                <Button
                  variant="link"
                  onClick={(event) => {
                    if (videoRef.current) {
                      const newTime = Math.min(videoRef.current.currentTime + 10, duration);
                      videoRef.current.currentTime = newTime;
                      setPosition(newTime);
                    }
                    fadeControls();
                    event.stopPropagation();
                  }}
                  style={{cursor: showControls ? "pointer" : "none"}}
                >
                  <MdForward10 className="material-icons-round" fontVariant="rounded" color="white" size={"4em"}/>
                </Button>
              </Col>
            </Row>


            <Row className="py-5 fixed-bottom">
              <Col xs={3} sm={2} className="justify-content-center">
                <Col xs={11} className="d-flex justify-content-center">
                  <span style={styles.timeLabel}>{secondsToHuman(duration - position)}</span>
                </Col>
              </Col>
              <Col xs={9} sm={10} className="justify-content-center">
                <Col xs={11} className="h-100 d-flex justify-content-center align-items-center">
                  <Slider
                    position={(position + 0.0001) / duration}
                    containerStyle={{cursor: showControls ? "pointer" : "none"}}
                    slidingBegan={() => {
                      videoRef.current && videoRef.current.pause()
                    }}
                    slidingEnded={() => {
                      videoRef.current && videoRef.current.play()
                    }}
                    onChange={value => {
                      if (videoRef.current) {
                        const newPosition = duration * value;
                        videoRef.current.currentTime = newPosition;
                        setPosition(newPosition);
                      }
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </Container>

          <Modal className="text-white" show={showSessionError} onHide={() => setShowSessionError(false)} centered={true}>
            <Modal.Header closeButton closeVariant="white">
              <Modal.Title>Too many screens watching</Modal.Title>
            </Modal.Header>
            <Modal.Body>Try again in 2 minutes.</Modal.Body>
          </Modal>
        </div>
      </Container>
    </FullScreen>

  );
}

function secondsToHuman(s: number) {

  if (s < 1) {
    return "0:00";
  }

  const seconds = Math.floor(s % 60);
  const minutes = Math.floor((s / 60) % 60);
  const hours = Math.floor((s / 3600) % 24)

  let result = [];

  let padString = "";

  if (hours > 0) {
    result.push(hours.toString().padStart(2, padString));
    padString = "0";
  }

  result.push(minutes.toString().padStart(2, padString));
  padString = "0";

  result.push(seconds.toString().padStart(2, padString));

  return result.join(':');
}

const styles = {
  container: {
    backgroundColor: "black",
  },
  controlsTop: {},
  title: {
    color: "white"
  },
  timeLabel: {
    color: "white"
  }
};
