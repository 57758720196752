import React from "react";
import {Form} from "react-bootstrap";
import {FormControlProps} from "react-bootstrap/FormControl";

export default function TextInput({className, ...props}: React.InputHTMLAttributes<HTMLInputElement> & FormControlProps) {
  return (
    <Form.Control
      className={"bg-black bg-opacity-50 shadow p-3 border-0 text-white " + className}
      type="text"
      {...props}
    />
  );
}
