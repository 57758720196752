import React, {useEffect, useState} from "react";
import {EpisodeProgress, UserEpisode, UserSeason} from "../models/APITypes";
import EpisodeCard from "./EpisodeCard";
import {MovieDBSeasonItem} from "../models/UploaderTypes";
import {Container, Spinner} from "react-bootstrap";
import MovieDBRequest from "../models/MovieDBRequest";
import Logger from "../models/Logger";

export type SeasonListProps = {
  seasonData: UserSeason;
  progress?: { [episodeId: number]: EpisodeProgress; }
  showHidden?: boolean;
};

function fetchHiddenEpisodes(showId: number, seasonId: number) {
  return MovieDBRequest.tvSeason(showId, seasonId).details()
    .catch(error => {
      Logger.log(error);
      return undefined;
    });
}

export default function SeasonList(props: SeasonListProps) {

  const [hiddenSeasonData, setHiddenSeasonData] = useState<{ [seasonId: number]: MovieDBSeasonItem; }>({});
  const [mappedEpisodes, setMappedEpisodes] = useState<{ [id: number]: UserEpisode; }>({});




  useEffect(() => {
    Logger.log(props.seasonData);
    if ((!props.showHidden && props.seasonData.episodes.length > 0) || props.seasonData.season_id in hiddenSeasonData) {
      return;
    }
    Logger.log("data being fetched");

    fetchHiddenEpisodes(props.seasonData.show_id, props.seasonData.season_id)
      .then(data => {

        if (data) {
          setHiddenSeasonData(prev => {
            prev[props.seasonData.season_id] = data;
            return {...prev};
          });
        }

      })
      .catch(Logger.log)

  }, [props.seasonData.show_id, props.seasonData.season_id, props.showHidden]);

  useEffect(() => {
    const userEpisodeDict: {[id: number]: UserEpisode} = {};
    for (const episode of props.seasonData.episodes) {
      userEpisodeDict[episode.media_id] = episode;
    }
    setMappedEpisodes(userEpisodeDict);
  }, [props.seasonData.episodes]);



  // If we should be showing hidden episodes, but we don't have them yet
  if ((props.showHidden || props.seasonData.episodes.length === 0) && !(props.seasonData.season_id in hiddenSeasonData)) {
    return (
      <Container className="d-flex justify-content-center">
        <Spinner variant="primary"/>
      </Container>
    )
  }


  return (
    <ul className="list-unstyled d-grid" style={styles.wrapper}>

      {
        props.showHidden || props.seasonData.episodes.length === 0 ?
          ((hiddenSeasonData[props.seasonData.season_id] || {episode: undefined}).episodes || []).map(episode => (
            <EpisodeCard
              showId={props.seasonData.show_id}
              seasonId={props.seasonData.season_id}
              mediaId={episode.episode_number || 0}
              name={episode.name}
              overview={episode.overview}
              stillPath={episode.still_path || ""}
              progress={props.progress}
              hidden={!((episode.episode_number || -1) in mappedEpisodes)}
            />
          ))
          :
          props.seasonData.episodes.map(episode => (
            <EpisodeCard
              showId={episode.show_id}
              seasonId={episode.season_id}
              mediaId={episode.media_id}
              name={episode.name}
              overview={episode.overview}
              stillPath={episode.still_path}
              progress={props.progress}
              duration={episode.duration}

            />
          ))
      }
    </ul>
  );
}

const styles = {
  wrapper: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: 11,
    gridAutoFlow: "dense"
  }
};
