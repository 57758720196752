import {MediaType, UploadProgressType, UploadQueueType, UploadStateType} from "./UploaderTypes";
import UploaderInterface, {UploadEvent} from "./UploaderInterface";
import Upload from "./Upload";
import API from "./API";
import WebSocket from "./WebSocket";
import Logger from "./Logger";

type StatusResult = {
  type: MediaType,
  media_id: number,
  season_id?: number,
  show_id?: number,
  upload_id: number
};

type ProgressResult = {
  progress: number,
} & StatusResult;


export default class Uploader implements UploaderInterface {

  private static instance: Uploader | undefined = undefined;

  private authToken: () => string = () => "";

  private uploadProgressListener?: (uploadState: UploadStateType, uploadData: UploadQueueType) => void;
  private uploadStatusListener?: (event: UploadEvent, uploadData: UploadQueueType) => void;

  uploads: Map<string, Upload> = new Map<string, Upload>();
  pendingUploadQueue: Array<UploadQueueType> = [];
  // Map queue data to an upload id
  encodingUploads: Map<string, number> = new Map<string, number>();

  private webSocket?: WebSocket;

  private static maxUploads = 1;
  private currentUploads = 0;

  private constructor() {
  }

  public fetch() {
    const api = new API(this.authToken());
    Logger.log("token: " + this.authToken)

    api.encodeJob().index()
      .then(result => {
        for (const job of result.data) {
          Logger.log("job: " + job.media_id)
          if (job.type === "episode") {
            const uploadData = {
              mediaType: MediaType.Episode,
              showId: job.show_id,
              seasonId: job.season_id,
              mediaId: job.media_id
            };

            const hash = Uploader.hashUploadData(uploadData);

            this.encodingUploads.set(hash, job.upload_id);
            this.uploadProgressListener && this.uploadProgressListener (
              {
                type: UploadProgressType.Encode,
                progress: job.progress
              },
              uploadData
            );
          }
          else if (job.type === "movie") {
            const uploadData: UploadQueueType = {
              mediaType: MediaType.Movie,
              mediaId: job.media_id
            };

            Logger.log("job media id: " + job.media_id);

            const hash = Uploader.hashUploadData(uploadData);

            this.encodingUploads.set(hash, job.upload_id);

            this.uploadProgressListener && this.uploadProgressListener (
              {
                type: UploadProgressType.Encode,
                progress: job.progress
              },
              uploadData
            );
          }
        }
      })
      .catch(Logger.log);
  }

  async cancel(uploadData: UploadQueueType): Promise<void> {
    const hashedData = Uploader.hashUploadData(uploadData);
    const upload = this.uploads.get(hashedData);
    Logger.log("id: " + upload?.getId());
    upload && upload.cancel();

    // TODO: Actually keep track of encoding uploads
    const uploadId = this.encodingUploads.get(hashedData);

    Logger.log("uploadId: " + uploadId);


    if (uploadId !== undefined) {
      const api = new API(this.authToken());
      await api.encodeJob(uploadId).delete();
    }
  }

  async enqueue(uploadData: UploadQueueType, file: File): Promise<void> {

    const upload = new Upload(
      uploadData.mediaType,
      file,
      (uploadData, progress) => {
        this.uploadProgressListenerHelper(progress, uploadData);
      },
      uploadData.mediaId,
      uploadData.mediaType === MediaType.Episode ? uploadData.seasonId : undefined,
      uploadData.mediaType === MediaType.Episode ? uploadData.showId : undefined,
    );

    await this.cancel(uploadData);
    this.uploads.set(Uploader.hashUploadData(uploadData), upload);

    this.pendingUploadQueue.push(uploadData);
    this.fillAvailableUploadSlot();

  }

  removeUploadProgressListener(): void {
    this.uploadProgressListener = undefined;
  }

  removeUploadStatusListener(): void {
    this.uploadStatusListener = undefined;
  }

  setAuthToken(token: () => string): void {
    this.authToken = token;
    // this.webSocket?.disconnect();
    this.setUpWebSocket().catch(Logger.log);
  }

  setUploadProgressListener(listener: (uploadState: UploadStateType, uploadData: UploadQueueType) => void): void {
    this.uploadProgressListener = listener;
  }

  setUploadStatusListener(listener: (event: UploadEvent, uploadData: UploadQueueType) => void): void {
    this.uploadStatusListener = listener;
  }


  private uploadStatusListenerHelper(event: UploadEvent, uploadData: UploadQueueType) {
    this.uploadStatusListener && this.uploadStatusListener(event, uploadData);
  }

  private uploadProgressListenerHelper(uploadState: UploadStateType, uploadData: UploadQueueType) {
    this.uploadProgressListener && this.uploadProgressListener(uploadState, uploadData);
  }

  private addEncodingUpload(data: StatusResult) {
    const formattedData = this.formatListenerData(data);
    this.encodingUploads.set(Uploader.hashUploadData(formattedData), data.upload_id);
  }

  private removeEncodingUpload(data: StatusResult) {
    const formattedData = this.formatListenerData(data);
    this.encodingUploads.delete(Uploader.hashUploadData(formattedData));
  }

  private async setUpWebSocket() {
    const api = new API(this.authToken());
    const user = await api.user();
    const userId = user.data.user_id;

    WebSocket.setAuthToken(this.authToken);

    const webSocket = WebSocket.getInstance();

    webSocket.private(`encode-progress.${userId}`)
      .notification(Logger.log)
      .stopListening("EncodeProgress")
      .listen("EncodeProgress", (data: ProgressResult) => {
        Logger.log(data);

        this.addEncodingUpload(data);
        this.uploadProgressListenerHelper(
          {
            type: UploadProgressType.Encode,
            progress: data.progress
          },
          this.formatListenerData(data)
        );

      })
      .stopListening("EncodePending")
      .listen("EncodePending", (data: StatusResult) => {
        this.addEncodingUpload(data);

        this.uploadProgressListenerHelper(
          {
            type: UploadProgressType.Encode,
            progress: 0
          },
          this.formatListenerData(data)
        );
      })
      .stopListening("EncodeFailed")
      .listen("EncodeFailed", (data: StatusResult) => {
        this.removeEncodingUpload(data);
        this.uploadStatusListenerHelper(
          UploadEvent.Fail,
          this.formatListenerData(data)
        );
      })
      .stopListening("EncodeCancelled")
      .listen("EncodeCancelled", (data: StatusResult) => {
        this.removeEncodingUpload(data);

        this.uploadStatusListenerHelper(
          UploadEvent.Cancel,
          this.formatListenerData(data)
        );
      })
      .stopListening("EncodeSucceeded")
      .listen("EncodeSucceeded", (data: StatusResult) => {
        this.removeEncodingUpload(data);
        this.uploadStatusListenerHelper(
          UploadEvent.Success,
          this.formatListenerData(data)
        );
      })
      .error(Logger.error);
  }

  formatListenerData(data: StatusResult): UploadQueueType {
    return data.type === MediaType.Movie ? {
      mediaType: data.type,
      mediaId: data.media_id
    } : {
      mediaType: data.type,
      mediaId: data.media_id,
      seasonId: data.season_id!,
      showId: data.show_id!
    };
  }


  private fillAvailableUploadSlot() {
    if (this.currentUploads > Uploader.maxUploads) {
      return;
    }

    const uploadData = this.pendingUploadQueue.shift();

    if (!uploadData) {
      return;
    }

    const hashedData = Uploader.hashUploadData(uploadData);
    const upload = this.uploads.get(hashedData);

    if (!upload) {
      return;
    }


    if (!this.authToken) {
      return;
    }

    this.currentUploads++;
    upload.ready(this.authToken)
      .then(() => {
        if (!this.authToken) {
          throw Error("Failed to upload - auth token undefined");
        }
        return upload.upload(this.authToken);
      })
      .catch(() => {
        this.uploadStatusListenerHelper(UploadEvent.Fail, uploadData);
      })
      .finally(() => {
        this.uploads.delete(hashedData);
        this.currentUploads--;
        this.fillAvailableUploadSlot();
      });
  }

  private static hashUploadData(uploadData: UploadQueueType): string {
    let hash = uploadData.mediaType === MediaType.Episode ? "episode" : "movie";
    hash += `|${uploadData.mediaId}`;
    if (uploadData.mediaType === MediaType.Episode) {
      hash += `|${uploadData.seasonId}|${uploadData.showId}`;
    }

    return hash;
  }

  public static getInstance(): Uploader {
    return this.instance || (this.instance = new this());
  }

  cleanUp(): void {
    // this.webSocket?.disconnect();
    this.webSocket = undefined;
    this.authToken = () => "";
    this.pendingUploadQueue = [];

    for (const [, value] of Object.entries(this.uploads)) {
      (value as Upload).cancel();
    }
  }


}
