import React, {ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
  MediaType,
  MoviesUploadStateType,
  ShowsUploadStateType,
  UploadQueueType,
  UploadStateType
} from "../models/UploaderTypes";
import UploaderInterface, {UploadEvent} from "../models/UploaderInterface";
import UploadModal, {UploadModalOpeningStateType} from "../components/UploadModal/UploadModal";
import MovieDBRequest from "../models/MovieDBRequest";
import Logger from "../models/Logger";

type UploaderContextType = {
  enqueue: (uploadData: UploadQueueType, file: File) => void;
  cancel: (uploadData: UploadQueueType) => void;
  uploader?: UploaderInterface;

  showsUploadState: ShowsUploadStateType;
  moviesUploadState: MoviesUploadStateType;

  getEpisodeUploadState: (showId: number, seasonId: number, episodeId: number) => UploadStateType|undefined,
  getMovieUploadState: (movieId: number) => UploadStateType|undefined;


  alerts: AlertType[];
  removeAlert: (alert: AlertType) => void;
  showUploadModal: (state?: UploadModalOpeningStateType) => void;
};


const UploaderContext = React.createContext<UploaderContextType>({} as UploaderContextType);

export type AlertVariant = "primary"|"success"|"info"|"danger"|"warning";

export type AlertType = {
  id: number;
  title: string;
  type: AlertVariant;
};

export type UploaderProviderPropsType = {
  children: ReactNode;
  uploader: UploaderInterface;
};


function UploaderProvider(props: UploaderProviderPropsType) {


  const [uploadModalOpeningState, setUploadModalOpeningState] = useState<UploadModalOpeningStateType>();

  const [showsUploadState, setShowsUploadState] = useState<ShowsUploadStateType>({});
  const [moviesUploadState, setMoviesUploadState] = useState<MoviesUploadStateType>({});

  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const alertIdRef = useRef<{idCount: number}>({idCount: 1});


  useEffect(() => {
    props.uploader.removeUploadProgressListener();
    props.uploader.removeUploadStatusListener();
    props.uploader.setUploadStatusListener((event, uploadData) => {
      // addIfMissing(uploadData);
      if (event === UploadEvent.Fail) {
        removeUpload(uploadData);
        pushAlert(uploadData, {title: "Failed to upload", type: "danger"}).catch(Logger.log);
      } else if (event === UploadEvent.Cancel) {
        removeUpload(uploadData);
        pushAlert(uploadData, {title: "Cancelled", type: "info"}).catch(Logger.log);
      } else if (event === UploadEvent.Success) {
        removeUpload(uploadData);
        pushAlert(uploadData, {title: "Uploaded successfully", type: "success"}).catch(Logger.log);
      }
    });

    props.uploader.setUploadProgressListener((uploadState, uploadData) => {
      Logger.log("upload progress " + uploadState.type + " " +  uploadState.progress);
      updateUploadState(uploadData, uploadState);
    });

    props.uploader.fetch();
  }, []);

  function removeUpload(uploadData: UploadQueueType) {
    if (uploadData.mediaType === MediaType.Episode) {
      setShowsUploadState(prev => {
        const updated = {...prev};
        if (updated[uploadData.showId] === undefined) {
          updated[uploadData.showId] = {};
        }

        if (updated[uploadData.showId][uploadData.seasonId] === undefined) {
          updated[uploadData.showId][uploadData.seasonId] = {};
        }

        if (updated[uploadData.showId][uploadData.seasonId][uploadData.mediaId]) {
          delete updated[uploadData.showId][uploadData.seasonId][uploadData.mediaId];
        }

        // Is season now empty?
        if (Object.keys(updated[uploadData.showId][uploadData.seasonId]).length === 0) {
          delete updated[uploadData.showId][uploadData.seasonId];
        }

        // Is show now empty?
        if (Object.keys(updated[uploadData.showId]).length === 0) {
          delete updated[uploadData.showId];
        }

        return updated;
      });
    }
    else if (uploadData.mediaType === MediaType.Movie) {
      setMoviesUploadState(prev => {
        const updated = {...prev};

        if (updated[uploadData.mediaId] !== undefined) {
          delete updated[uploadData.mediaId];
        }

        return updated;
      });
    }
  }

  function updateUploadState(uploadData: UploadQueueType, state: UploadStateType) {
    if (uploadData.mediaType === MediaType.Episode) {
      setShowsUploadState(prev => {
        const updated = {...prev};
        if (updated[uploadData.showId] === undefined) {
          updated[uploadData.showId] = {};
        }

        if (updated[uploadData.showId][uploadData.seasonId] === undefined) {
          updated[uploadData.showId][uploadData.seasonId] = {};
        }

        let added_on = new Date();

        if (updated[uploadData.showId][uploadData.seasonId][uploadData.mediaId] !== undefined) {
          added_on = updated[uploadData.showId][uploadData.seasonId][uploadData.mediaId].added_on;
        }

        updated[uploadData.showId][uploadData.seasonId][uploadData.mediaId] = {
          state: state,
          added_on: added_on
        };

        return updated;
      });
    } else if (uploadData.mediaType === MediaType.Movie) {
      setMoviesUploadState(prev => {
        const updated = {...prev};
        let added_on = new Date();

        if (updated[uploadData.mediaId] !== undefined) {
          added_on = updated[uploadData.mediaId].added_on;
        }

        updated[uploadData.mediaId] = {
          state: state,
          added_on: added_on
        };

        return updated;
      });
    }
  }

  async function enqueue(uploadData: UploadQueueType, file: File) {
    await props.uploader.enqueue(uploadData, file);
    await pushAlert(
      uploadData,
      {
        title: "Enqueued",
        type: "info"
      });
  }

  async function cancel(uploadData: UploadQueueType) {
    await props.uploader.cancel(uploadData);
  }

  function getMovieUploadState(movieId: number): UploadStateType | undefined {
    return moviesUploadState[movieId] ? moviesUploadState[movieId].state : undefined;
  }

  function getEpisodeUploadState(showId: number, seasonId: number, episodeId: number) {
    const showState = showsUploadState[showId];



    if (showState === undefined) {
      return undefined;
    }

    const seasonState = showState[seasonId];

    if (!seasonState || !seasonState[episodeId]) {
      return undefined;
    }

    return seasonState[episodeId].state;
  }

  function removeAlert(alert: AlertType) {
    setAlerts(prev => prev.filter(e => e.id !== alert.id));
  }

  async function pushAlert(uploadData: UploadQueueType, message: { title: string, type: AlertVariant}) {
    const id = alertIdRef.current.idCount++;
    if (uploadData.mediaType === MediaType.Episode) {
      const episodeInfo = await MovieDBRequest.tvEpisode(uploadData.showId, uploadData.seasonId, uploadData.mediaId).details();
      const episodeName = episodeInfo.name ? episodeInfo.name : uploadData.mediaId;

      setAlerts(prev => prev.concat({title: `${episodeName} - ${message.title}`, type: message.type, id: id}));
    }
    else if (uploadData.mediaType === MediaType.Movie) {
      const movieInfo = await MovieDBRequest.movie(uploadData.mediaId).details();
      const name = movieInfo.title ? movieInfo.title : uploadData.mediaId;
      setAlerts(prev => prev.concat({title: `${name} - ${message.title}`, type: message.type, id: id}));

    }
  }

  function showUploadModal(state?: UploadModalOpeningStateType) {
    setUploadModalOpeningState(state || {})
  }

  const memoedValue = useMemo(
    () => ({
      enqueue,
      cancel,
      showsUploadState,
      moviesUploadState,
      getMovieUploadState,
      getEpisodeUploadState,
      alerts,
      removeAlert,
      showUploadModal
    }),
    [showsUploadState, moviesUploadState, alerts, uploadModalOpeningState]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <UploaderContext.Provider value={memoedValue}>
      {props.children}
      <UploadModal openingState={uploadModalOpeningState} hide={() => setUploadModalOpeningState(undefined)}/>
    </UploaderContext.Provider>
  );
}


function useUploader() {
  return useContext(UploaderContext);
}

export {useUploader, UploaderProvider};
