import React from "react";
import ControlButton, {ControlButtonProps} from "./ControlButton";
import {IoVolumeMedium, IoVolumeMute} from "react-icons/io5";

export type VolumeButtonProps = {
  muted?: boolean;
} & ControlButtonProps;

export default function VolumeButton({muted, ...props}: VolumeButtonProps) {
  return (
    <ControlButton {...props}>
      {
        muted ? <IoVolumeMute size="2em" color="white"/> : <IoVolumeMedium size="2em" color="white"/>
      }
    </ControlButton>
  );
}
