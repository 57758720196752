import Upload from "./Upload";
import API from "./API";
import {AxiosResponse} from "axios";

export default class UploadSegment {

  static maxSegmentSize = 5242880;
  static maxAttempts = 3;

  private readonly partNumber: number;
  private readonly start: number;
  private readonly size: number;
  private readonly parent: Upload;
  private attempts = 0;

  constructor(partNumber: number, start: number, size: number, parent: Upload) {
    this.partNumber = partNumber;
    this.start = start;
    this.size = size;
    this.parent = parent;
  }

  getPartNumber() {
    return this.partNumber;
  }

  getStart(): number {
    return this.start;
  }

  getEnd(): number {
    return this.start + this.size;
  }

  getSize(): number {
    return this.size;
  }

  getAttemptCount(): number {
    return this.attempts;
  }

  getParent(): Upload {
    return this.parent;
  }

  async upload(token: () => string): Promise<AxiosResponse> {
    this.attempts++;

    const api = new API(token());


    const parentId = this.getParent().getId();

    if (!parentId) {
      throw new Error("Segment parent ID missing");
    }

    const buffer = this.parent.getFile().slice(this.start, this.start + this.size);

    try {
      const result = await api.uploadSegment(parentId, this.getPartNumber()).update(buffer);

      this.parent.addCompletedBytes(this.getSize());

      return result;
    }
    catch (e) {
      if (this.attempts < UploadSegment.maxAttempts) {
        await new Promise(r => setTimeout(r, 2000));
        return await this.upload(token);
      }

      throw e;
     }
  }
}
