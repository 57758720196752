import {Col, Container, Image, ListGroupItemProps, Placeholder, Row} from "react-bootstrap";
import React from "react";
import {
  IoChevronForward,
  IoFilmOutline,
  IoTvOutline
} from "react-icons/io5";

type UploadModalSearchItemProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  image?: string;
  title: string;
  labels?: string[];
  description?: string;
  itemId?: number;
  selectedItemId?: number;
  type?: "show" | "movie" | "season" | "episode";
};

export default function UploadModalSearchItem(props: UploadModalSearchItemProps & ListGroupItemProps) {

  const centerCol = () => {
    if (!props.action) {
      return 9;
    }

    return props.type === "episode" ? 7 : 8;
  };

  return (
    <Container className=" px-0 mx-0 mb-2" role={props.action ? "button" : undefined} style={props.style} {...props}>
      <div className="w-100 h-100 py-1 ">

        <Row
          className={"h-100 overflow-hidden py-3 rounded px-0 mx-0"}
          style={{opacity: props.disabled ? 0.5 : 1, maxHeight: "100%"}}>


          <Col xs={props.type === "episode" ? 3 : 2}
               className="d-none d-sm-flex justify-content-start px-0 my-auto h-100"
               style={{maxWidth: 250, maxHeight: "100%"}}>
            <div className="d-flex h-100 mx-auto"
                 style={{aspectRatio: props.type === "episode" ? "16:9" : "2:3", maxHeight: "100%"}}>
              {
                props.image ?
                  <Image
                    className="rounded my-auto"
                    src={"https://image.tmdb.org/t/p/w154" + props.image}
                    style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}
                    alt={props.title}
                  />
                  :
                  <div className="bg-menu bg-gradient rounded d-flex overflow-hidden h-100 w-100">
                    <h5 className="my-auto  text-center my-auto text-white opacity-0">{props.title}</h5>
                  </div>
              }
            </div>
          </Col>
          <Col xs={props.action ? 9 : undefined} sm={centerCol()} className="position-relative my-auto"
               style={{maxHeight: "100%"}}>
            <p className="lead m-0 text-truncate">
              {props.title}
            </p>
            <div className="d-flex">
              {
                props.type === "show" &&
                <IoTvOutline size="1.25em" className="text-primary me-2 my-auto"/>
              }

              {
                props.type === "movie" &&
                <IoFilmOutline size="1.25em" className="text-primary me-2 my-auto"/>
              }


              <small className=" m-0 my-auto text-muted text-middle" style={{lineHeight: "1.75em "}}>

                {
                  props.labels && props.labels.map(label => label + " ")
                }
              </small>
            </div>

            <small className="m-0 overflow-hidden line-clamp-2">
              {props.description}
            </small>
          </Col>
          {
            props.action &&
            <Col xs={2}
                 className=" my-auto d-flex px-0 justify-content-end align-items-end btn-link text-decoration-none"
                 style={{maxHeight: "100%"}}>

              <IoChevronForward size="2em"/>
            </Col>
          }
          <div className="position-absolute bottom-0 end-0 text-end text-primary me-2 mb-2">
          </div>
        </Row>
      </div>

    </Container>

  );
}
