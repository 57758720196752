import React, {ReactNode} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {BsCheck2, BsDashLg} from "react-icons/bs";
import AnimatedEnter from "./AnimatedEnter";


type GridRowProps = {
  title: ReactNode;
  columns: ReactNode[];
};

function GridRow(props: GridRowProps) {


  return (
    <Row >
      <Col xs={12} lg={2} className="offset-lg-1 py-3 py-lg-4">
        <h5 className="text-center">{props.title}</h5>
      </Col>
      {
        props.columns.map((column, index) => (
          <AnimatedEnter
            animationName="enter-left"
            className="col-3 col-lg-2 py-3 mb-5 py-lg-4 text-muted text-center"
            style={{"--animation-duration": `${0.5+(index*0.1)}s`} as React.CSSProperties}
          >
            {column}
          </AnimatedEnter>
        ))
      }
    </Row>
  );
}

export default function PlansGrid() {
  return (
    <>
      <Container className="bg-dark-highlight rounded pt-3">
        <Row className="py-4 mt-3">
          <Col xs={3} lg={2} className="border-1 text-center offset-lg-3">
            <h5>10GB</h5>
          </Col>
          <Col xs={3} lg={2} className="border-1 text-center">
            <h5>200GB</h5>
          </Col>
          <Col xs={3} lg={2} className="border-1 text-center">
            <h5>2TB</h5>
          </Col>
          <Col xs={3} lg={2} className="border-1 text-center">
            <h5>10TB</h5>
          </Col>
        </Row>

        <GridRow
          title={"Price"}
          columns={[
            "Free",
            "$2.99",
            "$14.99",
            "$39.99"
          ]}
        />

        <GridRow
          title={"Upload Storage"}
          columns={[
            "10GB",
            "200GB",
            "2TB",
            "10TB"
          ]}
        />

        <GridRow
          title={"Simultaneous Screens"}
          columns={[
            "1",
            "2",
            "4",
            "6"
          ]}
        />

        <GridRow
          title={"SD Streaming"}
          columns={[
            <BsCheck2 size={32}/>,
            <BsCheck2 size={32}/>,
            <BsCheck2 size={32}/>,
            <BsCheck2 size={32}/>
          ]}
        />

        <GridRow
          title={"HD Streaming"}
          columns={[
            <BsDashLg/>,
            <BsCheck2 size={32}/>,
            <BsCheck2 size={32}/>,
            <BsCheck2 size={32}/>,
          ]}
        />
      </Container>
      <Container>
        <small className="text-muted mb-4">* All prices in USD</small>
      </Container>
    </>


  );
}
