import React from "react";
import {Placeholder} from "react-bootstrap";

export default function FieldPlaceholder(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <Placeholder className="d-block" animation="glow">
        <span className="placeholder col-12 rounded"></span>
      </Placeholder>
    </div>
  );
}
