import React, {FormEvent, useState} from "react";
import ProfileList from "../components/ProfileList";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useProfile} from "../hooks/useProfile";
import API from "../models/API";
import useAuth from "../hooks/useAuth";
import TextInput from "../components/TextInput";
import ProfileAddScreen from "../components/ProfileModal/ProfileAddScreen";
import ProfileEditScreen from "../components/ProfileModal/ProfileEditScreen";
import Logger from "../models/Logger";

function InitialProfileCreation() {
  const [name, setName] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const profile = useProfile();
  const auth = useAuth();

  const add = (event: FormEvent) => {
    event.preventDefault();
    setIsUpdating(true);

    const api = new API(auth.getAuthToken() || "");

    api.profile().store({name: name})
      .catch(Logger.log)
      .finally(() => {
        setIsUpdating(false);
        profile.refresh();
        profile.setCurrentProfile(0);

      })
  };

  return (
    <div className="d-flex flex-column h-100" style={{maxHeight: "100%"}}>
      <Container className="p-5" style={{maxWidth: "520px"}}>
        <Row>
          <h1 className="text-center text-white">Let's make your profile</h1>
          <h5 className="py-1 text-center text-muted">
            Your profile helps you keep track of what you've watched and where you're up to.
          </h5>
        </Row>
        <Row className="px-4 my-5">
          <Form onSubmit={add}>
            <Row className="justify-content-center mb-5">
              <Col xs={12} className="my-auto">
                <TextInput type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} >
                <Button className="w-100" type="submit" disabled={isUpdating}>
                  {isUpdating ?
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    :
                    "Create"
                  }
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </div>
  );
}


export default function ProfileSelectScreen() {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editSelection, setEditSelection] = useState<number>();
  const [showAddScreen, setShowAddScreen] = useState<boolean>(false);

  const profile = useProfile();
  const profiles = profile.getProfiles();

  const backButtonTitle = () => {
    if (showAddScreen || editSelection !== undefined || isEditing) {
      return "Cancel";
    }

    return "Edit";
  }

  const backButtonPressed = () => {
    if (showAddScreen || editSelection !== undefined) {
      setEditSelection(undefined);
      setShowAddScreen(false);
      return;
    }

    setIsEditing(prev => !prev);
  }


  if (profile.loadingInitial) {
    return (
      <div className="d-flex flex-column h-100" style={{maxHeight: "100%"}}>
        <Container>
          <Row className="py-5 justify-content-center">
            <Spinner  animation="border" variant="primary"/>
          </Row>
        </Container>
      </div>
    );
  }

  if (profiles?.length === 0) {
    return (
      <InitialProfileCreation/>
    );
  }

  let inside;

  if (showAddScreen) {
    inside = <ProfileAddScreen onSuccess={() => setShowAddScreen(false)}/>;
  }
  else if (editSelection === undefined) {
    inside = <ProfileList isEditing={isEditing} onAddPressed={() => setShowAddScreen(true)} onSelection={profileIndex => isEditing && setEditSelection(profileIndex)}/>
  }
  else {
    inside = <ProfileEditScreen editingProfileId={editSelection}/>;
  }


  return (
    <div className="d-flex  vh-100 bg-dark text-white" style={{maxHeight: "100%"}}>
      <Container className="vh-100 d-flex flex-column">
        <Row className="mt-3 mt-sm-auto justify-content-center " style={{top: "-100%"}}>
          <Col xs={12} sm={10} lg={6} className="position-relative">
            <div>
              <Button
                className="text-decoration-none"
                variant="link"
                onClick={backButtonPressed}
              >
                {backButtonTitle()}
              </Button>
            </div>
          </Col>


        </Row>
        { !showAddScreen && editSelection === undefined &&
          <Row className="mt-5 mt-sm-0">
            <h2 className="text-center">
              Select a profile
            </h2>
          </Row>
        }


        <Row className=" justify-content-center mt-5 mb-auto">
          <Col xs={12} sm={8} lg={5}>
            {inside}
          </Col>
        </Row>

        {/*<Row className="justify-content-center mb-auto">*/}
        {/*  <div className="d-flex justify-content-center">*/}
        {/*    <Button*/}
        {/*      className="text-decoration-none"*/}
        {/*      variant="link"*/}
        {/*      // onClick={backButtonPressed}*/}
        {/*    >*/}
        {/*      Done*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</Row>*/}
      </Container>

    </div>
  );
}
