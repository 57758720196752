import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Card, Dropdown} from "react-bootstrap";
import MediaIcon from "./MediaIcon";
import {EpisodeProgress} from "../models/APITypes";
import {useUploader} from "../hooks/useUploader";
import {IoEllipsisVertical} from "react-icons/io5";
import {useUserContent} from "../hooks/useUserContent";
import Logger from "../models/Logger";

export type EpisodeCardProps = {

  showId: number;
  seasonId: number;
  mediaId: number;
  name?: string;
  overview?: string;
  stillPath?: string;
  hidden?: boolean;
  progress?: { [episodeId: number]: EpisodeProgress };
  duration?: number;
};

export default function EpisodeCard(props: EpisodeCardProps) {
  const [dragState, setDragState] = useState<"none" | "enter" | "over" | "leave">("none");



  const {showUploadModal} = useUploader();
  const {deleteEpisode} = useUserContent();

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragState("none");
    uploadEpisode(event.dataTransfer.files[0])
  };

  const textStyle = {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const uploadEpisode = (file?: File) => {
    showUploadModal({
      file: file,
      selectedMediaType: "tv",
      selectedMediaId: props.showId,
      selectedSeasonId: props.seasonId,
      selectedEpisodeId: props.mediaId
    });
  };

  const deleteEpisodeClicked = () => {
    deleteEpisode(props.showId, props.seasonId, props.mediaId)
      .catch(Logger.log);
  };

  const hiddenClassName = props.hidden ? " opacity-50" : "";

  return (
    <li>

      <Card
        className={"border-0 p-3 h-100 " + (dragState === "over" || dragState === "enter" ? "bg-primary bg-opacity-25" : "bg-transparent")}
        onDrop={handleDrop}
        onDragEnter={event => {
          event.preventDefault();
          event.stopPropagation();
          setDragState("enter");
        }}
        onDragLeave={event => {
          event.preventDefault();
          event.stopPropagation();
          setDragState("leave");
        }}
        onDragOver={event => {
          event.preventDefault();
          event.stopPropagation();
          setDragState("over");
        }}
      >
        {
          props.hidden ?
            <Card.Img
              as={MediaIcon}
              iconPath={props.stillPath || ""}
              progress={(props.progress && props.progress[props.mediaId] && props.progress[props.mediaId].progress) || 0}
              duration={props.duration}
              className={hiddenClassName}
              hidden={true}
            />
            :
            <Link to={`/watch/show/${props.showId}/${props.seasonId}/${props.mediaId}`}
                  className="text-reset text-decoration-none">
              <Card.Img
                as={MediaIcon}
                iconPath={props.stillPath || ""}
                progress={(props.progress && props.progress[props.mediaId] && props.progress[props.mediaId].progress) || 0}
                duration={props.duration}
                className={hiddenClassName}
              />
            </Link>
        }


        <Card.Body className="text-start px-0 d-flex flex-column">
          <div className="w-100 d-flex">
            {/* @ts-ignore*/}
            <Card.Title className={"text-truncate my-auto" + hiddenClassName}>
              {`${props.mediaId} - ` + (props.name ? props.name : `Episode ${props.mediaId}`)}
            </Card.Title>

            <Dropdown className="ms-auto my-auto">
              <Dropdown.Toggle bsPrefix="p-0" variant="link"
                               className={"text-white text-decoration-none p-0" + hiddenClassName}>
                <IoEllipsisVertical role="button" className=" flex-shrink-0 ms-auto my-auto"/>
              </Dropdown.Toggle>

              <Dropdown.Menu variant="dark">
                <Dropdown.Item onClick={() => uploadEpisode()}>Upload</Dropdown.Item>
                {
                  !props.hidden &&
                  <Dropdown.Item onClick={() => deleteEpisodeClicked()}><span className="text-danger">Delete</span></Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* @ts-ignore*/}
          <Card.Text className={hiddenClassName} style={{WebkitLineClamp: 3, ...textStyle}}>
            <small>
              {props.overview}
            </small>
          </Card.Text>
        </Card.Body>
      </Card>
    </li>
  );
}
