import {UploadQueueType, UploadStateType} from "./UploaderTypes";


export enum UploadEvent {
  Success,
  Fail,
  Cancel
}

export default interface UploaderInterface {

  setAuthToken: (token: () => string) => void;
  cleanUp: () => void;

  enqueue: (uploadData: UploadQueueType, file: File) => Promise<void>;
  cancel: (uploadData: UploadQueueType) => Promise<void>;

  setUploadStatusListener: (listener: (event: UploadEvent, uploadData: UploadQueueType) => void) => void;
  removeUploadStatusListener: () => void;

  setUploadProgressListener: (listener: (uploadState: UploadStateType, uploadData: UploadQueueType) => void) => void;
  removeUploadProgressListener: () => void;

  fetch: () => void;

};
