import * as React from 'react'
import {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import API from "../models/API";
import useAuth from "./useAuth";
import tinycolor from "tinycolor2";
import ProfileModal from "../components/ProfileModal/ProfileModal";
import Logger from "../models/Logger";


export type ProfileType = {
  id: number;
  name: string;
  colour: string;
};

interface ProfileContextType {
  profilesState: ProfileType[] | undefined;
  setProfiles: (profiles: ProfileType[]) => void;
  getProfiles: () => ProfileType[] | undefined;
  getProfile: (id: number) => ProfileType | undefined;
  getCurrentProfile: () => ProfileType | undefined;
  setCurrentProfile: (index: number) => void;
  isProfileSet: () => boolean;
  showProfileModal: () => void;
  hideProfileModal: () => void;
  localFetched: boolean;
  loadingInitial: boolean;
  refresh: () => void;
  logout: () => void;
}

const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);


export type ProfileProviderPropsType = {
  children: ReactNode;
};

const colours = [
  "#F5A300",
  "#5da8f1",
  "#ec3953",
  "#c64fee",
  "#3ab9bd",
  "#60565b",
];

function ProfileProvider(props: ProfileProviderPropsType) {
  const [profilesState, setProfilesState] = useState<ProfileType[]>();
  const [currentProfileState, setCurrentProfileState] = useState<number>();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [localFetched, setLocalFetched] = useState<boolean>(false);
  const [showProfileModalState, setShowProfileModalState] = useState<boolean>(false);

  const auth = useAuth();


  useEffect(() => {
    const profileId = window.localStorage.getItem("profile_id");

    if (profileId) {
      setCurrentProfile(Number(profileId));
    }

    setLocalFetched(true);

    fetch()
      .catch(Logger.log)
      .finally(() => setLoadingInitial(false));


  }, []);

  function setProfiles(profiles: ProfileType[]) {
    setProfilesState(profiles);
  }

  function getProfiles(): ProfileType[] | undefined {

    return profilesState;
  }

  function getProfile(id: number): ProfileType | undefined {
    const matchedProfiles = profilesState && profilesState.filter(item => item.id === id);

    if (matchedProfiles && matchedProfiles.length > 0) {
      return matchedProfiles[0];
    }

    return undefined;
  }

  function isProfileSet() {
    return currentProfileState !== undefined;
  }

  function getCurrentProfile(): ProfileType | undefined {
    return currentProfileState !== undefined ? getProfile(currentProfileState) : undefined;
  }

  function setCurrentProfile(index: number) {
    setCurrentProfileState(index);
    window.localStorage.setItem("profile_id", index.toString());
  }

  function logout() {
    window.localStorage.removeItem("profile_id");
    setCurrentProfileState(undefined);
  }

  function fetch() {
    const api = new API(auth.getAuthToken() || "");


    return api.profile().index()
      .then(result => {



        const profiles = result.data.map((profile, index) => {
          return {
            id: profile.profile_number,
            name: profile.name,
            colour: colours[index]
          };
        });

        setProfilesState(profiles);
      })
  }

  function refresh() {
    fetch()
      .catch(Logger.log);
  }

  function showProfileModal() {
    setShowProfileModalState(true);
  }

  function hideProfileModal() {
    setShowProfileModalState(false);
  }


  const memoedValue = useMemo(
    () => ({
      profilesState,
      currentProfileState,
      setProfiles,
      getProfiles,
      getProfile,
      getCurrentProfile,
      setCurrentProfile,
      isProfileSet,
      showProfileModal,
      hideProfileModal,
      localFetched,
      loadingInitial,
      refresh,
      logout
    }),
    [profilesState, currentProfileState, localFetched, loadingInitial, showProfileModalState]
  );

  return (
    <ProfileContext.Provider value={memoedValue}>
      {props.children}

      <ProfileModal show={showProfileModalState} onHide={hideProfileModal}/>
    </ProfileContext.Provider>
  )
}

function useProfile() {
  return useContext(ProfileContext);
}

export {ProfileProvider, useProfile}
