import React, {memo, useCallback, useEffect, useState} from "react";
import {
  MovieDBEpisodeItem,
  MovieDBSearchMultiItem,
  MovieDBSeasonItem,
  MovieDBShowItemSeason
} from "../../models/UploaderTypes";
import UploadModalSearchItem from "./UploadModalSearchItem";
import debounce from "lodash/debounce";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import {FixedSizeList, areEqual} from "react-window";
import {
  Col,
  Container,
  Image,
  Row,
  Spinner
} from "react-bootstrap";
import BackButton from "../BackButton";
import Search from "../../Search.svg";
import Player, {PlayerControls} from "../Player/Player";
import TextInput from "../TextInput";
import MovieDBRequest from "../../models/MovieDBRequest";
import Logger from "../../models/Logger";

type SearchRowItemType = MovieDBSearchMultiItem | { media_type: "season" } & MovieDBShowItemSeason | {
  media_type: "episode"
} & MovieDBEpisodeItem;

export type onMediaSelectedType = (media: SearchRowItemType) => void;

export type MediaSelectScreenProps = {
  uploadedFile?: File;
  selectedMediaState: [(MovieDBSearchMultiItem | undefined), React.Dispatch<React.SetStateAction<MovieDBSearchMultiItem | undefined>>];
  selectedSeasonState: [(MovieDBSeasonItem | undefined), React.Dispatch<React.SetStateAction<MovieDBSeasonItem | undefined>>];
  selectedEpisodeState: [(MovieDBEpisodeItem | undefined), React.Dispatch<React.SetStateAction<MovieDBEpisodeItem | undefined>>];
};

function fetchData(query: string): Promise<MovieDBSearchMultiItem[]> {
  if (query === "") {
    return new Promise((resolve) => resolve([]));
  }

  return MovieDBRequest.search(query).multi()
    .then(result => (result.data.results || [] as MovieDBSearchMultiItem[]).filter(result => result.media_type !== "person") || [])
    .catch(error => {
      Logger.log(error);
      return [];
    });
}

function fetchShowData(item: MovieDBSearchMultiItem): Promise<MovieDBShowItemSeason[]> {
  if (item.media_type !== "tv" || item.id === undefined) {
    return new Promise((resolve) => resolve([]));
  }

  return MovieDBRequest.tvShow(item.id).details()
    .then(data => data.seasons || [])
    .catch(error => {
      Logger.log(error);
      return [];
    });
}

function fetchSeasonData(showItem: MovieDBSearchMultiItem, seasonItem: MovieDBSeasonItem): Promise<MovieDBEpisodeItem[]> {
  if (showItem.media_type !== "tv" || showItem.id === undefined || seasonItem.season_number === undefined) {
    return new Promise((resolve) => resolve([]));
  }

  return MovieDBRequest.tvSeason(showItem.id, seasonItem.season_number).details()
    .then(data => data.episodes || [])
    .catch(error => {
      Logger.log(error);
      return [];
    });
}

function LoadingList() {
  return (
    <div className="d-flex justify-content-center p-5">
      <Spinner variant="primary" animation="border"/>
    </div>
  );
}

export default function MediaSelectScreen(props: MediaSelectScreenProps) {
  const [multiResult, setMultiResult] = useState<MovieDBSearchMultiItem[]>([]);
  const [showResult, setShowResult] = useState<MovieDBShowItemSeason[]>();
  const [seasonResult, setSeasonResult] = useState<MovieDBEpisodeItem[]>();

  const [selectedMedia, setSelectedMedia] = props.selectedMediaState;
  const [selectedSeason, setSelectedSeason] = props.selectedSeasonState;
  const [, setSelectedEpisode] = props.selectedEpisodeState;


  const [currentQuery, setCurrentQuery] = useState<string>("");

  useEffect(() => {
    if (selectedMedia && selectedMedia.media_type === "tv") {
      fetchShowData(selectedMedia).then(setShowResult);
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (selectedMedia && selectedMedia.media_type === "tv" && selectedSeason) {
      fetchSeasonData(selectedMedia, selectedSeason).then(setSeasonResult);
    }
  }, [selectedSeason]);


  const backPressed = () => {
    if (selectedSeason) {
      setSelectedSeason(undefined);
      setSeasonResult(undefined);
    } else {
      setSelectedMedia(undefined);
      setShowResult(undefined);
    }
  };


  const ResultRow = memo(({index, style, data}: {
      index: number,
      style?: React.CSSProperties,
      data: SearchRowItemType[]
    }) => {

      const mediaData = data[index];

      let title = "";
      let labels: string[] = [];
      let mediaType: "movie" | "show" | "season" | "episode" | undefined = undefined;

      let onClick = () => {};

      if (mediaData.media_type === "movie") {
        title = mediaData.title || mediaData.original_title || "";
        mediaType = "movie";
        if (mediaData.release_date) {
          labels = [mediaData.release_date.substring(0, 4)];
        }

        onClick = () => setSelectedMedia(mediaData);


      } else if (mediaData.media_type === "tv") {
        title = mediaData.name || "";
        mediaType = "show";
        if (mediaData.first_air_date) {
          labels = [mediaData.first_air_date.substring(0, 4)];
        }

        onClick = () => setSelectedMedia(mediaData);
      } else if (mediaData.media_type === "season") {
        mediaType = "season";
        title = mediaData.name || "";
        if (mediaData.air_date) {
          labels = [mediaData.air_date.substring(0, 4)];
        }
        onClick = () => setSelectedSeason(mediaData);
      } else if (mediaData.media_type === "episode") {
        title = `${mediaData.episode_number} - ` + (mediaData.name || `Episode ${mediaData.episode_number}`);
        mediaType = "episode";
        if (mediaData.air_date) {
          labels = [mediaData.air_date.substring(0, 4)];
        }

        onClick = () => setSelectedEpisode(mediaData);
      } else {
        throw new Error("Result should have been filtered out");
      }

      return (
        <UploadModalSearchItem
          action
          onClick={onClick}
          image={(mediaData.media_type !== "episode" ? mediaData.poster_path : mediaData.still_path) || undefined}
          title={title}
          description={mediaData.overview || undefined}
          labels={labels}
          style={style}
          type={mediaType}
        />
      );
    }
    , areEqual);

  ResultRow.displayName = "ResultRow";

  const handler = useCallback(debounce((query: string) => fetchData(query).then(data => currentQuery !== "" && setMultiResult(data)), 50), [currentQuery]);

  let inside;


  if (selectedSeason) {
    if (!seasonResult) {
      inside = LoadingList();
    } else {
      inside = (
        <AutoSizer>
          {(size: Size) => (
            <FixedSizeList
              height={size.height}
              width={size.width}
              itemCount={seasonResult.length}
              itemSize={140}
              itemData={seasonResult.map(episode => ({media_type: "episode", ...episode} as SearchRowItemType))}
            >
              {ResultRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      );
    }
  } else if (selectedMedia && selectedMedia.media_type === "tv") {
    if (!showResult) {
      inside = LoadingList();
    } else {
      inside = (
        <AutoSizer>
          {(size: Size) => (
            <FixedSizeList
              height={size.height}
              width={size.width}
              itemCount={showResult.length}
              itemSize={180}
              itemData={showResult.map(season => ({media_type: "season", ...season} as SearchRowItemType))}
            >
              {ResultRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      );
    }
  } else if (multiResult.length > 0) {
    inside = (
      <AutoSizer>
        {(size: Size) => (
          <FixedSizeList
            height={size.height}
            width={size.width}
            itemCount={multiResult.length}
            itemSize={180}
            itemData={multiResult}
          >
            {ResultRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  } else if (currentQuery === "") {
    inside = (
      <Container>
        <Row>
          <Image className="w-50 p-5 mx-auto" src={Search}/>
        </Row>
        <Row>
          <h5 className="text-center my-auto">Search for a movie or show to begin</h5>
        </Row>
      </Container>
    );
  } else {
    inside = (
      <div className="d-flex justify-content-center p-5">
        <h4 className="text-center">No results found</h4>
      </div>
    );
  }

  return (
    <Container className="pt-4 px-lg-5 py-0 ">
      <Row className="position-relative">
        <Col className="d-flex px-lg-5" xs={12} md={8} style={{minHeight: 41}}>
          {
            !selectedMedia ?
              <TextInput
                placeholder="Search..."
                onChange={event => {
                  const query = event.target.value;
                  handler(query);
                  setCurrentQuery(event.target.value);
                }}
                value={currentQuery}
              />
              :
              <Row className="d-flex flex-column">
                <BackButton
                  onClick={backPressed}
                  className="px-0 my-auto"
                  size="sm" style={{width: "2em"}}
                />
                <h5
                  className="my-auto text-truncate">{selectedSeason ? selectedSeason.name : selectedMedia.media_type === "tv" && selectedMedia.name}</h5>
              </Row>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} className="px-lg-5">
          <div className="flex-fill overflow-auto overflow-hidden w-100 px-0" style={{height: 450}}>
            {
              inside
            }
          </div>
        </Col>
        <Col xs={4} className="pe-lg-5 d-none d-md-block position-relative text-white">
          <Container fluid className=" pt-0 bg-black  rounded ratio ratio-16x9">
            {
              props.uploadedFile &&
              <Player
                src={URL.createObjectURL(props.uploadedFile)}
                controls={[PlayerControls.Play, PlayerControls.Track]}
              />
            }
          </Container>
          <div className="position-relative mt-4">
            <small className="text-muted">
              File
            </small>
            <h5 className="text-truncate">
              {props.uploadedFile?.name}
            </h5>
          </div>

          {
            selectedMedia && selectedMedia.media_type === "tv" &&
            <div className="position-relative mt-2">
              <small className="text-muted">
                Show
              </small>
              <h5 className="text-truncate">
                {selectedMedia.name}
              </h5>
            </div>
          }

          {
            selectedSeason &&
            <div className="position-relative mt-2">
              <small className="text-muted">
                Season
              </small>
              <h5 className="text-truncate">
                {selectedSeason.name}
              </h5>
            </div>
          }
        </Col>
      </Row>
    </Container>

  );
}
