import React, {useEffect, useState} from "react";
import {
  Col,
  Container,
  Modal,
  Row
} from "react-bootstrap";
import {
  MovieDBEpisodeItem,
  MovieDBMovieItem,
  MovieDBSearchMultiItem,
  MovieDBSeasonItem,
  MovieDBShowItem
} from "../../models/UploaderTypes";
import FileUploadScreen from "./FileUploadScreen";
import MediaSelectScreen from "./MediaSelectScreen";
import ConfirmScreen from "./ConfirmScreen";
import MovieDBRequest from "../../models/MovieDBRequest";



export type UploadModalOpeningStateType = {
  file?: File;
  selectedMediaType?: "tv"|"movie";
  selectedMediaId?: number;
  selectedSeasonId?: number;
  selectedEpisodeId?: number;
};


function fetchMovieData(id: number): Promise<MovieDBMovieItem> {
  return MovieDBRequest.movie(id).details();
}

function fetchShowData(id: number): Promise<MovieDBShowItem> {
  return MovieDBRequest.tvShow(id).details();
}

function fetchSeasonData(showId: number, seasonNumber: number): Promise<MovieDBSeasonItem> {
  return MovieDBRequest.tvSeason(showId, seasonNumber).details();
}


export type UploadModalProps = {
  file?: File;
  openingState?: UploadModalOpeningStateType;
  hide?: () => void;
};


export default function UploadModal(props: UploadModalProps) {


  const [uploadedFile, setUploadedFile] = useState<File>();


  const close = () => {
    props.hide && props.hide();
  };

  enum Screens {
    File,
    Select,
    Confirm
  }


  // Kinda goes against the whole naming scheme of media being either a movie or episode but eh
  const [selectedMedia, setSelectedMedia] = useState<MovieDBSearchMultiItem>();
  const [selectedSeason, setSelectedSeason] = useState<MovieDBSeasonItem>();
  const [selectedEpisode, setSelectedEpisode] = useState<MovieDBEpisodeItem>();

  useEffect(() => {
    // Modal is opened from the beginning so do nothing
    if (!props.openingState) {
      setUploadedFile(undefined);
      setSelectedMedia(undefined);
      setSelectedSeason(undefined);
      setSelectedEpisode(undefined);
      return;
    }
    const openingState = props.openingState;

    setUploadedFile(openingState.file);


    if (openingState.selectedMediaType === "tv" && openingState.selectedMediaId) {

      fetchShowData(openingState.selectedMediaId)
        .then(result => setSelectedMedia({media_type: "tv", ...result}));

      if (openingState.selectedSeasonId) {
        fetchSeasonData(openingState.selectedMediaId, openingState.selectedSeasonId)
          .then(result => {
            setSelectedSeason(result);
            if (openingState.selectedEpisodeId && result.episodes) {
              const episode = result.episodes.find(e => e.episode_number === openingState.selectedEpisodeId);
              setSelectedEpisode(episode);
            }
            else {
              setSelectedEpisode(undefined);
            }
          });
      }
      else {
        setSelectedSeason(undefined);
        setSelectedEpisode(undefined);
      }
    }
    else if (props.openingState.selectedMediaType === "movie" && openingState.selectedMediaId) {
      setSelectedSeason(undefined);
      setSelectedEpisode(undefined);
      fetchMovieData(openingState.selectedMediaId)
        .then(result =>
          setSelectedMedia({media_type: "movie", ...result}));
    }
    else {
      setSelectedMedia(undefined);
      setSelectedSeason(undefined);
      setSelectedEpisode(undefined);
      return;
    }


  }, [props.openingState]);


  const currentScreen = () => {
    if (!uploadedFile) {
      return Screens.File;
    }
    else if (!selectedMedia || (selectedMedia.media_type === "tv" && (!selectedSeason || !selectedEpisode))) {
      return Screens.Select;
    }
    else {
      return Screens.Confirm;
    }
  }

  const tabHighlighted = (screen: Screens) => {
    if (screen === Screens.Select && (currentScreen() === Screens.Select || currentScreen() === Screens.Confirm)) {
      return true;
    }
    else if (screen === Screens.Confirm && currentScreen() === Screens.Confirm) {
      return true
    }

    return false;
  }

  return (
    <Modal
      size="xl"
      contentClassName="shadow bg-dark border-0"
      centered={true}
      show={props.openingState !== undefined}
      onHide={close}
    >
      <Modal.Header className="border-0 shadow-sm" closeLabel="close" closeButton closeVariant="white">
        <Container className="ps-1">
          <Row className="justify-content-center">
            <Col xs={4} md={3} className="d-flex">
              <h5
                className={`text-primary text-center m-auto`}
                onClick={() => {
                  setUploadedFile(undefined);
                  setSelectedMedia(undefined);
                  setSelectedSeason(undefined);
                  setSelectedEpisode(undefined);
                }}
                role="button"
              >
                File
              </h5>
            </Col>
            <Col xs={4} md={3} className="d-flex">
              <h5
                className={`text-${tabHighlighted(Screens.Select) ? "primary" : "muted"} text-center m-auto`}
                onClick={() => {
                  setSelectedMedia(undefined);
                  setSelectedSeason(undefined);
                  setSelectedEpisode(undefined);
                }}
                role="button"
              >
                Details
              </h5>
            </Col>
            <Col xs={4} md={3} className="d-flex ">
              <h5
                className={`text-${tabHighlighted(Screens.Confirm) ? "primary" : "muted"} text-center m-auto`}
                role="button"
              >
                Confirm
              </h5>
            </Col>

          </Row>
        </Container>

      </Modal.Header>
      <Modal.Body className="d-flex p-0 flex-fill flex-column text-white">
        {
          currentScreen() === Screens.File &&
          <FileUploadScreen
            onFileChanged={file => setUploadedFile(file)}
          />
        }

        {
          currentScreen() === Screens.Select &&
          <MediaSelectScreen
            uploadedFile={uploadedFile}
            selectedMediaState={[selectedMedia, setSelectedMedia]}
            selectedSeasonState={[selectedSeason, setSelectedSeason]}
            selectedEpisodeState={[selectedEpisode, setSelectedEpisode]}
          />
        }

        {
          currentScreen() === Screens.Confirm &&
          <ConfirmScreen
            uploadedFile={uploadedFile}
            selectedMedia={selectedMedia}
            selectedSeason={selectedSeason}
            selectedEpisode={selectedEpisode}
            onConfirmPressed={() => close()}
          />
        }
      </Modal.Body>

    </Modal>

  );
}
