import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import useAuth from "../hooks/useAuth";
import PlansGrid from "../components/PlansGrid";

export default function PlansScreen() {
  const auth = useAuth();

  return (
    <>
      <Container style={{marginTop: 150, marginBottom: 150}}>
        <Row className="d-flex justify-content-center">
          <Col md={12} className="d-flex my-auto">
            <Col lg={12} className="my-auto just w-100 d-flex flex-column">
              <h1 className="text-center text-white my-auto">
                Plans to suit every need.
              </h1>
              <p className="my-3 text-center text-muted">
                From a few movies to a few thousand episodes, there's a plan to suit your needs.
              </p>
            </Col>
          </Col>
        </Row>
      </Container>

      <div className="position-relative vw-100 my-5">
        <Container>
          <Row className=" justify-content-center">
            <Col xs={12}>
              <PlansGrid/>
            </Col>
          </Row>

          <Row className="pt-5 position-relative">
            <Col xs={12} sm={6} className="my-auto">
              <h1 className="text-center">
                Ready to get started?
              </h1>
            </Col>
            <Col xs={12} sm={6} className="px-5">
              <h4 className="my-3 text-center">
                Get Pocketter Today.
              </h4>
              <Button
                variant="primary"
                className="w-100 my-2"
                onClick={auth.redirectSignUp}
                style={{minWidth: 90}}
              >
                Sign Up
              </Button>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
}
