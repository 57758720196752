import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {IoArrowBackOutline} from "react-icons/io5";
import Slider from "../Slider";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import PlayButton from "./PlayButton";
import SkipButton from "./SkipButton";
import VolumeButton from "./VolumeButton";
import FullScreenButton from "./FullScreenButton";
import ControlButton from "./ControlButton";
import Hls, {HlsConfig} from "hls.js";
import Logger from "../../models/Logger";

export enum PlayerControls {
  Back,
  Fullscreen,
  Volume,
  TimeStamp,
  Track,
  Play,
  SkipForward,
  SkipBack,
  Title
}

export type PlayerProps = {
  title?: ReactNode|string;
  controls?: PlayerControls[];
  src: string;
  hls?: boolean;
  hlsConfig?: Partial<HlsConfig>;
  autoPlay?: boolean;
  onProgress?: (current: number, duration: number) => void;
  onEnded?: () => void;
};

export default function Player(props: PlayerProps) {

  // Player and Container
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const hlsRef = useRef<Hls>();

  const [playerLoaded, setPlayerLoaded] = useState<boolean>(false);

  // Fullscreen stuff
  const handle = useFullScreenHandle();

  // Video state
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0.00001);
  const [duration, setDuration] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);



  // Controls
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);
  const [showControls, setShowControls] = useState<boolean>(true);


  // Initialise player
  useEffect(() => {

    if (playerLoaded) {
      return;
    }

    timer.current = setTimeout(() => {
      setShowControls(true)
    }, 500);


    if (!videoRef.current) {
      return;
    }

    if (props.hls && Hls.isSupported()) {

      hlsRef.current = new Hls(props.hlsConfig);



      hlsRef.current.attachMedia(videoRef.current);

      hlsRef.current.loadSource(props.src);

    }
    else {
      videoRef.current.src = props.src;
    }

    videoRef.current.ontimeupdate = () => {
      if (videoRef.current) {
        setPosition(videoRef.current.currentTime);

        props.onProgress && props.onProgress(videoRef.current.currentTime, videoRef.current.duration);
      }
    }

    videoRef.current.autoplay = props.autoPlay || false;

    videoRef.current.onplay = () => {
      setIsPlaying(true);
    }
    videoRef.current.onpause = () => {
      setIsPlaying(false);
    }
    videoRef.current.onloadeddata = () => {
      if (videoRef.current)
        setDuration(videoRef.current.duration);
    }
    videoRef.current.onwaiting = () => {
      setIsLoading(true);
    }
    videoRef.current.oncanplay = () => {
      setIsLoading(false);
    }
    videoRef.current.onended = () => {
      setShowControls(true);
      timer.current && clearTimeout(timer.current);
      props.onEnded && props.onEnded();
    }

    setPlayerLoaded(true);

  }, []);



  const fadeControls = () => {
    setShowControls(true);
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowControls(false)
    }, 3000);
  }


  const togglePlay = () => {
    Logger.log(duration);

    timer.current && clearTimeout(timer.current);

    if (isPlaying) {
      videoRef.current?.pause();
      setShowControls(true);
    } else {
      fadeControls();
      videoRef.current?.play();
    }
  }

  const skip = (amount: number) => {
    if (videoRef.current) {
      const newTime = Math.min(Math.max(videoRef.current.currentTime + amount, 0.0001), duration);
      videoRef.current.currentTime = newTime;
      setPosition(newTime);
    }
    fadeControls();
  };

  return (
    <FullScreen handle={handle} className="d-flex flex-fill">
      <meta name="theme-color" content="#000"/>

      <Container fluid={true} className="position-relative bg-black d-flex flex-fill" ref={containerRef}>
          <video
            ref={videoRef}
            className="mh-100 mw-100 m-auto"
          />


        <div
          className="bg-black bg-opacity-25 position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{
            opacity: showControls ? 1 : 0,
            cursor: showControls ? "default" : "none"
          }}
          onClick={togglePlay}
          onMouseMove={() => {
            isPlaying ? fadeControls() : setShowControls(true);
          }}
        >
          <Container fluid={true}>
            {/* Top Controls */}
            <Row className="py-5 position-absolute top-0 start-0 end-0">
              <Col xs={3} sm={2}>




                {
                  !handle.active && props.controls?.includes(PlayerControls.Back) &&
                  <ControlButton
                    className="row"
                    visible={showControls}
                    onClick={() => {}}
                  >
                    <IoArrowBackOutline size="2em" color="white"/>
                  </ControlButton>

                }
              </Col>
              <Col xs={6} sm={8} className="d-flex justify-content-center">
                {
                  props.controls?.includes(PlayerControls.Title) &&
                  <div>
                    <h4 className="text-center text-white">{props.title}</h4>
                  </div>
                }


              </Col>
              <Col xs={3} sm={2} className="d-flex flex-column" style={{color: "red"}}>

                {
                  props.controls?.includes(PlayerControls.Fullscreen) &&
                  <FullScreenButton
                    active={handle.active}
                    onClick={() => handle.active ? handle.exit() : handle.enter()}
                    className="mx-auto pb-3"
                    visible={showControls}
                  />
                }

                {
                  props.controls?.includes(PlayerControls.Volume) &&
                  <div className="mx-auto position-relative d-none d-md-block">
                    <div className=""
                         style={{width: "120px", top: "8px", left: "-130px", position: "absolute", zIndex: 300}}>
                      <Slider
                        containerStyle={{
                          cursor: showControls ? "pointer" : "pointer"
                        }}
                        position={volume}
                        onChange={value => {
                          if (videoRef.current) {
                            const volume = Math.min(Math.max(value, 0), 1);
                            videoRef.current.volume = volume;
                            setVolume(volume);
                          }
                        }}
                      />


                    </div>
                    <VolumeButton
                      visible={showControls}
                      onClick={event => {
                        if (videoRef.current) {
                          videoRef.current.muted = !muted;
                          setMuted(!muted);
                        }
                      }}
                    />
                  </div>
                }

              </Col>
            </Row>

            {/* Centre Controls */}
            <Row className="my-auto justify-content-center">
              {
                props.controls?.includes(PlayerControls.SkipBack) &&
                <Col xs={3} className="d-flex justify-content-center">
                  <SkipButton
                    visible={showControls}
                    direction="backward"
                    onClick={() => skip(-10)}
                  />
                </Col>
              }

              {
                props.controls?.includes(PlayerControls.Play) &&
                <Col xs={4} className="d-flex align-items-center justify-content-center p-0">
                  <PlayButton
                    visible={showControls}
                    isLoading={isLoading}
                    isPlaying={isPlaying}
                    onClick={togglePlay}
                  />
                </Col>
              }

              {
                props.controls?.includes(PlayerControls.SkipForward) &&
                <Col xs={3} className="d-flex justify-content-center">
                  <SkipButton
                    visible={showControls}
                    direction="forward"
                    onClick={() => skip(10)}
                  />
                </Col>
              }



            </Row>


            {/* Bottom Controls */}
            <Row className=" position-absolute justify-content-center bottom-0 start-0 end-0">
              {
                props.controls?.includes(PlayerControls.TimeStamp) &&
                <Col xs={3} sm={2} className="justify-content-center">
                  <Col xs={11} className="d-flex justify-content-center">
                    <span style={styles.timeLabel}>{secondsToHuman(duration - position)}</span>
                  </Col>
                </Col>
              }

              {
                props.controls?.includes(PlayerControls.Track) &&
                <Col xs={9} sm={10} className="d-flex justify-content-center">
                  <Col xs={11} className="h-100 d-flex justify-content-center align-items-center" >
                    <Slider
                      position={(position + 0.0001) / duration}
                      containerStyle={{cursor: showControls ? "pointer" : "none"}}
                      slidingBegan={() => {
                        videoRef.current && videoRef.current.pause()
                      }}
                      slidingEnded={() => {
                        videoRef.current && videoRef.current.play()
                      }}
                      onChange={value => {
                        if (videoRef.current) {
                          const newPosition = duration * value;
                          videoRef.current.currentTime = newPosition;
                          setPosition(newPosition);
                        }
                      }}
                    />
                  </Col>
                </Col>
              }



            </Row>
          </Container>

        </div>
      </Container>
    </FullScreen>
  );
}

const styles = {
  controlsTop: {},
  title: {
    color: "white"
  },
  timeLabel: {
    color: "white"
  }
};

function secondsToHuman(s: number) {

  if (s < 1) {
    return "0:00";
  }

  const seconds = Math.floor(s % 60);
  const minutes = Math.floor((s / 60) % 60);
  const hours = Math.floor((s / 3600) % 24)

  let result = [];

  let padString = "";

  if (hours > 0) {
    result.push(hours.toString().padStart(2, padString));
    padString = "0";
  }

  result.push(minutes.toString().padStart(2, padString));
  padString = "0";

  result.push(seconds.toString().padStart(2, padString));

  return result.join(':');
}
