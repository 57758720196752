import Axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default class WebSocket extends Echo {

  private static instance?: WebSocket;
  private static pusher?: Pusher;
  private static authToken: () => string = () => "";


  private constructor() {
    super({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_KEY || "",
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      client: WebSocket.getPusherInstance()
    });
  }

  public static setAuthToken(token: () => string) {
    WebSocket.authToken = token;
  };

  public tearDown() {
    this.disconnect();
    WebSocket.authToken = () => "";
  }

  public static getInstance() {
    return WebSocket.instance ? WebSocket.instance : new WebSocket();
  }

  private static getPusherInstance() {

    if (!WebSocket.pusher) {
      WebSocket.pusher = new Pusher(
        process.env.REACT_APP_PUSHER_KEY || "",
        {
          cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || "",
          authorizer: ({ name }) => ({
            authorize: async (socketId, callback) => {
              const service = name.startsWith("private-encode-progress") ? "encode" : "video";

              Axios.post(
                `${process.env.REACT_APP_API_URL}/v1/${service}/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: name
                },
                {
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${WebSocket.authToken()}`,
                  },
                }
              )
                .then(response => {
                  callback(null, response.data);
                })
                .catch(error => {
                  callback(error, null);
                });
            },
          }),

        },
      );
    }
    return WebSocket.pusher;
  }
}
