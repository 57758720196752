import API from "./API";

export default class SessionToken {
  public static getToken(): string|null {
    return window.localStorage.getItem("vst");
  }

  public static setToken(token: string) {
    window.localStorage.setItem("vst", token);
  }

  // Expiry as epoch time
  public static getExpiry(): number|null {
    const expiry = window.localStorage.getItem("vst_exp");
    return expiry ? Number(expiry) : null;
  }

  // Set a new expiry by specifying the token lifetime in seconds
  public static setExpiresIn(expiresIn: number) {
    window.localStorage.setItem("vst_exp", (Date.now() + (expiresIn * 1000)).toString());
  }

  public static clearExpiresIn() {
    window.localStorage.removeItem("vst_exp");
  }

  public static clearToken() {
    window.localStorage.removeItem("vst");
  }

  public static requestSessionToken(authToken: string, videoId: number) {
    const sessionToken = this.getToken();

    let data = sessionToken ?
      {
        video_id: videoId,
        session_token: sessionToken
      }
      :
      {
        video_id: videoId
      };

    const api = new API(authToken);
    return api.videoSession().store(data)
      .then(result => {
        this.setToken(result.data.session_token);
        this.setExpiresIn(result.data.expires_in);
        return result.data;
      });
  }
}
