import React, {useEffect, useState} from "react";
import API from "../models/API";
import useAuth from "../hooks/useAuth";
import {CardUpdateSetup} from "../models/APITypes";
import {Container, Row, Spinner} from "react-bootstrap";
import {loadStripe, Stripe, StripeElementsOptions} from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import Logger from "../models/Logger";

export default function PaymentUpdateCardScreen() {

  const auth = useAuth();
  const [paymentData, setPaymentData] = useState<CardUpdateSetup>();
  const [, setStripeOptions] = useState<StripeElementsOptions>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    const api = new API(auth.getAuthToken() || "");

    api.paymentUpdateCard().index()
      .then(result => {
        setPaymentData(result.data);
        setStripeOptions({clientSecret: result.data.stripe_client_secret});
        setStripePromise(loadStripe(result.data.stripe_key));

      })
      .catch(Logger.log);

  }, []);

  return (
    <Container className="p-5" style={{maxWidth: "500px"}}>
      <Row>
        <h1 className="text-center">Enter Card details</h1>
      </Row>
      <Row>
        {
          stripePromise ?
            <Elements stripe={stripePromise} options={{
              appearance: {
                theme: "flat",
                variables: {
                  colorPrimary: "#7354c2",
                  colorBackground: "#1b1d1d",
                  colorText: "#fff"
                }
              }
            }}>
              <CheckoutForm paymentSetup={paymentData}/>
            </Elements>
            :
            <Spinner className="mx-auto" animation="border" variant="primary"/>
        }
      </Row>
    </Container>
  );
}


