import React, {HTMLProps} from "react";
import {Button, Card, CardProps, Col, Container, Row} from "react-bootstrap";
import {PlanResult} from "../models/APITypes";
import useAuth from "../hooks/useAuth";
import {BsCloudArrowUp, BsDisplay} from "react-icons/bs";

type PlanPanelProps = {
  plan: PlanResult;
  trialButton?: boolean
};

export default function PlanPanel({plan, trialButton, ...props}: PlanPanelProps & HTMLProps<"div"> & CardProps) {
  const auth = useAuth();
  return (
    <Card className=" h-100 shadow-sm" {...props}>

      <Card.Body>
        <div className="text-center p-3">
          <h5 className="card-title">{plan.name}</h5>
          <br/>
          {
            Number(plan.price) === 0 ?
              <div><span className="h5">Free</span></div>
              :
              (<div><span className="h5">${plan.price}</span><span>/month</span></div>)
          }

          {
            trialButton &&
            <>
              <br/>
              <Button size="sm" onClick={auth.redirectSignUp}>
                Free trial
              </Button>
            </>


          }


        </div>
      </Card.Body>

      <Container>
        <Row className="justify-content-center text-muted">
          <Col xs={6} className="flex-row d-flex mb-3 justify-content-center px-0">
            <BsCloudArrowUp size={25} className="mx-2 flex-shrink-0"/>{plan.name}
          </Col>
          <Col xs={6} className="list-group flex-row d-flex justify-content-center mb-3 px-0">
            <BsDisplay size={25} className="mx-2 flex-shrink-0"/>{plan.screen_count}
          </Col>
          <Col xs={6} className="flex-row d-flex justify-content-center px-0">
            <strong>SD</strong>
          </Col>
          {
            plan.hd_allowed ?
              <Col xs={6} className="flex-row d-flex justify-content-center px-0">
                <strong>HD</strong>
              </Col>
              :
              null
          }


        </Row>
      </Container>
      {/*<ListGroup variant="flush" className="border-0">*/}
      {/*  {*/}
      {/*    plan.features.map(feature => {*/}
      {/*      return (*/}
      {/*        <ListGroup.Item className="border-0">*/}
      {/*          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check"*/}
      {/*               viewBox="0 0 16 16">*/}
      {/*            <path*/}
      {/*              d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>*/}
      {/*          </svg>*/}
      {/*          {feature}*/}
      {/*        </ListGroup.Item>*/}
      {/*      );*/}
      {/*    })*/}
      {/*  }*/}
      {/*</ListGroup>*/}
      <div className="card-body text-center"/>
    </Card>
  );
}
