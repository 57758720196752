import React, {useEffect, useState} from "react";
import {Button, Col, Container, Dropdown, Row, Spinner} from "react-bootstrap";
import MediaIcon from "../components/MediaIcon";
import {useNavigate, useParams} from "react-router-dom";
import {IoEllipsisVertical} from "react-icons/io5";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import {useProfile} from "../hooks/useProfile";

import {useUploader} from "../hooks/useUploader";
import {useUserContent} from "../hooks/useUserContent";
import {BsPlayFill} from "react-icons/bs";
import Logger from "../models/Logger";

export default function MovieScreen() {
  const navigate = useNavigate();
  const profile = useProfile();
  const {movieId} = useParams();
  const mediaId = (movieId || 0) as number;
  const [progress, setProgress] = useState<number>();
  const auth = useAuth();
  const {showUploadModal} = useUploader();
  const {getMovie, deleteMovie} = useUserContent();
  const movieData = getMovie(mediaId);

  useEffect(() => {
    const currentProfile = profile.getCurrentProfile();

    if (!currentProfile) {
      return;
    }

    const api = new API(auth.getAuthToken() || "");

    api.movieProgress(currentProfile.id, Number(movieId)).view()
      .then(result => setProgress(result.data.progress))
      .catch(() => setProgress(0));
  }, [profile]);

  const uploadMovie = () => {
    showUploadModal({
      selectedMediaType: "movie",
      selectedMediaId: Number(movieId)
    });
  };

  const deleteMovieClicked = () => {
    deleteMovie(mediaId, () => navigate(-1))
      .catch(Logger.log);
  };

  if (!movieData) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Spinner variant="primary"/>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6}>
          <Button variant="link" onClick={() => navigate(`/watch/movie/${movieData?.media_id}`)}
                  className="w-100 h-100 p-0">
            <MediaIcon iconPath={(movieData.backdrop_path) || ""} progress={progress} duration={movieData.duration}/>
          </Button>
        </Col>
        <Col xs={12} lg={6} className="text-start mt-3 mt-sm-0">
          <div className="d-flex flex-row">
            <div className="flex-fill">
              <h2 className="text-start">{movieData.title}</h2>
            </div>
            <div>
              <Dropdown className="ms-auto my-auto">
                <Dropdown.Toggle bsPrefix="p-0" variant="link"
                                 className={"text-white text-decoration-none p-0"}>
                  <IoEllipsisVertical role="button" className="flex-shrink-0 ms-auto my-auto"/>
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                  <Dropdown.Item onClick={uploadMovie}>Upload</Dropdown.Item>
                  <Dropdown.Item onClick={deleteMovieClicked}><span className="text-danger">Delete</span></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Button className="w-auto d-flex mb-2" variant="light" onClick={() => navigate(`/watch/movie/${movieData?.media_id}`)}>
            <BsPlayFill className="my-auto" size="1.25em"/> <small className="my-auto ms-1">{progress ? "Resume" : "Play"}</small>
          </Button>
          <Row>
            <Col>
              <p>
                {movieData.overview}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

