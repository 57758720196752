import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar, Offcanvas, Toast, ToastContainer} from "react-bootstrap";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import SideBar from "../components/SideBar";
import Logger from "../models/Logger";

import {
  BsCloudArrowUp
} from "react-icons/bs";

import {
  IoArrowBackOutline, IoMenuSharp,
  IoSettingsOutline,
} from "react-icons/io5";
import {useUploader} from "../hooks/useUploader";
import SettingsScreen from "./SettingsScreen";


function backButtonCheck(location: string) {
  return !["/search", "/", "/uploads"].includes(location);
}

export default function PrivateScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const {showUploadModal, alerts, removeAlert} = useUploader();
  const {showId, movieId} = useParams();

  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);

  const [showSideBar, setSideBar] = useState(false);
  const handleCloseSideBar = () => setSideBar(false);
  const handleShowSideBar = () => setSideBar(true);


  const [backButtonEnabled, setBackButtonEnabled] = useState(backButtonCheck(location.pathname));
  useEffect(() => {
    Logger.log(location.pathname);
    setBackButtonEnabled(backButtonCheck(location.pathname));
  }, [location.pathname]);

  const [dragState, setDragState] = useState<"none"|"enter"|"over"|"leave">("none");

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragState("none");
    const file = event.dataTransfer.files[0];

    let type: "tv"|"movie"|undefined = undefined;
    let selectedMediaId = undefined;

    Logger.log(location.key)
    if (showId !== undefined) {
      type = "tv";
      selectedMediaId = Number(showId);
    }
    else if (movieId !== undefined) {
      type = "movie";
      selectedMediaId = Number(movieId);
    }

    showUploadModal({
      selectedMediaType: type,
      selectedMediaId: selectedMediaId,
      file: file
    });
  };


  return (

      <div className="d-flex position-relative flex-column text-white bg-dark vh-100" style={{maxHeight: "100%"}}>
        <ToastContainer className="position-absolute top-0 end-0 float-right" position="top-end" style={{zIndex: 9999, marginTop: 65, paddingRight: 15}}>
          {alerts.map(alert => {
            return (
              <Toast key={alert.title} className="overflow-hidden bg-transparent" style={{width: 300}} autohide={true} delay={3000}
                     onClose={() => removeAlert(alert)}>
                <Toast.Header closeVariant="white" className="ps-0 pe-3 py-0 bg-menu justify-content-end">
                  <div className="position-relative d-flex flex-fill" style={{minHeight: 75}}>
                    <div className={"h-100 position-absolute start-0 top-0 bg-" + alert.type}  style={{width: 8}}/>
                    <span className="py-4 ms-4 text-white">{alert.title}</span>
                  </div>
                </Toast.Header>
              </Toast>
            );
          })}
        </ToastContainer>
        <div className="d-flex vh-100"
             onDrop={handleDrop}
             onDragEnter={event => {
               event.preventDefault();
               setDragState("enter");
             }}
             onDragLeave={event => {
               event.preventDefault();
               setDragState("leave");
             }}
             onDragOver={event => {
               event.preventDefault();
               setDragState("over");
             }}
        >
          <div className="overflow-scroll d-none d-md-flex flex-shrink-0 border-end" style={{zIndex: 9, width: 318}} >
            <SideBar/>
          </div>

          <div className="flex-fill overflow-scroll position-relative">
            <Navbar sticky="top" className="px-3" style={{zIndex: 9}}>
              <Nav className="flex-fill">
                <Container fluid className="d-flex flex-row">
                  <Nav.Item className=" flex-row d-flex">
                    <div role="button" onClick={() => navigate(-1)} className={ "bg-black bg-opacity-75 ratio ratio-1x1 p-4 rounded-circle " + (backButtonEnabled ? "d-flex" : "d-none" ) } >
                      <div className="d-flex">
                        <IoArrowBackOutline className="m-auto" size="1.75em"/>
                      </div>
                    </div>
                  </Nav.Item>

                  <Nav.Item className="ms-auto flex-row d-flex d-md-none ms-2">
                    <div role="button" onClick={handleShowSideBar} className="d-flex bg-black bg-opacity-75 ratio ratio-1x1 p-4 rounded-circle">
                      <div className="d-flex">
                        <IoMenuSharp className="m-auto" size="1.5em"/>
                      </div>
                    </div>
                  </Nav.Item>

                  <Nav.Item className="ms-md-auto flex-row d-flex ms-2">
                    <div role="button" onClick={() => showUploadModal()} className="d-flex bg-black bg-opacity-75 ratio ratio-1x1 p-4 rounded-circle">
                      <div className="d-flex">
                        <BsCloudArrowUp className="m-auto" size="1.5em"/>
                      </div>
                    </div>
                  </Nav.Item>



                  <Nav.Item className=" flex-row d-flex ms-2">
                    <div role="button" onClick={handleShowSettings} className="d-flex bg-black bg-opacity-75 ratio ratio-1x1 p-4 rounded-circle">
                      <div className="d-flex">
                        <IoSettingsOutline className="m-auto" size="1.5em"/>
                      </div>
                    </div>
                  </Nav.Item>




                </Container>

              </Nav>
            </Navbar>

            <Outlet/>


          </div>
          <div className={"bg-primary position-absolute top-0 start-0 bottom-0 " + (dragState === "over" || dragState === "enter" ? "" : "d-none" )}  style={{width: 3, zIndex: 10}}/>
          <div className={"bg-primary position-absolute top-0 start-0 end-0 "  + (dragState === "over" || dragState === "enter" ? "" : "d-none" )}  style={{height: 3, zIndex: 10}}/>
          <div className={"bg-primary position-absolute top-0 end-0 bottom-0 " + (dragState === "over" || dragState === "enter" ? "" : "d-none" )}  style={{width: 3, zIndex: 10}}/>
          <div className={"bg-primary position-absolute bottom-0 start-0 end-0 " + (dragState === "over" || dragState === "enter" ? "" : "d-none" )}  style={{height: 3, zIndex: 10}}/>

        </div>


        <Offcanvas placement="start" show={showSideBar} onHide={handleCloseSideBar} >
          <Offcanvas.Header closeButton closeVariant="white"/>
          <Offcanvas.Body className="pt-0 text-white">
            <SideBar/>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas placement="end" show={showSettings} onHide={handleCloseSettings} >
          <Offcanvas.Header className="bg-menu" closeButton closeVariant="white"/>
          <Offcanvas.Body className="pt-0 text-white bg-menu">
            <SettingsScreen/>
          </Offcanvas.Body>
        </Offcanvas>


      </div>
  );
}
