import React, {useEffect, useState} from "react";
import "./index.css";

export type ToggleSwitchProps = {
  onValueChange?: (isToggled: boolean) => void;
  initialValue?: boolean;
} & React.HTMLAttributes<HTMLLabelElement>;

export default function ToggleSwitch({onValueChange, initialValue, className, ...props}: ToggleSwitchProps) {
  const [isToggled, setIsToggled] = useState<boolean>(initialValue === true);

  useEffect(() => {
    onValueChange && onValueChange(isToggled);
  }, [isToggled]);

  return (
    <label className={"toggle-switch-container " + className} {...props}>
      <input className="toggle-switch-input" type="checkbox" defaultChecked={isToggled} onClick={() => setIsToggled(prev => !prev)} />
      <span className="toggle-switch " />
    </label>
  )
}
