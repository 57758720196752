import React, {useEffect, useState} from "react";
import {Card, Dropdown, Placeholder, ProgressBar, Ratio} from "react-bootstrap";
import {IoEllipsisVertical, IoFilmOutline, IoTvOutline} from "react-icons/io5";
import MovieDBRequest from "../../models/MovieDBRequest";
import {useUploader} from "../../hooks/useUploader";
import {MediaType} from "../../models/UploaderTypes";
import Logger from "../../models/Logger";

export enum UploadProgressType {
  None,
  Compression,
  Upload,
  Encode
}

export type UploadStateType = {
  type: UploadProgressType,
  progress: number
};

type UploadTileProps = {
  mediaId: number;
  type: "movie";
} |
  {
    mediaId: number;
    seasonId: number;
    showId: number;
    type: "episode";
  };


type MediaDataType = {
  title: string;
  year?: string;
  image?: string;
  labels?: string[];
};

const none = {type: UploadProgressType.None, progress: 0};

export default function UploadTile(props: UploadTileProps) {
  const {getEpisodeUploadState, getMovieUploadState, cancel} = useUploader();

  let progressState: UploadStateType = none;


  if (props.type === "episode") {
    progressState = getEpisodeUploadState(props.showId, props.seasonId, props.mediaId) || none;
  }
  else if (props.type === "movie") {
    progressState = getMovieUploadState(props.mediaId) || none;
  }


  const barVariant = () => {
    if (!progressState) {
      return  "";
    }

    switch (progressState.type) {
      case UploadProgressType.Upload:
        return "info";
      case UploadProgressType.Compression:
        return "warning";
      case UploadProgressType.Encode:
        return "success";
    }

    return "";
  };

  const [data, setData] = useState<MediaDataType>();

  const fetchData = async () => {

    if (props.type === "movie") {

      const movieData = await MovieDBRequest.movie(props.mediaId).details();

      setData({
        title: movieData.title || `Movie - ${props.mediaId}`,
        year: movieData.release_date ? movieData.release_date.substring(0, 4) : "",
        image: movieData.backdrop_path || undefined
      });
    }
    else if (props.type === "episode") {

      const showData = await MovieDBRequest.tvShow(props.showId).details();
      const episodeData = await MovieDBRequest.tvEpisode(props.showId, props.seasonId, props.mediaId).details();

      let seasonLabel = `Season ${props.seasonId}`

      for (const season of showData.seasons || []) {
        if (season.season_number === props.seasonId && season.name) {
          seasonLabel = season.name;
          break;
        }
      }

      let labels = [showData.name || `Show ${props.showId}`, seasonLabel];

      setData({
        title: episodeData.name || `Episode - ${props.mediaId}`,
        year: showData.first_air_date ? showData.first_air_date.substring(0, 4) : "",
        image: episodeData.still_path || undefined,
        labels: labels
      });
    }
  };

  const cancelPressed = () => {
    if (props.type === "movie") {
      cancel({
        mediaType: MediaType.Movie,
        mediaId: props.mediaId
      });
    }
    else if (props.type === "episode") {
      cancel({
        mediaType: MediaType.Episode,
        showId: props.showId,
        seasonId: props.seasonId,
        mediaId: props.mediaId
      });
    }
  };

  useEffect(() => {
    fetchData().catch(Logger.log);
  }, []);

  if (!data) {
    return (
      <Card className="bg-transparent border-0 p-3 h-100">
        <Placeholder animation="glow" className="ratio ratio-16x9">
          <Placeholder className="rounded"/>
        </Placeholder>
        <Card.Body className="text-start px-0 d-flex flex-column">
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={10} className="rounded"/>
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} className="rounded"/>
          </Placeholder>
        </Card.Body>
      </Card>

    )
  }

  return (
    <Card className="bg-transparent border-0 p-3 h-100">
      <Ratio aspectRatio="16x9">
        <Card.Img src={"https://image.tmdb.org/t/p/w500" + data.image} style={{objectFit: "cover"}}/>
      </Ratio>
      <Card.Body className="text-start px-0 d-flex flex-column">
        <div className="w-100 d-flex">

          <Card.Title className="text-truncate my-auto">
            {data.title}
          </Card.Title>

          <Dropdown className="ms-auto my-auto">
            <Dropdown.Toggle bsPrefix="p-0" variant="link" className="text-white text-decoration-none p-0">
              <IoEllipsisVertical role="button" className=" flex-shrink-0 ms-auto my-auto"/>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item onClick={cancelPressed}><span className="text-danger">Cancel</span></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="d-flex mt-2">
          {
            props.type === "episode" &&
            <IoTvOutline size="1.25em" className="text-primary me-2 my-auto"/>
          }

          {
            props.type === "movie" &&
            <IoFilmOutline size="1.25em" className="text-primary me-2 my-auto"/>
          }

          <small className="m-0 my-auto text-muted text-middle" style={{lineHeight: "1.75em"}}>
            {data.year}
          </small>
        </div>
        <div className="d-flex mb-3">
          <small className="m-0 my-auto text-muted text-middle" style={{lineHeight: "1.75em"}}>
            {
              data && data.labels && data.labels.join(" • ")
            }
          </small>
        </div>
        <ProgressBar
          className={"mt-auto rounded-0 " + (progressState.type === UploadProgressType.None ? "d-none" : "")}
          style={{height: 2}}
          min={0}
          max={1}
          variant={barVariant()}
          now={Math.max(0.01, progressState.progress/100)}
        />
      </Card.Body>
    </Card>

  )
    ;
}
