import React, {ReactNode, useEffect, useState} from "react";
import {
  Alert,
  Button,
  Card,
  Container, Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import {IoCard, IoLogoPaypal} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {AccountResult} from "../models/APITypes";
import API from "../models/API";
import FieldPlaceholder from "../components/FieldPlaceholder";
import {useProfile} from "../hooks/useProfile";
import Logger from "../models/Logger";

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

function formatPaymentData(accountData: AccountResult) {
  if (accountData.payment_method === "paypal") {
    return (
      <>

        <h2 className="mx-auto w-100 text-primary text-center">
          <IoLogoPaypal/>
        </h2>
        <small className="text-center text-muted">
          {accountData.paypal_email}
        </small>
      </>
    );
  } else if (accountData.payment_method === "card") {
    return (
      <>
        <h2 className="mx-auto w-100 text-primary text-center">
          <IoCard/>
        </h2>
        <small className="text-center text-muted">
          •••• •••• •••• {accountData.last_four}
        </small>
      </>
    );
  }
  return <small className="text-center text-muted">No payment method</small>;
}

type SettingTileProps = {
  title?: string;
  children?: ReactNode | ReactNode[];
  action?: {
    title: string;
    onClick: () => void;
  };
} & React.HTMLAttributes<HTMLDivElement>;

function SettingTile({className, title, children, action, ...props}: SettingTileProps) {
  return (
    <div className={"h-100 p-2 " + className} {...props}>
      <Card className="h-100">
        <Card.Body className=" d-flex flex-column">

          <Card.Title className="mb-auto">
            {title}
          </Card.Title>
          {
            children
          }

          <div className="d-flex mt-auto">
            {
              action &&
              <Button className="mx-auto" onClick={action.onClick}>{action.title}</Button>
            }
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}


export default function SettingsScreen() {


  const auth = useAuth();
  const profile = useProfile();
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState<AccountResult>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const [showEmailSentAlert, setShowEmailSentAlert] = useState<boolean>(false);
  const [showDeleteCancelledAlert, setShowDeleteCancelledAlert] = useState<boolean>(false);

  const fetchAccountData = () => {
    const api = new API(auth.getAuthToken() || "");

    api.account().index()
      .then(result => {
        setAccountData(result.data);
        // @ts-ignore
        Logger.log(Date.parse(result.data.trial_end));

      })
      .catch(Logger.log);
  }

  useEffect(() => {
    fetchAccountData();
  }, []);

  const requestAccountDelete = () => {
    const api = new API(auth.getAuthToken() || "");

    api.requestAccountDeletion()
      .then(() => {
        setShowEmailSentAlert(true);
      })
      .catch(Logger.log)
      .finally(() => setShowDeleteConfirmModal(false));

  }

  const cancelAccountDelete = () => {
    const api = new API(auth.getAuthToken() || "");

    api.cancelAccountDeletion()
      .then(() => {
        setShowDeleteCancelledAlert(true);
        fetchAccountData();
      })
      .catch(Logger.log)
      .finally(() => setShowDeleteConfirmModal(false));
  };

  const signOutClicked = () => {
    profile.logout();
    auth.logout();
  };

  const accountRenewLabel = () => {
    if (!accountData) {
      return;
    }

    const now = new Date();


    const prefix = accountData.current_plan.id === accountData.selected_plan.id ? "Renews on" : `Changes to ${accountData.selected_plan.name} on`;

    if (accountData.trial_end) {
      const trialEnd = new Date(accountData.trial_end);

      if (trialEnd < now) {
        return prefix + " " + trialEnd.toLocaleDateString();
      }
    }

    if (accountData.current_period_end) {
      const periodEnd = new Date(accountData.current_period_end);

      return prefix + " " + periodEnd.toLocaleDateString();
    }


    return;
  };


  return (
    <Container>

      <Row className="mb-5" style={{height: 300}}>

        {
          accountData && accountData.delete_request_date &&
          <SettingTile className="bg-danger rounded" title="Account Deletion" action={{title: "Recover account", onClick: cancelAccountDelete}}>
            <p className="text-center text-danger">Your account will be <strong>deleted</strong> on <strong>{new Date(accountData.delete_request_date).toLocaleDateString()}</strong>. Recover your account to save your data.</p>
          </SettingTile>
        }

        <SettingTile title="Plan" action={{
          title: "Change", onClick: () => {
            navigate("/plan/update")
          }
        }}>
          {
            accountData ?
              <>
                <h4 className="mx-auto w-100 text-center">
                  {accountData.current_plan.name}
                </h4>
                <small className="text-center text-muted">
                  {accountRenewLabel()}
                </small>

              </>
              :
              <>
                <FieldPlaceholder className="w-50 mx-auto"/>
              </>
          }

        </SettingTile>

        <SettingTile title="Storage">
          {
            accountData ?
              <>
                <h4 className="mx-auto w-100 text-center">
                  {formatBytes(accountData.current_plan.storage - accountData.used_storage)} free
                </h4>
                <ProgressBar className="" now={(accountData.used_storage / accountData.current_plan.storage) * 100}
                             max={100}/>

                <small className="text-center text-muted">
                  {formatBytes(accountData.current_plan.storage)} Total
                </small>
              </>
              :
              <>
                <FieldPlaceholder className="w-50 mx-auto"/>
                <FieldPlaceholder/>
                <FieldPlaceholder className="w-50 mx-auto"/>
              </>
          }
        </SettingTile>


        <SettingTile title="Payment Method" action={{
          title: "Change", onClick: () => {
            navigate("/payment/update/card");
          }
        }}>
          {
            accountData ?
              formatPaymentData(accountData)
              :
              <>
                <FieldPlaceholder className="w-25 mx-auto"/>
                <FieldPlaceholder/>
              </>
          }
        </SettingTile>

        <SettingTile title="Billing">
          <Button className="mx-auto" onClick={() => navigate("/payment/history")}>History</Button>
        </SettingTile>



        <SettingTile title="Account Details" action={{
          title: "Update password", onClick: () => {
            window.open(`${process.env.REACT_APP_API_URL}/v1/auth/forgot_password`, "_blank");
          }
        }}>

          <div className="px-2">
            <h5>
              Email
            </h5>
            {
              accountData ?
                <small className="text-muted">
                  {accountData.email}
                </small>
                :
                <FieldPlaceholder/>
            }

            <h5 className="mt-3">
              Password
            </h5>
            {
              accountData ?
                <small className="text-muted">
                  *******
                </small>
                :
                <FieldPlaceholder/>
            }
          </div>

        </SettingTile>

        {
          accountData && !accountData.delete_request_date &&
          <SettingTile title="Manage Account">
            <Button className="mx-auto" variant="outline-danger" onClick={() => setShowDeleteConfirmModal(true)}>Delete account</Button>
          </SettingTile>
        }

        <SettingTile title="Sign Out">

          <Button className="mx-auto" variant="danger" onClick={signOutClicked}>Sign out</Button>

        </SettingTile>

        <Modal className="text-white" show={showDeleteConfirmModal}>
          <Modal.Header closeButton={true} closeVariant="white">
            <h3>Are you sure?</h3>
          </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Deleting</strong> you account will <strong>permanently remove all your content</strong>. If you do want you content <strong>gone forever</strong>,
                click the "send email" button below and we will send you an email with a delete link.
              </p>
              <div className="d-flex justify-content-end">
                <Button variant="danger" onClick={requestAccountDelete}>Send email</Button>
              </div>
            </Modal.Body>
        </Modal>

        <div className="position-fixed d-flex justify-content-center top-0 start-0 end-0">
          <Alert
            onClose={() => setShowEmailSentAlert(false)}
            closeVariant="white"
            variant="dark"
            className="w-100 mt-3 text-white bg-dark border-0"
            show={showEmailSentAlert}
            style={{maxWidth: 600}}
            dismissible={true}
          >
            <Alert.Heading>Email sent</Alert.Heading>
            <p>
              An email has been sent with a delete link. The link will expire in 24 hours.
            </p>
          </Alert>
        </div>

        <div className="position-fixed d-flex justify-content-center top-0 start-0 end-0">
          <Alert
            onClose={() => setShowDeleteCancelledAlert(false)}
            closeVariant="white"
            variant="success"
            className="w-100 mt-3 text-white bg-dark border-0"
            show={showDeleteCancelledAlert}
            style={{maxWidth: 600}}
            dismissible={true}
          >
            <Alert.Heading>Account recovered</Alert.Heading>
            <p>
              Your deletion request has been cancelled. All you data is now safe.
            </p>
          </Alert>
        </div>

      </Row>
    </Container>

  );
}
