import React, {useEffect} from "react";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";

export default function LoginRedirectScreen() {

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      auth.isAuthenticated ? navigate("/") : auth.redirectLogin();
    }
  }, [auth]);
  return (
    <></>
  );
}
