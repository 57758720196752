import React from "react";
import {Button, ButtonProps} from "react-bootstrap";

export type ControlButtonProps = {
  visible?: boolean;
} & React.AllHTMLAttributes<"button"> & ButtonProps;

export default function ControlButton({visible, onClick, ...props}: ControlButtonProps) {
  return (
    <Button
      variant="link"
      onClick={event => {
        event.stopPropagation();
        onClick && onClick(event);
      }}
      style={{cursor: visible ? "pointer" : "none"}}
      {...props}
    />
  );
}
