import React from "react";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import useAuth from "../hooks/useAuth";
import PlansList from "../components/PlansList";
import {BsCloudArrowUp, BsCpu, BsInfoCircle} from "react-icons/bs";
import AnimatedEnter from "../components/AnimatedEnter";

export default function LandingScreen() {
  const auth = useAuth();
  const sectionMargin = 400;


  return (
    <Container>
      <Row className="flex-column-reverse flex-md-row landing-jumbo-margin">
        <Col xs={12} md={6} className="h-100 d-flex flex-column px-lg-5">
          <h1>Your movies. Your shows. <span
            className="text-primary text-gradient-primary text-nowrap">Your way.</span>
          </h1>
          <p className="text-muted">
            Whether on the morning commute or in bed before some snooze, Pocketter is wherever you need it, whenever you
            need it.
          </p>
          <Button className="w-100 mt-lg-4 mt-md-3">Sign Up</Button>
        </Col>
        <Col xs={12} md={6} className="mb-5 my-md-auto">
          <div>
            <Image className="w-100 h-100" style={{objectFit: "contain"}} src="../landing_multi_screen.png"/>
          </div>
        </Col>
      </Row>

      <Row className="flex-column-reverse flex-md-row">
        <AnimatedEnter
          className="col-12 col-md-6"
          animationName="enter-up"
        >
          <div className="bg-menu ratio ratio-16x9">
            <video src="/drag_and_drop.mp4" muted={true} autoPlay={true} controls={false} loop={true}/>
          </div>
        </AnimatedEnter>
        <AnimatedEnter
          className="col-12 col-md-6 d-flex mb-3 mb-md-0"
          animationName="enter-up"
        >
          <h1 className="text-center m-auto">
            Drag and drop. <span className="text-primary text-gradient-primary text-nowrap">Simple stuff.</span>
          </h1>
        </AnimatedEnter>
      </Row>


      <Row style={{marginTop: 150, marginBottom: 100}}>
        <h3 className="text-center mb-0">We'll take care of</h3>
      </Row>

      <Row className="d-flex justify-content-evenly">
        <AnimatedEnter
          className={`col-12 col-sm-4 col-md-3 d-flex align-items-stretch justify-content-center mb-5 md-sm-0`}
          style={{"--animation-duration": "0.5s"} as React.CSSProperties}
          animationName="enter-left"
        >
          <div className="h-100" style={{maxWidth: 250}}>
            <Row className="d-flex flex-column">
              <span className="col">
                <BsInfoCircle size="1.5em" className="text-secondary mb-3"/>
              </span>
            </Row>
            <Row>
              <h3>Info</h3>
            </Row>
            <Row>
              <p className="text-muted my-auto">
                Titles, overviews, and images are all sorted for you.
              </p>
            </Row>

          </div>

        </AnimatedEnter>

        <AnimatedEnter
          className={`col-12 col-sm-4 col-md-3 d-flex align-items-stretch justify-content-center mb-5 mb-sm-0`}
          style={{"--animation-duration": "0.6s"} as React.CSSProperties}
          animationName="enter-left"
        >

          <div className="h-100" style={{maxWidth: 250}}>
            <Row className="d-flex flex-column">
              <span className="col">
                <BsCpu size="1.5em" className="text-secondary mb-3"/>
              </span>
            </Row>
            <Row>
              <h3>Encoding</h3>
            </Row>
            <Row>
              <p className="text-muted my-auto">
                Optimized for the best performance, without the need for an expensive computer.
              </p>
            </Row>

          </div>

        </AnimatedEnter>

        <AnimatedEnter
          className={`col-12 col-sm-4 col-md-3 d-flex align-items-stretch justify-content-center mb-5 mb-sm-0`}
          style={{"--animation-duration": "0.7s"} as React.CSSProperties}
          animationName="enter-left"
        >
          <div className="h-100" style={{maxWidth: 250}}>
            <Row className="d-flex flex-column">
              <span className="col">
                <BsCloudArrowUp size="1.5em" className="text-secondary mb-3"/>
              </span>
            </Row>
            <Row>
              <h3>Storage</h3>
            </Row>
            <Row>
              <p className="text-muted my-auto">
                No need to buy or replace hard drives - access from anywhere, anytime.
              </p>
            </Row>

          </div>

        </AnimatedEnter>
      </Row>


      <Row className="flex-column-reverse flex-md-row" style={{marginTop: sectionMargin}}>
        <Col xs={12} md={6} className="">
          <div className="bg-menu ratio ratio-16x9">
            <video src="/progress_resume.mp4" muted={true} autoPlay={true} controls={false} loop={true}/>
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex flex-column mb-3 mb-md-0 ">
          <h1 className="text-center mt-auto mx-auto">
            Pick up where you left off.
          </h1>
          <p className="text-muted text-center mb-auto mx-auto">
            Don't have time to finish your current episode? We'll keep track of where you're up to so you can finish it any time.
          </p>
        </Col>
      </Row>


      <Row style={{marginTop: 100, marginBottom: 100}}>
        <Col className="rounded-3" style={{height: 4}}>
          <AnimatedEnter animationName="enter-scale" style={{"--animation-duration": "1s"} as React.CSSProperties}>
            <div className="rounded bg-primary enter-scale, glow-primary-1" style={{height: 4}}/>
          </AnimatedEnter>

        </Col>
      </Row>


      <Row style={{marginTop: sectionMargin}}>
        <AnimatedEnter className="d-flex justify-content-center enter-up-start" animationName="enter-up">
          <Col>
            <h1 className="text-center">
              A plan to suit you. <span
              className="text-primary text-gradient-primary text-nowrap">No matter your needs.</span>
            </h1>
          </Col>
        </AnimatedEnter>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <AnimatedEnter className="d-flex justify-content-center enter-up-start" animationName="enter-up">
          <Col xs={12} sm={8} lg={5} xl={4}>
            <p className="text-muted text-center">
              Whether it's a few movies or a few thousand episodes, we've got a plan to work for you.
            </p>
          </Col>
        </AnimatedEnter>
      </Row>
      <Row>
        <AnimatedEnter className="overflow-hidden d-flex justify-content-center enter-up-start"
                       animationName="enter-up">
          <Row>
            <PlansList/>
          </Row>
        </AnimatedEnter>
      </Row>


      <Row className="justify-content-center" style={{marginTop: 150, marginBottom: 150}}>
        <AnimatedEnter className="d-flex justify-content-center enter-up-start" animationName="enter-up">
          <Col md={12} lg={6} className="d-flex my-auto py-5">
            <Col lg={12} className="my-auto just w-100 d-flex flex-column">
              <h1 className="my-3 text-center text-white">
                Get Pocketter Today.
              </h1>
              <Button
                variant="primary"
                className="w-100 my-2"
                onClick={auth.redirectSignUp}
              >
                Sign Up
              </Button>
            </Col>

          </Col>
        </AnimatedEnter>
      </Row>
    </Container>


  );
}
