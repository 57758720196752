export default class Logger {
  private static isProduction(): boolean {
    return process.env.NODE_ENV === "production";
  }

  public static log(message: any) {
    if (!Logger.isProduction()) {
      console.log(message);
    }
  }

  public static error(message: any) {
    if (!Logger.isProduction()) {
      console.error(message);
    }
  }

  public static debug(message: any) {
    if (!Logger.isProduction()) {
      console.debug(message);
    }
  }
}
