import React, {useEffect, useState} from "react";
import PlanPanel from "../components/PlanPanel";
import {AccountResult, PlanResult} from "../models/APITypes";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import {Button, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {IoChevronBackCircleSharp, IoChevronForwardCircleSharp} from "react-icons/io5";
import Logger from "../models/Logger";

export default function PlanUpdateScreen() {

  const auth = useAuth();
  const navigate = useNavigate();

  const [planList, setPlanList] = useState<PlanResult[]>();
  const [accountData, setAccountData] = useState<AccountResult>();
  const [selectedPlan, setSelectedPlan] = useState<number>(1);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const updatePlan = () => {
    if (!accountData || !planList) {
      return;
    }

    if (accountData.payment_method === "none") {
      setShowPaymentModal(true);
      return;
    }

    const api = new API(auth.getAuthToken() || "");
    api.account().update({plan_id: selectedPlan})
      .then(() => {
        return navigate("/settings");
      })
      .catch(Logger.log);
  };

  const plansScroll = (amount: number) => {
    const planList = document.getElementById("plans");

    if (planList) {
      planList.scrollBy({ left: amount, top: 0, behavior: "smooth"});
    }
  };

  const plansScrollLeft = () => {
    plansScroll(-150);
  };

  const plansScrollRight = () => {
    plansScroll(150)
  };

  const modalMessage = () => {
    if (accountData) {
      return accountData && !accountData.trial_end ?
        "We're glad you're enjoying Pocketter; we just need some info before we can start your free month trial."
        :
        "We just need some info before we make changes to your plan."
    }
  };

  const trialText = () => {
    if (!accountData?.trial_end) {
      // trial available
      return (
        <Row className="my-2 my-md-5">
          <h5 className="text-center text-white">
            Upgrade to any premium plan and get a free month trial.
          </h5>
        </Row>
      );
    }

    const trialEnds = Date.parse(accountData?.trial_end.replace(/-/g, '/'));

    if (trialEnds > Date.now()) {
      // Trial still running
      return (
        <Row className="my-5">
          <h5 className="text-center text-white">
            You can upgrade for free anytime on your trial.
          </h5>
        </Row>
      );
    }
  }

  useEffect(() => {
    const api = new API(auth.getAuthToken() || "");

    api.plan().index()
      .then(result => {
        setPlanList(result.data);
        return api.account().index();
      })
      .then(result => {
        setAccountData(result.data);
        setSelectedPlan(result.data.selected_plan.id);
      })
      .catch(Logger.log);

  }, []);

  return (
    <Container fluid={true}>
      <Container className="py-5">

        {
          trialText()
        }

        {
          planList && accountData ?
            <>

              <Row className="position-relative">

                  <IoChevronBackCircleSharp size={"4em"} onClick={() => plansScrollLeft()} className="position-absolute top-50 p-0 start-0 btn d-md-none opacity-75 bg-white rounded-circle" style={{ width: 40, height: 40, zIndex: 999}}/>

                  <IoChevronForwardCircleSharp size={"4em"} onClick={() => plansScrollRight()} className="position-absolute top-50 p-0 end-0 btn d-md-none opacity-75 bg-white rounded-circle" style={{ width: 40, height: 40, zIndex: 999}}/>


                  <Container id="plans" fluid={true} className="overflow-scroll py-3">

                  <Row className="flex-nowrap justify-content-md-center">
                    {
                      planList.map(plan =>
                        <Col xs={11} md={4} lg={3} className="border-4 mb-5">
                          <PlanPanel
                            className={"h-100 " + (plan.id === selectedPlan ? "border-primary" : "")}
                            plan={plan}
                            style={{cursor: "pointer"}}
                            onClick={() => setSelectedPlan(plan.id)}
                          />
                          <div className="text-center">
                            {
                              accountData?.current_plan.id === plan.id &&
                              <small className="text-primary">Current</small>
                            }
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                  </Container>
                <small className="text-muted mb-4">* All prices in USD</small>
              </Row>
              <Row>
                <Col className="mb-2 d-flex justify-content-center">
                  <small className="text-muted">
                    By clicking the 'Start trial' button you agree to our <Link to="/legal/privacy">Privacy Policy</Link> and <Link to="/legal/tos">Terms of Service</Link>.
                  </small>
                </Col>
              </Row>

              <Row className="py-5 d-flex justify-content-center">
                <Col sm={12} md={3} className="">
                  <Button className="w-100" onClick={() => updatePlan()}>Save</Button>
                </Col>
              </Row>
            </>
          :
            <div className="d-flex justify-content-center py-5">
              <Spinner animation="border" variant="primary"/>
            </div>
        }

      </Container>
      <Modal className="text-white" style={{zIndex: 9999}} show={showPaymentModal} centered={true} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header className="shadow-sm" closeButton closeVariant="white">
          <Modal.Title>Payment Method Required</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {
              modalMessage()

            }
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Link to="/payment/update/card" className="btn btn-primary ">Add payment</Link>
        </Modal.Footer>
      </Modal>
    </Container>

  );
}
