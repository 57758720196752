import React, {useCallback, useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import Search from "../Search.svg";
import debounce from "lodash/debounce";
import {LibraryResultItem} from "../models/APITypes";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import MediaTile from "../components/MediaTile";
import TextInput from "../components/TextInput";
import ScrollDetector from "../components/ScrollDetector";
import Logger from "../models/Logger";

type FetchingStateType = {
  token: string | null;
  query?: string;
  fetching: boolean;
};

export default function SearchScreen() {

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<LibraryResultItem[]>();

  const bottomHitRef = useRef<{bottomHit: boolean}>({bottomHit: false});

  const auth = useAuth();

  const fetchStateRef = useRef<FetchingStateType>({token: null, fetching: true});

  const fetchData = (query: string, fromInputChange?: boolean) => {

    if (query === "") {
      setSearchResults(undefined);
      return;
    }


    fetchStateRef.current.fetching = true;
    fetchStateRef.current.query = query;

    const api = new API(auth.getAuthToken() || "");


    let token = undefined;

    if (!fromInputChange) {
      token = fetchStateRef.current.token || undefined;
    }


    api.search(query, token)
      .then(result => {

        // There's probably a chance of some weird race condition where a search is changed then changed back
        // but eh, good enough
        if (fetchStateRef.current.query === query) {
          setSearchResults(prev => fromInputChange ? result.data.data : (prev || []).concat(result.data.data));

          // Set fetch state
          fetchStateRef.current.token = result.data.next;
          fetchStateRef.current.fetching = false;

          if (result.data.next && bottomHitRef.current.bottomHit) {
            fetchData(query, false);
          }
        }
      });

  };

  useEffect(() => {
    Logger.log(searchResults);
  }, [searchResults]);

  const onBottomHit = () => {
    bottomHitRef.current.bottomHit = true;
    if (!fetchStateRef.current.fetching && fetchStateRef.current.token) {
      fetchData(fetchStateRef.current.query || "");
    }
  };

  const onLeave = () => {
    bottomHitRef.current.bottomHit = false;
  }


  const handler = useCallback(debounce((query: string) => fetchData(query, true), 50), []);

  let inside = (
    <Container className="col-12 col-lg-6 mx-auto">
      <Row>
        <Col xs={6} className="mx-auto d-flex justify-content-center">
          <Image src={Search} className="my-5 mx-3" style={{maxWidth: 300}}/>
        </Col>
      </Row>
      <Row>
        <h5 className="text-center my-auto">Search for a movie or show</h5>
      </Row>
    </Container>
  );

  if (searchResults && searchResults.length > 0) {
    inside = (
      <Row className="d-grid py-3"
           style={{gap: 15, gridAutoFlow: "dense", gridTemplateColumns: `repeat(auto-fill, minmax(160px, 1fr))`}}
      >
        {
          searchResults.map((result) => (
            <div style={{gridColumnEnd: `span 1`}} key={`${result.name}${result.id}`}>
              <MediaTile info={result}/>
            </div>
          ))
        }


      </Row>
    );
  } else if (searchResults) {
    inside = (
      <Container className="col-12 col-lg-6 mx-auto">
        <Row className="mt-5">
          <h5 className="text-center my-auto">We couldn't find anything in your library</h5>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <TextInput
        className="col-12 col-md-6 mx-auto"
        placeholder="Search..."
        onChange={event => {
          const query = event.target.value;
          handler(query);
          setCurrentQuery(event.target.value);
        }}
        value={currentQuery}
      />

      {inside}
      <ScrollDetector className="mb-5" onBecomeVisible={onBottomHit} onLeave={onLeave}/>

    </Container>
  );
}
