import React from "react";
import PlanPanel from "./PlanPanel";
import AnimatedEnter from "./AnimatedEnter";


export const plans = [
  {
    "id": 1,
    "name": "10GB",
    "price": "0.00",
    "storage": 10,
    "screen_count": 1,
    "hd_allowed": 0,
    "features": [
      "10 GB storage",
      "1 simultaneous screen",
      "SD streaming"
    ]
  },
  {
    "id": 2,
    "name": "200GB",
    "price": "2.99",
    "storage": 200,
    "screen_count": 2,
    "hd_allowed": 1,
    "features": [
      "200 GB storage",
      "2 simultaneous screens",
      "SD streaming",
      "HD streaming"
    ]
  },
  {
    "id": 3,
    "name": "2TB",
    "price": "14.99",
    "storage": 2000,
    "screen_count": 4,
    "hd_allowed": 1,
    "features": [
      "2 TB storage",
      "4 simultaneous screens",
      "SD streaming",
      "HD streaming"
    ]
  },
  {
    "id": 4,
    "name": "10TB",
    "price": "39.99",
    "storage": 10000,
    "screen_count": 6,
    "hd_allowed": 1,
    "features": [
      "10 TB storage",
      "6 simultaneous screens",
      "SD streaming",
      "HD streaming"
    ]
  }
];


export default function PlansList() {

  const padding = (index: number) => {

    if (index === 0) {
      return "ps-lg-0";
    }

    if (index === plans.length - 1) {
      return "pe-lg-0";
    }

    return "";
  };


  return (
    <>
      <>
        {
          plans.map((plan, index) =>
            <AnimatedEnter
              className={`col-md-12 col-lg-3 mb-4 ${padding(index)}`}
              style={{"--animation-duration": `${0.5+(index*0.1)}s`} as React.CSSProperties}
              animationName="enter-left"
            >
              <PlanPanel trialButton={true} className="h-100 rounded border-0" plan={plan}/>
            </AnimatedEnter>

          )
        }
        <small className="text-muted mb-4">* All prices in USD</small>
      </>

      {/*<Col lg={3} md={12}>*/}
      {/*  <PlanPanel trialButton={true} className="mb-4" plan={{name: "200 GB", cost: 2.99, features: ["200 GB storage", "2 simultaneous screens", "SD streaming", "HD streaming"]}}/>*/}
      {/*</Col>*/}
      {/*<Col lg={3} md={12}>*/}
      {/*  <PlanPanel trialButton={true} className="mb-4" plan={{name: "2 TB", cost: 9.99, features: ["2 TB storage", "4 simultaneous screens", "SD streaming", "HD streaming"]}}/>*/}
      {/*</Col>*/}
      {/*<Col lg={3} md={12}>*/}
      {/*  <PlanPanel trialButton={true} className="mb-4" plan={{name: "10 TB", cost: 49.99, features: ["10 TB storage", "6 simultaneous screens", "SD streaming", "HD streaming"]}}/>*/}
      {/*</Col>*/}
    </>
  );
}
