import React, {FormEvent, useState} from "react";
import {Button, Col, Form, FormProps, Row, Spinner} from "react-bootstrap";
import ProfileButton from "../ProfileButton";
import API from "../../models/API";
import {useProfile} from "../../hooks/useProfile";
import useAuth from "../../hooks/useAuth";
import TextInput from "../TextInput";
import Logger from "../../models/Logger";

export type ProfileEditScreenProps = {
  editingProfileId: number;
  onSuccess?: () => void;
} & React.FormHTMLAttributes<HTMLFormElement> & FormProps;

export default function ProfileEditScreen({editingProfileId, ...props}: ProfileEditScreenProps) {

  const profile = useProfile();
  const auth = useAuth();

  const editingProfile = profile.getProfile(editingProfileId);
  const [name, setName] = useState<string>(editingProfile?.name || "");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);


  const update = (event: FormEvent) => {
    event.preventDefault();
    setIsUpdating(true);

    const api = new API(auth.getAuthToken() || "");

    api.profile(editingProfileId).update({name: name})
      .catch(Logger.log)
      .finally(() => {
        profile.refresh();
        setIsUpdating(false);
        props.onSuccess && props.onSuccess();
      });
  };


  if (!editingProfile) {
    return <h3>Something went wrong</h3>;
  }

  return (
    <Form onSubmit={update}>
      <Row className="justify-content-center">
        <Col xs={6}>
          <ProfileButton profileColour={editingProfile.colour} initial={editingProfile.name[0].toUpperCase()}/>
        </Col>
        <Col xs={10} className="mt-4">
          {/*<Form.Control type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>*/}
          <TextInput placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4 mb-3">
        <Col xs={4}>
          <Button className="w-100" type="submit" disabled={isUpdating}>
            {isUpdating ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              :
              "Save"
            }
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
