import React from "react";
import {Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./index.css";

export default function TermsScreen() {
  return (
    <Container>
      <Row>
        <h1 className="my-4">Terms of Service</h1>
        <p>
          By using or otherwise accessing the Pocketter website, any Pocketter software, mobile, or web application(s),
          or any related service provided by Pocketter, you accept and agree to the following Terms of Service (this
          “TOS”). Pocketter Pty Ltd (“Pocketter”) provides access to its software and services under condition of
          on-going compliance with this Terms of Service.
        </p>

        <ol>
          <li>
            <strong>Billing</strong>
            <ol>
              <li>
                <strong>Billing Cycle</strong>
                <p>
                  Our billing cycle is based on a monthly schedule that commences on the day of the month corresponding
                  to your initial subscription or purchase of a paid plan. Subsequent billing periods will align with
                  this day each month. If the billing date falls on a day that does not exist in a particular month,
                  such as February 30th, your billing will occur on the last day of that month. Billing continues until
                  termination.
                </p>
              </li>
              <li>
                <strong>Payment Methods</strong>
                <p>
                  Our billing cycle is based on a monthly schedule that commences on the day of the month corresponding
                  to your initial subscription or purchase of a paid plan. Subsequent billing periods will align with
                  this day each month. If the billing date falls on a day that does not exist in a particular month,
                  such as February 30th, your billing will occur on the last day of that month. Billing continues until
                  termination.
                </p>
              </li>

              <li>
                <strong>Changes to plans</strong>
                <p>
                  We may modify our subscription plans, features, or pricing at our discretion. Any changes to your plan
                  will be communicated to you at least 30 days in advance to allow you to make informed decisions
                  regarding your subscription. If you do not wish to accept the changes, you may opt to cancel your
                  subscription. Your continued use of our services after the notification period signifies your
                  acceptance of the modified terms and plans.
                </p>
              </li>

              <li>
                <strong>Upgrades, Downgrades, and Cancellation</strong>
                <p>
                  If a plan is upgraded, it initiates a new billing cycle immediately, and any charges for the remainder
                  of the existing plan will be applied toward the new plan.

                  For downgrades, changes will take effect at the end of the current billing cycle. Users will maintain
                  access to their current plan until that time. Cancellation can be initiated by downgrading to the free
                  tier.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Promotions</strong>
            <p>
              Pocketter may, at its discretion, offer promotional offers or discounts to eligible users. Eligibility for
              such promotions may be determined based on various factors, including but not limited to email addresses,
              account IDs, device IDs, location information, and a user's current or recent membership history.
              Eligibility criteria for these offers may vary and are subject to change. You should refer to specific
              offer details and terms to understand the eligibility requirements and conditions associated with each
              promotion.
            </p>
          </li>
          <li>
            <strong>Privacy Policy</strong>
            <p>See Pocketter's <Link to="/legal/privacy">privacy policy</Link>.</p>
          </li>
          <li>
            <strong>Pocketter Service</strong>
            <p>
              By using the Pocketter service you agree that:
            </p>
            <ol>
              <li>
                You are at least 18 years old or have reached the age of majority in your country, state, or territory.
              </li>
              <li>
                By granting someone access to your account, you agree that they are acting on your behalf and that you
                remain liable for any actions they take within your account.
              </li>
              <li>
                You are responsible for taking reasonable steps to safeguard your account and login information in order
                to maintain the security and integrity of your account.
              </li>
              <li>
                You acknowledge that Pocketter may not have the ability to restrict maturity level of content available
                through its software and services.
              </li>
              <li>
                You will not upload, display, transmit, or otherwise cause content to interact with Pocketter’s software
                and services that:
                <ul>
                  <li>
                    May cause damages or otherwise harm the Pocketter brand or the reputation of its software and
                    services;
                  </li>
                  <li>
                    Threatens, offends, harasses, or in any way violates or infringes on the rights of another person,
                    company, or other legal entity;
                  </li>
                  <li>
                    Any file, programming code, software, or other digital content that is harmful to Pocketter or other
                    users of Pocketter;
                  </li>
                  <li>
                    Anything which is unlawful or that you otherwise do not have legal right to upload, store, or watch;
                  </li>
                </ul>
              </li>
              <li>
                You give Pocketter a limited licence to access, read, duplicate, delete, and modify your content for the
                purposes of delivering the Pocketter service. Pocketter does not claim ownership of content you upload.
              </li>
              <li>
                Pocketter is for personal use only, and you may not sell or use your account for commercial purposes or
                gain.
              </li>
              <li>
                You will not attempt to circumvent any restriction measures unless expressly granted permission to do
                so. This includes, but is not limited to, the following:
                <ol>
                  <li>
                    <strong>Reverse Engineering:</strong> You will not reverse engineer, decompile, or disassemble the
                    Pocketter Service or any software provided by Pocketter.
                  </li>
                  <li>
                    <strong>Use of Scrapers, Spiders, or Robots:</strong> You will not employ scrapers, spiders, robots,
                    or any automated means to access Pocketter without explicit authorization.
                  </li>
                  <li>
                    <strong>Archiving or Content Protection:</strong> You will not attempt to archive, remove, or
                    manipulate content protections or any other elements of the Pocketter Service.
                  </li>
                </ol>
              </li>
              <li>
                While Pocketter does its best to provide excellent service, quality of content streamed will vary
                depending on device and network conditions.
              </li>
            </ol>
          </li>
          <li>
            <strong>Third-Party Content, Websites, and Platforms</strong>
            <p>
              Our software and services may include links or incorporate content, websites, or platforms provided by
              third parties. These links and integrations are offered for your convenience and may provide additional
              functionality or information. However, it's important to note that these third-party offerings do not
              grant you permission or rights to the content, services, or data provided by these external sources. Any
              interactions or transactions you engage in with third parties, whether linked to or incorporated into our
              software and services, are subject to the terms and policies of those third parties. We do not control or
              endorse these third-party offerings, and your use of them is at your own risk. We recommend reviewing the
              terms and policies of these third parties to understand your rights and obligations when using their
              content, websites, or platforms.
            </p>
          </li>
          <li>
            <strong>Warranties and Limitation Liability</strong>
            <p>
              Pocketter’s software and services are provided “as is”, without warranty or condition. To the extent
              permitted by applicable law, Pocketter shall not be liable for any direct or indirect damages, including
              but not limited to service interruptions, software issues, or data loss, regardless of the cause of such
              issues. This waiver does not limit or waive any rights or protections granted by applicable laws that
              cannot be waived or limited, including but not limited to non-waivable warranties, consumer protections,
              or other rights mandated by law.
            </p>
          </li>
          <li>
            <strong>Class Action Waiver</strong>
            <p>
              By using our services, you and Pocketter agree to waive your respective rights to participate in or
              initiate class action lawsuits against each other. Any disputes, claims, or controversies must be resolved
              on an individual basis, and not as part of a class action, consolidated action, or representative action.
              This mutual waiver ensures that disputes are addressed through individual arbitration or other applicable
              dispute resolution procedures rather than collective legal actions. If you do not agree with this
              provision, do not use our software services. Your use of our services signifies your understanding and
              acceptance of this mutual class action waiver.
            </p>
          </li>
          <li>
            <strong>Suggestions and feedback</strong>
            <p>
              We welcome and value your suggestions and feedback, as they help us improve our services. By providing us
              with any ideas, suggestions, or feedback, you acknowledge that you have the right to transmit such ideas
              and that they do not violate intellectual property laws or the rights of others. We appreciate your input
              and may use your suggestions to enhance our services, but please be aware that submitting ideas or
              feedback does not entitle you to any ownership or compensation related to the ideas you provide. We may,
              at our discretion, implement or incorporate user suggestions into our products and services without
              further obligation. Your engagement in providing suggestions and feedback signifies your understanding and
              acceptance of these terms.
            </p>
          </li>
          <li>
            <strong>Updates to this agreement</strong>
            <p>
              Updates to these terms may occur periodically to reflect evolving legal requirements, technological
              advancements, or improvements in our services. When such updates are made, we will make every effort to
              notify you promptly. You will be made aware of changes through two primary channels: by posting the
              updated terms to our website and by sending notifications via email to the address associated with your
              account.

            </p>
          </li>
        </ol>
      </Row>

    </Container>
  );
}
