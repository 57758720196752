import Axios from "axios";
import {
  MovieDBEpisodeItem,
  MovieDBMovieItem,
  MovieDBSearchMultiResult,
  MovieDBSeasonItem,
  MovieDBShowItem
} from "./UploaderTypes";


export type RequestParamType = { [param: string]: any };


export default class MovieDBRequest {
  static baseUrl = process.env.REACT_APP_MOVIE_DB_API_URL;
  static apiKey = process.env.REACT_APP_MOVIE_DB_API_KEY;
  static defaultParams: { [param: string]: any } = {
    language: "en-US",
    include_adult: false,
    api_key: MovieDBRequest.apiKey
  };


  static basicGet = <T>(request: string, additionalParams?: RequestParamType) => {
    return Axios.get<T>(MovieDBRequest.baseUrl + request, {params: {...MovieDBRequest.defaultParams, ...(additionalParams || {})}});
  };

  static basicGetData = <T>(request: string, additionalParams?: RequestParamType) => {
    return MovieDBRequest.basicGet<T>(request, additionalParams).then<T>(result => result.data);
  };



  static movie = (movieId: number) => {
    return {
      details: (additionalParams?: { [param: string]: any }) => {
        return MovieDBRequest.basicGetData<MovieDBMovieItem>(`/movie/${movieId}`, additionalParams);
      }
    }
  }

  static tvShow = (showId: number) => {
    return {
      details: (additionalParams?: { [param: string]: any }) => {
        return MovieDBRequest.basicGetData<MovieDBShowItem>(`/tv/${showId}`, additionalParams);
      }
    }
  }

  static tvSeason = (showId: number, seasonId: number) => {
    return {
      details: (additionalParams?: { [param: string]: any }) => {
        return MovieDBRequest.basicGetData<MovieDBSeasonItem>(`/tv/${showId}/season/${seasonId}`, additionalParams);
      }
    }
  }

  static tvEpisode = (showId: number, seasonId: number, episodeId: number) => {
    return {
      details: (additionalParams?: { [param: string]: any }) => {
        return MovieDBRequest.basicGetData<MovieDBEpisodeItem>(`/tv/${showId}/season/${seasonId}/episode/${episodeId}`, additionalParams);
      }
    }
  }

  static search = (query: string) => {
    return {
      multi: (additionalParams?: { [param: string]: any }) => {
        return MovieDBRequest.basicGet<MovieDBSearchMultiResult>(`/search/multi`, {query: query, ...(additionalParams || {})});
      }
    }
  }
}


