import {MediaType, UploadProgressCallbackType, UploadProgressType, UploadQueueType} from "./UploaderTypes";
import UploadSegment from "./UploadSegment";
import API from "./API";
import Logger from "./Logger";


export default class Upload {
  static maxConcurrentSegments = 1;
  private pendingSegments: Array<UploadSegment> = [];
  private uploadSegments: Array<UploadSegment> = [];
  private file: File;
  private id?: number;
  private mediaId: number;
  private seasonId?: number;
  private showId?: number;
  private type: MediaType;
  private fileSize?: number;
  private completedSize = 0;
  private cancelled = false;

  private progressCallback: UploadProgressCallbackType;


  constructor(type: MediaType, file: File, progressCallback: UploadProgressCallbackType, mediaId: number, seasonId?: number, showId?: number) {
    this.type = type;
    this.file = file;
    this.mediaId = mediaId;
    this.seasonId = seasonId;
    this.showId = showId;
    this.progressCallback = progressCallback;
  }

  getFile(): File {
    return this.file;
  }

  setFile(file: File) {
    this.file = file;
  }

  getId(): number | undefined {
    return this.id;
  }

  async ready(token: () => string): Promise<number> {
    this.onUploadProgress(0);

    this.fileSize = this.file.size;
    const segmentCount = Math.ceil(this.fileSize / UploadSegment.maxSegmentSize);

    let data = {
      part_count: segmentCount,
      type: this.type,
      media_id: this.mediaId
    };

    if (this.type === MediaType.Episode) {
      data = {
        ...{
          season_id: this.seasonId,
          show_id: this.showId,
        },
        ...data
      };
    }

    const api = new API(token());

    const response = await api.uploadRequest().store(data);

    this.id = response.data.id;
    for (const part of response.data.parts) {
      const offset = (part.part_number - 1) * UploadSegment.maxSegmentSize;
      const size = Math.min(this.fileSize - offset, UploadSegment.maxSegmentSize);
      const segment = new UploadSegment(
        part.part_number,
        offset,
        size,
        this
      );
      this.pendingSegments.push(segment);
    }


    return this.id!;
  }

  upload(token: () => string) {
    return new Promise<void>((resolve, reject) => {

      if (this.cancelled) {
        reject();
        return;
      }

      if (this.pendingSegments.length === 0 && this.uploadSegments.length === 0) {
        resolve();
        return;
      }

      while (this.pendingSegments.length > 0 && this.uploadSegments.length < Upload.maxConcurrentSegments) {
        const segment = this.pendingSegments.pop()!;
        this.uploadSegments.push(segment);

        if (this.cancelled) {
          reject();
          return;
        }

        segment.upload(token)
          .then(() => {
            this.uploadSegments = this.uploadSegments.filter(item => item !== segment);
            return this.upload(token);
          })
          .then(() => {
            if (this.cancelled) {
              reject();
              return;
            }

            if (this.pendingSegments.length === 0 && this.uploadSegments.length === 0) {
              resolve();
            }
          })
          .catch(error => {
            this.pendingSegments = [];
            this.uploadSegments = [];
            reject(error);
          });
      }
    });
  }

  public addCompletedBytes(completed: number) {
    this.completedSize += completed;
    Logger.log("completed - " + this.completedSize);
    Logger.log("total - " + this.fileSize);
    this.onUploadProgress(this.completedSize / (this.fileSize || 0));
  }

  cancel() {
    this.cancelled = true;
  }

  public onUploadProgress(progress: number) {
    const progressState = {type: UploadProgressType.Upload, progress: progress};
    this.progressCallback(this.asData(), progressState);
  }

  private asData(): UploadQueueType {
    return this.type === MediaType.Episode ? {
        mediaId: this.mediaId,
        mediaType: this.type,
        seasonId: this.seasonId!,
        showId: this.showId!
      }
      : {
        mediaId: this.mediaId,
        mediaType: this.type
      };
  }

}
