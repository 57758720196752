import {Route, Routes} from "react-router-dom";
import PlayerScreen from "./screens/PlayerScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicPrivateRoute from "./components/PublicPrivateRoute";
import PublicScreen from "./screens/PublicScreen";
import LandingScreen from "./screens/LandingScreen";
import PlansScreen from "./screens/PlansScreen";
import HelpScreen from "./screens/HelpScreen";
import PrivateScreen from "./screens/PrivateScreen";
import LibraryScreen from "./screens/LibraryScreen";
import ShowScreen from "./screens/ShowScreen";
import MovieScreen from "./screens/MovieScreen";
import SettingsScreen from "./screens/SettingsScreen";
import PaymentUpdateScreen from "./screens/PaymentUpdateScreen";
import PaymentUpdateCardScreen from "./screens/PaymentUpdateCardScreen";
import PlanUpdateScreen from "./screens/PlanUpdateScreen";
import ProfileRequiredRoute from "./components/ProfileRequiredRoute";
import LoginRedirectScreen from "./screens/LoginRedirectScreen";
import {useEffect, useRef} from "react";
import UploaderInterface from "./models/UploaderInterface";
import Uploader from "./models/Uploader";
import useAuth from "./hooks/useAuth";
import {UploaderProvider} from "./hooks/useUploader";
import {UserContentProvider} from "./hooks/useUserContent";
import WebSocket from "./models/WebSocket";
import UploadsScreen from "./screens/UploadsScreen";
import SearchScreen from "./screens/SearchScreen";
import BillHistoryScreen from "./screens/BillHistoryScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import TermsScreen from "./screens/TermsScreen";

function UploaderWrapped() {

  return (
    <div className="App vw-100">
      <Routes>
        <Route path="/" element={PublicPrivateRoute(<PublicScreen/>, ProfileRequiredRoute(<PrivateScreen/>))}>
          <Route index element={PublicPrivateRoute(<LandingScreen/>, <LibraryScreen/>)}/>

          <Route path="search" element={ProtectedRoute(<SearchScreen/>)}/>
          <Route path="uploads" element={ProtectedRoute(<UploadsScreen/>)}/>


          <Route path="plans" element={<PlansScreen/>}/>
          <Route path="help" element={<HelpScreen/>}/>
          <Route path="login" element={<LoginRedirectScreen/>}/>

          <Route path="legal/tos" element={<TermsScreen/>}/>
          <Route path="legal/privacy" element={<PrivacyScreen/>}/>

          <Route path="payment/history" element={ProtectedRoute(<BillHistoryScreen/>)}/>
          <Route path="payment/update" element={ProtectedRoute(<PaymentUpdateScreen/>)}>
            <Route path="card" element={ProtectedRoute(<PaymentUpdateCardScreen/>)}/>
          </Route>
          <Route path="plan/update" element={ProtectedRoute(<PlanUpdateScreen/>)}/>

          <Route path="show/:showId" element={ProtectedRoute(ProfileRequiredRoute(<ShowScreen/>))}/>
          <Route path="movie/:movieId" element={ProtectedRoute(ProfileRequiredRoute(<MovieScreen/>))}>
          </Route>
          <Route path="settings" element={ProtectedRoute(<SettingsScreen/>)}/>
        </Route>
        <Route path="/watch/movie/:movieId"
               element={ProtectedRoute(ProfileRequiredRoute(<PlayerScreen mediaType="movie"/>))}/>
        <Route path="/watch/show/:showId/:seasonId/:episodeId"
               element={ProtectedRoute(ProfileRequiredRoute(<PlayerScreen mediaType="episode"/>))}/>
      </Routes>
    </div>
  );
}

function UserContentWrapped(props: { uploader: UploaderInterface }) {
  return (
    <UploaderProvider uploader={props.uploader}>
      <UploaderWrapped/>
    </UploaderProvider>
  )
}

function App() {
  const auth = useAuth();

  const uploader = useRef<{ uploader: UploaderInterface }>({uploader: Uploader.getInstance()})

  useEffect(() => {
    const uploaderCopy = uploader.current;
    return () => {
      uploaderCopy.uploader.cleanUp();
    };
  }, [])

  useEffect(() => {
    if (auth.isAuthenticated) {
      WebSocket.setAuthToken(() => auth.getAuthToken() || "");
      uploader.current.uploader.setAuthToken(() => auth.getAuthToken() || "");
      uploader.current.uploader.fetch();
    } else {
      uploader.current.uploader.cleanUp();
      WebSocket.getInstance().tearDown();
    }
  }, [auth]);

  return (
    <UserContentProvider>
      <UserContentWrapped uploader={uploader.current.uploader}/>
    </UserContentProvider>
  );
}

export default App;
