import React from "react";
import {Button, Row} from "react-bootstrap";
import tinycolor from "tinycolor2";
import {BsPencil} from "react-icons/bs";


type ProfileButtonProps = {
  name?: string;
  initial?: string;
  isEditing?: boolean;
  profileColour: string;
  selected?: boolean;
}

export default function ProfileButton({selected, profileColour, name, initial, ...props}: React.HTMLAttributes<HTMLDivElement> & ProfileButtonProps) {



  return (
    <div {...props}>
      <Button
        className={" ratio ratio-1x1 position w-100 d-flex align-items-center justify-content-center text-white border-" + (selected ? "4" : "0")}
        style={{
          aspectRatio: "1/1",
          background: profileColour
        }}
        variant={"outline-primary"}
        onClick={() => props.onClick}
      >


            <div className="position-absolute d-flex">
              {props.isEditing && <BsPencil size="3em" className="m-auto"/>}
            </div>
            <div className={"position-absolute my-auto d-flex " + (props.isEditing ? "opacity-25" : "")}>
              <h3 className="my-auto mx-auto">{initial}</h3>
            </div>

      </Button>
      <Row>
        <strong className="my-auto text-white text-truncate text-center">
          {name}
        </strong>
      </Row>
    </div>

  );
}
