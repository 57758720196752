import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {BsCreditCard} from "react-icons/bs";
import useAuth from "../hooks/useAuth";
import API from "../models/API";
import {BillAttempt} from "../models/APITypes";
import Logger from "../models/Logger";

export default function BillHistoryScreen() {

  const {getAuthToken} = useAuth();
  const [results, setResults] = useState<BillAttempt[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const api = new API(getAuthToken() || "");

    api.billHistory()
      .then(result => {
        setResults(result.data.data);
      })
      .catch(Logger.log)
      .finally(() => setLoading(false));
  }, []);

  const amountColourClass = (attempt: BillAttempt) => {
    if (attempt.status === "failed") {
      return "text-danger"
    }
    else if (attempt.status === "pending") {
      return "text-warning"
    }

    return "text-white"
  }

  let inside = <Row className="justify-content-center px-md-3"><h5 className="text-muted text-center">No transactions found</h5></Row>

  if (loading) {
    inside = <Row className="justify-content-center px-md-3"><Spinner variant="primary"/> </Row>
  } else if (results.length > 0) {
    inside = (
      <>
        {
          results.map(attempt => (
            <Row className="px-md-3 mb-3">
              <Card className="px-0">
                <Card.Header className="text-muted d-flex ">
                  <small>
                    {(new Date(attempt.created_at * 1000)).toDateString()}
                  </small>
                  <small className={"ms-auto text-capitalize "}>
                    {attempt.status}
                  </small>

                </Card.Header>
                <Card.Body className="pt-1">
                  <Row>
                    <Col xs={8} sm={6}>
                      <Col xs={12} className="h4">
                        {attempt.description}
                      </Col>
                      <Col className="text-muted d-flex">
                        <BsCreditCard className="my-auto me-2"/> <small className="my-auto">•••• {attempt.payment_method.last4}</small>
                      </Col>
                    </Col>
                    <Col xs={4} sm={6} className="text-end d-flex">
                      <h5 className={"text-end ms-auto my-auto " + amountColourClass(attempt)}>${attempt.amount/100}</h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
          ))
        }
      </>
    );

  }

  return (
    <Container className="">
      <Row className="px-md-3">
        <Col>
          <h1>Billing History</h1>
        </Col>
      </Row>
      {inside}
    </Container>
  );
}
