import React from "react";
import {Button, Col, Container, Row, Stack} from "react-bootstrap";
import {
  MediaType,
  MovieDBEpisodeItem,
  MovieDBSearchMultiItem,
  MovieDBSeasonItem,
  UploadQueueType
} from "../../models/UploaderTypes";
import UploadModalSearchItem from "./UploadModalSearchItem";
import Player, {PlayerControls} from "../Player/Player";
import {useUploader} from "../../hooks/useUploader";

export type ConfirmScreenProps = {
  uploadedFile?: File;
  selectedMedia?: MovieDBSearchMultiItem;
  selectedSeason?: MovieDBSeasonItem;
  selectedEpisode?: MovieDBEpisodeItem;
  onConfirmPressed?: () => void;
};

export default function ConfirmScreen(props: ConfirmScreenProps) {

  let selectedImage: string | undefined = undefined;
  let selectedTitle: string = "";
  let selectedDescription: string | undefined = undefined;

  if (props.selectedMedia?.media_type === "movie") {
    selectedImage = props.selectedMedia.backdrop_path || undefined;
    selectedTitle = props.selectedMedia.title || "";
    selectedDescription = props.selectedMedia.overview || undefined;
  } else if (props.selectedEpisode) {
    selectedImage = props.selectedEpisode.still_path || undefined;
    selectedTitle = `${props.selectedEpisode.episode_number} - ` + (props.selectedEpisode.name || `Episode ${props.selectedEpisode.episode_number}`);
    selectedDescription = props.selectedEpisode.overview;
  }

  const {enqueue} = useUploader();

  const confirmPressed = () => {

    let data: UploadQueueType|undefined = undefined;

    if (props.selectedMedia?.media_type === "person" || !props.selectedMedia || !props.selectedMedia.id || !props.uploadedFile) {
      return;
    }

    if (props.selectedMedia?.media_type === "movie") {
      data = {
        mediaType: MediaType.Movie,
        mediaId: props.selectedMedia.id
      }
    }
    else if (props.selectedMedia?.media_type === "tv" && props.selectedEpisode && props.selectedEpisode.id && props.selectedSeason && props.selectedSeason.season_number) {
      data = {
        mediaType: MediaType.Episode,
        showId: props.selectedMedia.id,
        seasonId: props.selectedSeason.season_number,
        mediaId: props.selectedEpisode.episode_number || 0
      }
    }
    else {
      throw new Error("Should not reach here");
    }

    enqueue(data, props.uploadedFile);

    props.onConfirmPressed && props.onConfirmPressed();
  };



  return (
    <Container className="py-4 px-4  ">

      <Row>

        <Col xs={12} lg={8} className="d-flex flex-fill position-relative">


          <Container className="d-flex flex-column flex-fill w-100">
            <Row className="">
              <UploadModalSearchItem
                onClick={() => {
                }}
                image={selectedImage}
                title={selectedTitle}
                description={selectedDescription}
                // labels={labels}
                // style={style}
                className="shadow rounded my-auto"
                type={"episode"}
              />
            </Row>
            <Row className="mt-5 mt-lg-auto justify-content-center">

              {/*<Col xs={12} className="my-auto">*/}
              {/*  <h3 className="text-secondary text-center">Ready to upload?</h3>*/}

              {/*</Col>*/}

              <Col xs={12} lg={6} className="d-flex justify-content-center">
                <Button className="w-100" onClick={confirmPressed}>
                  Upload
                </Button>
              </Col>

            </Row>


          </Container>


        </Col>


        <Col xs={4} className=" d-none d-lg-block position-relative">
          <Container fluid className=" pt-0 bg-black  rounded ratio ratio-16x9">
            {/*<video*/}
            {/*  playsInline*/}
            {/*  src={props.uploadedFile ? URL.createObjectURL(props.uploadedFile) : undefined}*/}
            {/*  className="w-100 shadow  my-auto rounded"*/}
            {/*/>*/}
            {
              props.uploadedFile &&
              <Player
                src={URL.createObjectURL(props.uploadedFile)}
                controls={[PlayerControls.Play, PlayerControls.Track]}
              />
            }


          </Container>

          <Stack className="mt-4" gap={1}>
            <div className="position-relative">
              <small className="text-muted">
                File
              </small>
              <h5 className="text-truncate">
                {props.uploadedFile?.name}
              </h5>
            </div>

            {
              props.selectedMedia && props.selectedMedia.media_type === "tv" &&
              <div className="position-relative">
                <small className="text-muted">
                  Show
                </small>
                <h5 className="text-truncate">
                  {props.selectedMedia.name}
                </h5>
              </div>
            }

            {
              props.selectedSeason &&
              <div className="position-relative">
                <small className="text-muted">
                  Season
                </small>
                <h5 className="text-truncate">
                  {props.selectedSeason.name}
                </h5>
              </div>

            }
          </Stack>


          {/*<Button className="w-100 mt-4 d-none d-lg-block ">*/}
          {/*  Upload*/}
          {/*</Button>*/}
          {
          }
        </Col>


        {/*<div className="bg-white rounded mx-1 mb-2 flex-row d-flex" style={{height: 60}}>*/}
        {/*  {*/}
        {/*    selectedShow && selectedShow.media_type === "tv" &&*/}
        {/*    <SelectedShow title={selectedShow.name} image={selectedShow.poster_path || ""}/>*/}
        {/*  }*/}
        {/*</div>*/}
        {/*<Row className="d-flex justify-content-start mx-0 px-0 mb-1">*/}
        {/*  <Col xs={12} sm={12} className="mb-2">*/}
        {/*    <Button variant="outline-primary" className="rounded-pill d-flex  mw-100">*/}
        {/*      <BsX className="my-auto ms-1 me-2 flex-shrink-0" size="1.5em"/>*/}
        {/*      <small className="my-auto me-3 text-truncate">American Horror Story: The Will of the Wisp </small>*/}
        {/*    </Button>*/}
        {/*  </Col>*/}
        {/*  <Col xs={12} sm={12}>*/}
        {/*    <Button variant="outline-primary" className="rounded-pill d-flex  mw-100" style={{maxWidth: "50%"}}>*/}
        {/*      <BsX className="my-auto ms-1 me-2 flex-shrink-0" size="1.5em"/>*/}
        {/*      <small className="my-auto me-3 text-truncate">Season 7 aduhfb sdjf sdjf sdjf jsdhfb jsdhf jsdvf </small>*/}
        {/*    </Button>*/}
        {/*  </Col>*/}


        {/*</Row>*/}


        {/*<DropDownSearch/>*/}
      </Row>
    </Container>

  );
}


// </Col>
// }
