import React, {FormEvent, useState} from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useProfile} from "../../hooks/useProfile";
import useAuth from "../../hooks/useAuth";
import API from "../../models/API";
import TextInput from "../TextInput";
import Logger from "../../models/Logger";

type ProfileAddScreenProps = {
  onSuccess: () => void;
};

export default function ProfileAddScreen({onSuccess}: ProfileAddScreenProps) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);


  const [name, setName] = useState<string>("");

  const profile = useProfile();
  const auth = useAuth();


  const add = (event: FormEvent) => {
    event.preventDefault();
    setIsUpdating(true);

    const api = new API(auth.getAuthToken() || "");

    api.profile().store({name: name})
      .then(() => {
        onSuccess();
      })
      .catch(Logger.log)
      .finally(() => {
        setIsUpdating(false);
        setName("");
        profile.refresh();
      })
  };

  return (
    <Form onSubmit={add}>
      <Row className="justify-content-center">
        <Col xs={10} className="mb-4">
          <h3 className="text-center">Create a new profile</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={10} className="my-auto">
          <TextInput type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4 mb-3">
        <Col xs={10} sm={8}>
          <Button className="w-100" type="submit" disabled={isUpdating}>
            {isUpdating ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              :
              "Save"
            }
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
