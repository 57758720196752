import React, {FormEvent, useState} from "react";
import {CardUpdateSetup} from "../models/APITypes";
import {Col, Form, Row} from "react-bootstrap";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import StripeFormElement from "./StripeFormElement";
import {Link, useNavigate} from "react-router-dom";
import Logger from "../models/Logger";


export default function CheckoutForm(props: { paymentSetup?: CardUpdateSetup }) {

  const [cardNumberError, setCardNumberError] = useState<string>("");
  const [cardExpiryError, setCardExpiryError] = useState<string>("");
  const [cardCvcError, setCardCvcError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement || !props.paymentSetup) {
      return;
    }


    let setupResult = await stripe.confirmCardSetup(
      props.paymentSetup.stripe_client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              country: props.paymentSetup.country,
              city: props.paymentSetup.city || "",
              postal_code: props.paymentSetup.post_code,
            },
          }
        },
      }
    );

    if (setupResult.error) {
      setErrorMessage(setupResult.error.message);
      return;
    }

    navigate("/");
  };
  return (
    <Form className="d-flex justify-content-center" onSubmit={handleSubmit}>
      <Row className="col-12">

        <StripeFormElement errorMessage={cardNumberError}>
          <CardNumberElement
            onChange={event => {
              Logger.log(event);
              setCardNumberError(event.error?.message || "");
            }}
            className={"p-2 list-group-item rounded-3 shadow-sm w-100 bg-black bg-opacity-50 " + (cardNumberError ? "border border-danger" : "")}
            options={{showIcon: true, style: {base: {lineHeight: "40px", color: "#fff", iconColor: "#d4c4ff"}}}}
          />
        </StripeFormElement>

        <StripeFormElement errorMessage={cardExpiryError}>
          <CardExpiryElement
            onChange={event => {
              Logger.log(event);
              setCardExpiryError(event.error?.message || "");
            }}
            className={"p-2 list-group-item rounded-3 shadow-sm w-100 bg-black bg-opacity-50 " + (cardExpiryError ? "border border-danger" : "")}
            options={{style: {base: {lineHeight: "40px", color: "#fff"}}}}
          />
        </StripeFormElement>

        <StripeFormElement errorMessage={cardCvcError}>
          <CardCvcElement
            onChange={event => {
              Logger.log(event);
              setCardCvcError(event.error?.message || "");
            }}
            className={"p-2 list-group-item rounded-3 shadow-sm w-100 bg-black bg-opacity-50 " + (cardCvcError ? "border border-danger" : "")}
            options={{style: {base: {lineHeight: "40px", color: "#fff"}}}}
          />
        </StripeFormElement>

        <Row className="px-4 pt-1 text-danger" style={{fontSize: "12px"}}>
          {errorMessage}
        </Row>

        <Col className="mb-2">
          <small className="text-muted">
            By clicking the 'Start trial' button you agree to our <Link to="/legal/privacy">Privacy Policy</Link> and <Link to="/legal/tos">Terms of Service</Link>.
          </small>
        </Col>


        <Col xs={12} className="my-2">
          <button className="btn btn-primary w-100">Update</button>
        </Col>

      </Row>

    </Form>
  );
}
