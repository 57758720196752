import React from "react";
import ControlButton, {ControlButtonProps} from "./ControlButton";
import {BiExitFullscreen, BiFullscreen} from "react-icons/bi";

export type FullScreenButtonProps = {
  active?: boolean;
} & ControlButtonProps;

export default function FullScreenButton({active, ...props}: FullScreenButtonProps) {
  return (
    <ControlButton {...props}>
      {
        active ?
          <BiExitFullscreen size="2em" color="white"/>
          :
          <BiFullscreen size="2em" color="white"/>
      }
    </ControlButton>
  );
}
