import React, {useEffect, useState} from "react";
import UploadTile from "../components/UploadTile";
import {Container, Row} from "react-bootstrap";
import {
  MoviesUploadStateType,
  ShowsUploadStateType,
} from "../models/UploaderTypes";
import {useUploader} from "../hooks/useUploader";

/*
  For each
 */
type UploadListItemType =
  {
    type: "movie",
    mediaId: number;
    addedOn: Date;
  }
  |
  {
    type: "episode",
    showId: number;
    seasonId: number;
    mediaId: number;
    addedOn: Date;
  };


function sortedUploads(shows: ShowsUploadStateType, movies: MoviesUploadStateType) {

  let mergedList: UploadListItemType[] = [];

  for (const showKey in shows) {
    for (const seasonKey in shows[showKey]) {
      for (const episodeKey in shows[showKey][seasonKey]) {
        const episode = shows[showKey][seasonKey][episodeKey];
        mergedList.push({
          type: "episode",
          showId: Number(showKey),
          seasonId: Number(seasonKey),
          mediaId: Number(episodeKey),
          addedOn: episode.added_on
        });
      }
    }
  }

  for (const movieKey in movies) {
    const movie = movies[movieKey];

    mergedList.push({
      type: "movie",
      mediaId: Number(movieKey),
      addedOn: movie.added_on,
    });
  }

  mergedList.sort((a, b) => b.addedOn.getUTCSeconds() - a.addedOn.getUTCSeconds());

  return mergedList;
}

export default function UploadsScreen() {

  const [results, setResults] = useState<UploadListItemType[]>([]);
  const {showsUploadState, moviesUploadState, showUploadModal} = useUploader();

  useEffect(() => {
    setResults(sortedUploads(showsUploadState, moviesUploadState));
  }, [showsUploadState, moviesUploadState]);

  if (Object.keys(showsUploadState).length === 0 && Object.keys(moviesUploadState).length === 0) {
    return (
      <div className="w-100 py-5">
        <Row className="w-100">
          <h3 className="text-center">
            Looks like there isn't anything here yet
          </h3>
        </Row>
        <Row className="w-100">
          <h4 className="text-center text-muted">
            <span role="button" className="text-primary" onClick={() => showUploadModal()}>Upload</span> to begin
          </h4>
        </Row>
      </div>
    );
  }

  return (
    <Container>
      <ul className="list-unstyled px-0 d-grid" style={styles.wrapper}>
        {
          results.map((item, index) => (
            item.type === "episode" ?
              <li key={index}>
                <UploadTile
                  type={item.type}
                  showId={item.showId}
                  seasonId={item.seasonId}
                  mediaId={item.mediaId}
                />
              </li>
              :
              <li key={index}>
                <UploadTile
                  type={item.type}
                  mediaId={item.mediaId}
                />
              </li>
          ))
        }
      </ul>
    </Container>
  );
}

const styles = {
  wrapper: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: 11,
    gridAutoFlow: "dense"
  }
};
