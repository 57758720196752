import React, {useEffect, useRef} from "react";

export type AnimatedEnterProps = {
  animationName?: "enter-up"|"enter-left"|"enter-scale";
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function AnimatedEnter({animationName, className, ...props}: AnimatedEnterProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {

      if (entry.isIntersecting && animationName) {
        entry.target.classList.add(animationName);
      }
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className={`${animationName}-start ` + className} ref={ref} {...props}/>
  );
}
