import React from "react";
import {Container, Row} from "react-bootstrap";
import "./index.css";

type ListItem = {
  title: string,
  body: string,
  children?: ListItem[]
};

const informationWeCollect: ListItem[] = [
  {
    title: "User Data",
    body: "We collect information related to the creation and maintenance of your account such as your email address, payment methods, and password to ensure secure access and financial transactions."
  },
  {
    title: "Content Data",
    body: "Pocketter is a file storage and streaming service, and as such, we collect the files you upload and store on our platform to provide you with the core functionality of our service."
  },
  {
    title: "Usage Data",
    body: "We collect information about how you use our services, including the features you access, the duration of your visits, and your interactions with our platform.",
    children: [
      {
        title: "Browser Type and Operating System",
        body: "We gather data on the type of web browser and operating system you use to optimize your experience on our platform."
      },
      {
        title: "IP Address",
        body: "Your IP address is collected to help us detect and prevent unauthorized access and provide regional services as applicable."
      },
      {
        title: "Web Server Logs",
        body: "We maintain server logs for troubleshooting, performance optimization, and security purposes. These logs may contain data related to your interactions with our platform."
      },
      {
        title: "File Metadata",
        body: "To enable you to manage and access your files effectively, we may collect/generate metadata associated with your files, such as file names, sizes, and formats."
      }
    ]
  },
  {
    title: "Location Data",
    body: "In some cases, we may use your IP address to determine your approximate geographical location. This information is used to provide localized services where applicable."
  },
  {
    title: "Payment Data",
    body: "We collect payment information necessary to process your transactions securely and efficiently."
  }

];

const sharingOfInformation: ListItem[] = [
  {
    title: "Third-Party Payment Providers",
    body: "To facilitate payment processing, we may share information relevant to your payment with third-party payment service providers."
  },
  {
    title: "Mergers, Acquisitions, Dissolutions, Reorganizations",
    body: "Your information may be transferred or disclosed as part of the transaction."
  }
];

const howInformationIsUsed: ListItem[] = [
  {
    title: "Providing Our Service",
    body: "Your information is used to deliver and enhance our services, including facilitating file storage, streaming, and ensuring the security of your account. We may also use this information to develop new features and optimize your user experience."
  },
  {
    title: "Communication",
    body: "We may use your contact information, such as your email address, to communicate with you regarding your account, service updates, security alerts, and other relevant information. These communications are essential for the proper functioning of our services and your user experience."
  },
  {
    title: "Personalization",
    body: "We may use your usage data to personalize your experience on Pocketter. This includes tailoring content, recommendations, and features to your preferences and needs."
  },
  {
    title: "Security",
    body: "Your information is crucial for the security of our platform. We use it to detect and prevent unauthorized access, fraud, and other security threats."
  },
  {
    title: "Analytics and Research",
    body: "We may anonymize and aggregate your data to perform analytics and research to better understand how our platform is used and to identify areas for improvement. This data is used in an aggregated, non-identifiable form."
  },
  {
    title: "Legal Compliance",
    body: "We may use your information to comply with legal obligations, including responding to legal requests, enforcing our Terms of Service, and protecting our rights, privacy, safety, and property, as well as those of our users and the public."
  },
  {
    title: "Business Operations",
    body: "Your information may be used for internal business purposes, such as data analysis, quality control, and financial management."
  }
];

const whereInformationIsStored: ListItem[] = [
  {
    title: "Cookies",
    body: "We may use cookies and similar technologies to collect information about your interactions with our website and apps. These files are typically stored on your device and can be managed through your browser or device settings."
  },
  {
    title: "Servers",
    body: "Your data, including files and account information, is primarily stored on servers located in France. These servers are maintained to provide you with a secure and reliable service."
  },
  {
    title: "Transmission",
    body: "Your information may be transmitted and stored worldwide, including in countries outside of France or your home country. This includes locations such as Australia, where we may have infrastructure or data processing facilities. We take measures to ensure that your information remains protected and treated in accordance with this policy regardless of the location."
  }
];

const dataRetention: ListItem[] = [
  {
    title: "User Data",
    body: "We will retain your account information for as long as your account remains active and for a reasonable period afterward to facilitate reactivation if you choose to return."
  },
  {
    title: "Content Data",
    body: "We store user files and content for as long as they are required for the core functionality of our services."
  },
  {
    title: "Legal and Regulatory Requirements",
    body: "In some cases, we may be required to retain certain information for a longer period due to legal or regulatory obligations."
  }
];

const futureChanges: ListItem[] = [
  {
    title: "Notification of Changes",
    body: "Whenever we make significant updates or modifications to these Terms of Service, we will provide you with notice through your registered email address or through prominent announcements on our website or app. It is your responsibility to review these notifications and the updated Terms of Service."
  },
  {
    title: "Consent to Updated Terms",
    body: "By continuing to use Pocketter after any changes to the Terms of Service, you implicitly consent to the revised terms. If you do not agree with the new terms, you may choose to discontinue your use of our services."
  },
  {
    title: "Reviewing Terms",
    body: "We recommend that you periodically review these Terms of Service to stay informed about any changes. Your ongoing use of our services implies your acceptance of any updated terms."
  },
  {
    title: "Dispute Resolution",
    body: "If you have concerns or disputes related to changes in our Terms of Service, please contact our Customer Support team. We are committed to resolving issues amicably and professionally."
  }
];

function mapListItem(item: ListItem) {
  return (
      <li><strong>{item.title}:</strong> {item.body}
      {item.children && <ol>{item.children.map(subItem => mapListItem(subItem))}</ol>}
      </li>
  );
}

export default function PrivacyScreen() {
  return (
    <Container>
      <Row className="my-4">
        <h1>Privacy Policy</h1>
      </Row>
      <Row>
        <ol>
          <li>
            <strong>Information We Collect</strong>
            <p>
              At Pocketter, we are committed to providing you with a secure and efficient file storage and streaming service. To achieve this, we may collect various types of information.
            </p>
            <ol>
              {
                informationWeCollect.map(item => mapListItem(item))
              }
            </ol>
          </li>

          <li>
            <strong>Sharing of Information</strong>
            <ol>
              {
                sharingOfInformation.map(item => mapListItem(item))
              }
            </ol>
          </li>

          <li>
            <strong>How Information is Used</strong>
            <p>
              At Pocketter, the information we collect serves various purposes, all aimed at providing you with a seamless and secure experience while using our file storage and streaming service. The following are the primary ways in which we use your information:
            </p>
            <ol>
              {
                howInformationIsUsed.map(item => mapListItem(item))
              }
            </ol>
          </li>

          <li>
            <strong>Where Information is Stored</strong>
            <p>
              At Pocketter, we operate a global service that may require your information to be stored and processed in various locations. The following outlines where your information may be stored:
            </p>
            <ol>
              {
                whereInformationIsStored.map(item => mapListItem(item))
              }
            </ol>
            <p>
              It's important to note that we maintain stringent security measures to protect your data, regardless of where it is stored. We adhere to industry best practices to safeguard your information and ensure its confidentiality, integrity, and availability. By using Pocketter, you acknowledge and consent to the international transmission and storage of your data as described in this policy.
            </p>
          </li>

          <li>
            <strong>Age Restriction</strong>
            <p>
              Pocketter is intended for use by individuals who are at minimum 18 years of age, or the legal age of their respective country, whichever is older. By using Pocketter, you confirm that you meet this age requirement. Minors may only use the service with the involvement, supervision, and approval of a parent or legal guardian.
            </p>
          </li>

          <li>
            <strong>Data Retention</strong>
            <p>
              The specific retention periods for different types of information may vary. Here are some general guidelines:
            </p>
            <ol>
              {
                dataRetention.map(item => mapListItem(item))
              }
            </ol>
          </li>

          <li>
            <strong>Future Changes</strong>
            <p>
              To ensure our service remains up to date and in compliance with legal and technological advancements, we reserve the right to make changes to these Terms of Service.
            </p>
            <ol>
              {
                futureChanges.map(item => mapListItem(item))
              }
            </ol>
          </li>


        </ol>
      </Row>

    </Container>
  );
}
