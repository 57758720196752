import axios, {Axios, AxiosRequestConfig} from "axios";
import {
  AccountResult,
  CardUpdateSetup,
  EpisodeProgress,
  MovieProgress,
  PaypalUpdateSetup,
  PlanResult,
  ProfileResult,
  LibraryResultResponse,
  SessionCreateType,
  ShowProgress,
  UploadRequestPostResponse,
  UserContentResponse,
  UserEpisode,
  UserMovie,
  UserResult,
  UserSeason,
  UserShow,
  BillAttemptsResultResponse,
  AccessTokenRefreshResponse
} from "./APITypes";
import {UploadEncodeProgressType} from "./UploaderTypes";

class APIRequest<IndexType, ViewType, StoreType, UpdateType, DeleteType> {
  private readonly token: string;
  private readonly url: string;
  private readonly customConfig?: AxiosRequestConfig;
  private customAxios?: Axios;

  constructor(token: string, url: string, customAxios?: Axios, customConfig?: AxiosRequestConfig) {
    this.token = token;
    this.url = url;
    this.customConfig = customConfig;
    this.customAxios = customAxios;
  }

  private config() {
    return {
      headers: {Authorization: `Bearer ${this.token}`},
      ...(this.customConfig || {})
    };
  }

  public index() {
    return (this.customAxios || axios).get<IndexType>(this.url, this.config());
  }

  public view() {
    return (this.customAxios || axios).get<ViewType>(this.url, this.config());
  }

  public store(data?: any) {
    return (this.customAxios || axios).post<StoreType>(this.url, data, this.config());
  }

  public update(data?: any) {
    return (this.customAxios || axios).put<UpdateType>(this.url, data, this.config());
  }

  public delete() {
    return (this.customAxios || axios).delete<DeleteType>(this.url, this.config());
  }
}

export default class API {
  private readonly token: string;
  private readonly videoServiceUrl: string;
  private readonly accountServiceUrl: string;
  private readonly authServiceUrl: string;
  private readonly uploadServiceUrl: string;
  private readonly encodeServiceUrl: string;

  constructor(token: string) {
    this.token = token;
    this.authServiceUrl = `${process.env.REACT_APP_API_URL}/v1/auth`;
    this.uploadServiceUrl = `${process.env.REACT_APP_API_URL}/v1/upload`;
    this.encodeServiceUrl = `${process.env.REACT_APP_API_URL}/v1/encode`;
    this.videoServiceUrl = `${process.env.REACT_APP_API_URL}/v1/video`;
    this.accountServiceUrl = `${process.env.REACT_APP_API_URL}/v1/account`;

  }

  public userContent() {
    return new APIRequest<UserContentResponse, never, never, never, never>(this.token, this.videoServiceUrl + "/usercontent");
  }

  public userShow(showId?: number) {
    let localUrl = `/usercontent/shows`;
    localUrl += showId ? `/${showId}` : "";
    return new APIRequest<UserShow[], UserShow, never, never, never>(this.token, this.videoServiceUrl + localUrl);
  }

  public userSeason(showId: number, seasonId?: number) {
    let localUrl = `/usercontent/shows/${showId}/seasons`;
    localUrl += seasonId ? `/${seasonId}` : "";
    return new APIRequest<UserSeason[], UserSeason, never, never, never>(this.token, this.videoServiceUrl + localUrl);
  }

  public userEpisode(showId: number, seasonId: number, episodeId?: number) {
    let localUrl = `/usercontent/shows/${showId}/seasons/${seasonId}/episodes`;
    localUrl += episodeId ? `/${episodeId}` : "";
    return new APIRequest<UserEpisode[], UserEpisode, never, never, never>(this.token, this.videoServiceUrl + localUrl);
  }

  public userMovie(movieId?: number) {
    let localUrl = `/usercontent/movies`;
    localUrl += movieId ? `/${movieId}` : "";
    return new APIRequest<UserMovie[], UserMovie, never, never, never>(this.token, this.videoServiceUrl + localUrl);
  }

  public paymentUpdatePaypal() {
    let localUrl = `/account/payment/update/paypal`;
    return new APIRequest<PaypalUpdateSetup, never, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public paymentUpdateCard() {
    let localUrl = `/account/payment/update/card`;
    return new APIRequest<CardUpdateSetup, never, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public account() {
    return new APIRequest<AccountResult, never, never, never, never>(this.token, this.accountServiceUrl + "/account");
  }

  public profile(profileId?: number) {
    let localUrl = `/account/profiles`;
    localUrl += profileId !== undefined ? `/${profileId}` : "";
    return new APIRequest<ProfileResult[], never, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public plan() {
    return new APIRequest<PlanResult[], never, never, never, never>(this.token, this.accountServiceUrl + "/plans");
  }

  public videoSession() {
    return new APIRequest<never, never, SessionCreateType, never, never>(this.token, this.videoServiceUrl + "/session");
  }

  public showProgress(profileId: number, showId?: number) {
    let localUrl = `/account/profiles/${profileId}/progress/show`
    localUrl += showId !== undefined ? `/${showId}` : "";
    return new APIRequest<never, ShowProgress, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public latestShowProgress(profileId: number, showId: number) {
    let localUrl = `/account/profiles/${profileId}/progress/show/${showId}/latest`;
    return new APIRequest<never, EpisodeProgress, never, never, never>(this.token, this.accountServiceUrl + localUrl);

  }

  public episodeProgress(profileId: number, showId: number, seasonId:number, episodeId: number) {
    const localUrl = `/account/profiles/${profileId}/progress/show/${showId}/season/${seasonId}/episode/${episodeId}`;

    return new APIRequest<never, EpisodeProgress, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public movieProgress(profileId: number, movieId: number) {
    const localUrl = `/account/profiles/${profileId}/progress/movie/${movieId}`;

    return new APIRequest<never, MovieProgress, never, never, never>(this.token, this.accountServiceUrl + localUrl);
  }

  public uploadRequest() {
    const localUrl = "/requests";
    return new APIRequest<never, never, UploadRequestPostResponse, never, never>(this.token, this.uploadServiceUrl + localUrl);
  }

  public uploadSegment(requestId: number, partNumber: number) {
    const customAxios = axios.create();
    customAxios.defaults.timeout = 300000;
    const localUrl = `/requests/${requestId}/parts/${partNumber}`;
    return new APIRequest<never, never, never, void, never>(this.token, this.uploadServiceUrl + localUrl, customAxios);
  }

  public encodeJob(uploadId?: number) {
    let localUrl = "/jobs";
    localUrl += uploadId !== undefined ? `/${uploadId}` : "";
    return new APIRequest<UploadEncodeProgressType[], UploadEncodeProgressType, never, never, never>(this.token, this.encodeServiceUrl + localUrl);
  }

  public refreshAccessToken(clientId: string, refreshToken: string) {
    const localUrl = "/oauth/token";
    const data = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: clientId,
      scope: ""
    }
    return new APIRequest<never, never, AccessTokenRefreshResponse, never, never>(this.token, this.authServiceUrl + localUrl).store(data);
  }

  public logout() {
    const localUrl = "/logout";
    return new APIRequest<never, never, never, never, never>(this.token, this.authServiceUrl + localUrl).store();
  }

  public endSessions() {
    const localUrl = "/end_sessions";
    return new APIRequest<never, never, never, never, never>(this.token, this.authServiceUrl + localUrl).store();
  }

  public user() {
    const localUrl = "/user";
    return new APIRequest<UserResult, never, never, never, never>(this.token, this.authServiceUrl + localUrl).index();
  }

  public library(token?: string) {
    let localUrl = `/library`;
    localUrl += token ? `?cursor=${token}` : "";
    return new APIRequest<LibraryResultResponse, never, never, never, never>(this.token, this.videoServiceUrl + localUrl).index();
  }

  public search(query: string, token?: string) {
    let localUrl = `/search?query=${query}`;
    localUrl += token ? `&cursor=${token}` : "";
    return new APIRequest<LibraryResultResponse, never, never, never, never>(this.token, this.videoServiceUrl + localUrl).index();
  }

  public billHistory(token?: string) {
    let localUrl = `/account/payment/attempts`;
    localUrl += token ? `?cursor=${token}` : "";
    return new APIRequest<BillAttemptsResultResponse, never, never, never, never>(this.token, this.accountServiceUrl + localUrl).index();
  }

  public requestAccountDeletion() {
    const localUrl = "/account_delete_request";
    return new APIRequest<never, never, never, never, never>(this.token, this.authServiceUrl + localUrl).store();
  }

  public cancelAccountDeletion() {
    const localUrl = "/account_delete_cancel";
    return new APIRequest<never, never, never, never, never>(this.token, this.authServiceUrl + localUrl).store();
  }
}
